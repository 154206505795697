import React from 'react'

import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

const FIND_ALL_USERS = gql`
	query FindAllUsers {
		findAllUsers {
			id
			state
			email
			firstName
			lastName
			fullName
		}
	}
`
const UserListPage = ({ routes, match }:{routes:any, match:any}) => {
	const { t } = useTranslation()
	const queryListFelhasznalok = useQuery(FIND_ALL_USERS)
	const serviceType = match.params && match.params.szolgaltatas
	return <div className='batch-page-content'>
		{!queryListFelhasznalok.loading ?
			<DataTable autoLayout={true} className='w-100' value={queryListFelhasznalok.data ? queryListFelhasznalok.data.findAllUsers : []}>
				{/*<Column className="icon-column" body={iconTemplate} />*/}
				<Column sortable field='email' header='E-mail' />
				<Column sortable field='fullName' header='Név' />
			</DataTable>
			:
		  null
		}
	</div>
}

export default UserListPage
