import React from 'react'
import { useTranslation } from 'react-i18next'
import { Card } from 'primereact/card'
import { Logo } from '../../layout/Menu'
import { Link } from 'react-router-dom'

const ActivationSuccess = ({ routes, match }:{routes:any, match:any}) => {
  const { t } = useTranslation('Registration')
  return <div className='registration-content'>
        <div className='row'>
          <div className='col-10 col-sm-8 col-md-6 col-lg-4 offset-1 offset-sm-2 offset-md-3 offset-lg-4'>
            <div className='dialog-bg' />
            <Card>
              <div className='ck-header p-3'>
                <div className='row'>
                  <div className='col'>
                    <div className='text-left'>
                      <Logo t={t} />
                    </div>
                  </div>
                  <div className='col-auto text-right'>
                    <Link className='icon-label-close-24' to='/' />
                  </div>
                </div>
              </div>
              <div className='p-3'>
                <div className=''>
                  <h5 className='card-title text-center'>{t('registration.title')}</h5>
                  <div className='text-center'>
                    {match.params && match.params.hibakod === 'wrong_change_code' && t('registration.wrong_change_code')}
                    {match.params && match.params.hibakod === 'wrong_email' && t('registration.wrong_email')}
                    {match.params && match.params.hibakod === 'activation_expired' && t('registration.activation_expired')}
                    {match.params && match.params.hibakod === 'password_modofication_expired' && t('registration.password_modofication_expired')}
                    {match.params && match.params.hibakod === 'success' &&
                        <>
                          <div>{t('registration.activation-success-message')}</div>
                          <hr/>
                          <div className='mt-3 text-left' dangerouslySetInnerHTML={{ __html:t('registration.activation-success-desc') }}></div>
                        </>
                    }
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
}

export default ActivationSuccess
