import React from 'react'
import TextEditor from '../../../components/editors/TextEditor'
import { useTranslation } from 'react-i18next'

const ConcatenatedAddressInputForm = ({
										  values,
										  placeholder,
										  errors,
										  handleBlur,
										  handleChange,
										  onAddressChanged
									  }:
										  {
											  values:any,
											  placeholder?:any,
											  errors:any,
											  handleBlur: any,
											  handleChange: any,
											  onAddressChanged: any
										  }) => {
  const handleAddressChanged = (e : any) => {
    // onAddressChanged(e.target.value)
	      handleChange(e)
  }
  let { t } = useTranslation('Provisioning')
  return (
    <React.Fragment>
      <TextEditor
        fieldName='fullAddress'
        fieldLabel=''
		placeholder={placeholder}
        value={values.fullAddress}
        error={errors.fullAddress}
        handleChange={handleAddressChanged}
        handleBlur={handleBlur} />
    </React.Fragment>)
}

export default ConcatenatedAddressInputForm
