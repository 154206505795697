import React from 'react'
import { getEnumValue } from '../../utils/EnumUtils'
import BatchInputPanel from './BatchInputPanel'


const LazmeroForm = ({ apiKey, t } : {apiKey: string, t:any}) => {
	const displayEnumName = ((enumClass: any, enumValues: any) => {
		const values = getEnumValue(enumClass,enumValues)
		return values ? ' - ' + values : ''
	})
	// @ts-ignore
	return <BatchInputPanel serviceType='Lazmeres' t={t} />
}

export default LazmeroForm
