import React from 'react'
import { HashRouter, Switch } from 'react-router-dom'
import RegistrationPage from './routes/registration/RegistrationPage'
import MainPageRoute from './routes/main/MainPageRoute'
import MainPage from './routes/main/MainPage'
import DefaultPageRoute from './routes/main/DefaultPageRoute'
import ErrorPage from './layout/ErrorPage'
import Balances from './routes/account/Balances'
import Profile from './routes/account/Profile'
import Orders from './routes/account/Orders'
import ResourceUse from './routes/account/ResourceUse'
import ChangePasswordPage from './routes/registration/ChangePasswordPage'
import ActivationSuccess from './routes/registration/ActivationSuccess'
import RegistrationFinish from './routes/registration/RegistrationFinish'
import RegistrationPageRoute from './routes/main/RegistrationPageRoute'
import BatchPage from './routes/batch/BatchPage'
import BatchPageRoute from './routes/main/BatchPageRoute'
import PasswordReminderPage from './routes/registration/PasswordReminderPage'
import ContactUsPage from './routes/main/ContactUsPage'
import LoginPage from './routes/registration/LoginPage'
import UserListPage from './routes/superuser/UserListPage'
import AccountListPage from './routes/superuser/AccountListPage'
import ManualOrderPage from './routes/superuser/ManualOrderPage'
import AdvertPage from './routes/main/AdvertPage'
import ImpressPage from "./routes/main/ImpressPage";

const CimklinikaApp = () => (
  <HashRouter>
    <Switch>
      <MainPageRoute path='/' component={MainPage} exact />
      <BatchPageRoute path='/kezelo/:szolgaltatas' component={BatchPage} />
      <DefaultPageRoute path='/torzsadatok' component={Profile} />
      <DefaultPageRoute path='/megrendelesek' component={Orders} />
      <DefaultPageRoute path='/felhasznalasok' component={ResourceUse} />
      <DefaultPageRoute path='/egyenlegek' component={Balances} />
      <DefaultPageRoute path='/szuperfelhasznalo/felhasznalok' component={UserListPage} />
      <DefaultPageRoute path='/szuperfelhasznalo/elofizetesek' component={AccountListPage} />
      <DefaultPageRoute path='/szuperfelhasznalo/megrendeles/:accountId' component={ManualOrderPage} />
      <RegistrationPageRoute path='/jelszocsere/:email?/:kod?' component={ChangePasswordPage} />
      <RegistrationPageRoute path='/jelszoemlekezteto/:email?' component={PasswordReminderPage} />
      <RegistrationPageRoute path='/regisztracio_redirect/:hibakod' component={ActivationSuccess} />
      <RegistrationPageRoute path='/regisztracio_vege/:sikeres' component={RegistrationFinish} />
      <RegistrationPageRoute path='/regisztracio' component={RegistrationPage} />
      <RegistrationPageRoute path='/irjonnekunk' component={ContactUsPage} />
      <RegistrationPageRoute path='/impresszum' component={ImpressPage} />
      <RegistrationPageRoute path='/bejelentkezes' component={LoginPage} />
      <MainPageRoute path='/hirdetes/:id' component={AdvertPage} exact />
      <ErrorPage />
    </Switch>
  </HashRouter>
)

export default CimklinikaApp
