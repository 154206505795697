import React from 'react'
import i18n from '../i18n'

const EnumUtils = ({ enumClassName, enumName } : {enumClassName: any, enumName: any}) : any => {
  return getEnumValue(enumClassName, enumName)
}

const getEnumValueInternal = (enumClassName: any, enumName: any) : any => {
  const enumValues = i18n.t('enumValues', { returnObjects:true })
  const enumClass = enumValues ? enumValues[enumClassName] : null
  const retVal = enumClass ? enumClass[enumName] : 'N/A'
  // console.log('getEnumValue: ' + enumClassName + ':' + enumName + '=' + retVal)
  return retVal || 'N/A'
}

export const getEnumValue = (enumClassName: any, enumName: any) : any => {
  if (enumName instanceof Array) {
    let res = ''
    enumName.forEach((value :any) => {
      res = res.length > 0 ? res.concat(', ') : ''
      // @ts-ignore
      res = res.concat(getEnumValueInternal(enumClassName, value))
    })
    return res
  } else {
    return getEnumValueInternal(enumClassName, enumName)
  }
}

export const listEnumValues = (enumClassName: any) :any => {
  const enumValues = i18n.t('enumValues', { returnObjects:true })
  let retVal = {}
  if (enumValues[enumClassName]) {
    const enumClass:any = enumValues[enumClassName]
    retVal = enumClass
    // retVal = enumClass ? Object.keys(enumClass).map(k => enumClass[k]) : []
  }
  // console.log('listEnumValues: ' + enumClassName + '=' + JSON.stringify(retVal))
  return retVal
}

export default EnumUtils
