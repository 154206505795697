import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router'
import React from 'react'
import { RegistrationContentWrapper } from '../registration/RegistrationPage'
import mailText from "../../images/email_impress.png";
import mailTextDo from "../../images/email_impress_do.png";

export const CKLabelValue = ({ label, value, rendered = true, className }:{label:string, value:any, rendered?:boolean, className?:string}) => {
	return <React.Fragment>
		{rendered && <label className={`col-sm-5 col-form-label ${className || ''}`}>{label}</label>}
		{rendered && <div className={`col-sm-7 form-control-plaintext ${className || ''}`}>{value}</div>}
	</React.Fragment>
}

const ImpressPage = (props) => {
	const { t } = useTranslation(['Registration', 'Common'])
	return <RegistrationContentWrapper className='col-12 col-sm-10 col-lg-8 col-xl-6 offset-sm-1 offset-lg-2 offset-xl-3' t={t} title={t('Common:menu.impress')}>
		<div className='impress-content'>
			<div className='row'>
				<CKLabelValue label={t('Common:impress.name')} value='Scriptum Informatika Zrt.'/>
				<CKLabelValue label={t('Common:impress.residence')} value='6771 Szeged, Mályva utca 34.'/>
				<CKLabelValue label={t('Common:impress.phone')} value='+36 62 406 133'/>
				<CKLabelValue label={t('Common:impress.email')} value={<img src={mailText} alt='mail-text'/>}/>
				<CKLabelValue label={t('Common:impress.company-register')} value='Szegedi Törvényszék Cégbírósága'/>
				<CKLabelValue label={t('Common:impress.company-registration-number')} value='06-10-000238'/>
				<CKLabelValue label={t('Common:impress.VAT-number')} value='11596202-2-06'/>
				<CKLabelValue label={t('Common:impress.hosting-provider')} value='DigitalOcean, LLC'/>
				<CKLabelValue label={t('Common:impress.hosting-provider-email')} value={<img src={mailTextDo} alt='mail-text-digital-ocean'/>}/>
			</div>
		</div>
	</RegistrationContentWrapper>
}

export default withRouter(ImpressPage)
