import React from 'react'
import Header from '../layout/Header'
import Footer from '../layout/Footer'

const DefaultPageLayout = ({ children, ...rest } : {children?: any, rest?: any}) => {
  return (
    <div className='ck-root default-layout'>
      <Header />
      <div className=''>
        <main className='ck-main container-fluid'>
          <div className='page-center-content'>
            {children}
          </div>
        </main>
      </div>
      <Footer className='mt-5'/>
    </div>
  )
}

export default DefaultPageLayout
