import React from 'react'
import imgInfo32 from '../../images/info-32.png'
import { ProgressBar } from 'primereact/progressbar'
import { CKLabelValue } from '../operator/resultPanel/AddressResultPanel'
import invalidImg from '../../images/invalid-128.png'
import validImg from '../../images/valid-128.png'
import { formatNumber } from '../../utils/Localization'
import moreResultImg from '../../images/help-128.png'

export const CKProgressBar = ({ percentage }: { percentage: any }) => {
	let styleSheet = document.styleSheets[0] as CSSStyleSheet
	let keyframes =
		`@-webkit-keyframes filler-anim {
	  	from { width: 0%; }
  		to { width: ${percentage}%; }
	}`
	styleSheet.insertRule(keyframes, styleSheet.cssRules.length)
	keyframes =
		`@-webkit-keyframes rest-anim {
  		from { width: 100%; }
  		to { width: ${100 - percentage}%; }
	}`
	styleSheet.insertRule(keyframes, styleSheet.cssRules.length)
	return (
		<>
			{/*<div className='text-right'>{formatNumber(percentage)}%</div>*/}
			<div className='progress-bar'>
				{percentage !== 0 && <Filler className='filler' percentage={percentage}/>}
				<Filler className='filler-rest' percentage={100 - percentage}/>
			</div>
		</>
	)
}

const Filler = ({ percentage, className }: { percentage: any, className?: any }) => {
	return <div className={className} style={{ width: `${percentage}%` }}/>
}

const RontgenResultPanel = ({
								result,
								additionalServices,
								t,
								children,
								progress = false
							}: {
	result: any,
	additionalServices?: string[],
	t: any,
	children?: any,
	progress?: boolean,
}) => {
	return (
		<div className='h-100'>
			<div className='result card h-100'>
				{((!result || result === undefined) && !progress) &&
                <div className='m-4'>
					{children && <div className=' mb-3 text-center'>
                      <img src={imgInfo32}/>
                    </div>}
					{children}
                </div>
				}
				{progress && <div className='m-3'>
                  <div>{t('Provisioning:kezelo.process')}</div>
                  <ProgressBar mode='indeterminate'/>
                </div>}
				{result && !progress &&
                <div className='d-flex result-content'>
                  <div className='col data-col'>
                    <div className='result'>
                      <div className='row'>
                        <CKLabelValue label={t('Provisioning:kezelo-form.result.qualification')}
                          value={t('Provisioning:kezelo-form.result.qualification-values.'+result.qualification) + ', ' + t('Provisioning:kezelo-form.result.checkLevel-values.'+result.checkLevel)} />
                      </div>
                    </div>
                  </div>
                  <div className='col-auto image-col img-column'>
					  {result.qualification === 'invalid' ?
						  <img src={invalidImg} alt='invalid image' /> :
						  ((result.qualification === 'ambiguous') ?
							  <img src={moreResultImg} alt='more result image' /> :
							  <img src={validImg} alt='valid image' />)
					  }
                  </div>
                </div>
				}
			</div>
			{result && !progress &&
            <div className='result card h-100 mt-3 overflow-hidden'>
                <div className='d-flex result-content'>
                  <div className='col data-col'>
                    <div className='result'>
                      <div className={'row'}>
                        <CKLabelValue label={t('Provisioning:rontgen-form.result.correctness')}
                          value={t('Common:enumValues.CorrectnessGrade.' + result.correctnessGrade) + ' (' + formatNumber(result.correctness * 10) + '%)'}/>
                      </div>
                      <CKProgressBar percentage={result.correctness * 10}/>
                      <div className={'row mt-3'}>
                        <CKLabelValue label={t('Provisioning:rontgen-form.result.accuracy')} value={`${formatNumber(result.accuracy * 100)}%`}/>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
			}
		</div>
	)
}

export default RontgenResultPanel
