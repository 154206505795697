import React from 'react'
import { LocaleSettings } from 'primereact/calendar'
import numeral from 'numeral'
import moment from 'moment-with-locales-es6'
// import 'moment/locale/hu';
import { precisionRound } from './CommonFunction'
// import {Moment, MomentInput} from "moment";

export const CalendarLocale: LocaleSettings = {
  today: 'Ma',
  clear: 'Törlés',
  firstDayOfWeek: 1,
  dayNames: ['Vasárnap', 'Hétfő', 'Kedd', 'Szerda', 'Csütörtök', 'Péntek', 'Szombat'],
  dayNamesShort: ['Vas', 'Hé', 'Ke', 'Sze', 'Csüt', 'Pé', 'Szo'],
  dayNamesMin: ['V', 'H', 'K', 'Sze', 'Cs', 'P', 'Szo'],
  monthNames: ['Január', 'Február', 'Március', 'Április', 'Május', 'Június', 'Július', 'Augusztus', 'Szeptember', 'Október', 'November', 'December'],
  monthNamesShort: ['Jan', 'Feb', 'Már', 'Ápr', 'Máj', 'Jún', 'Júl', 'Aug', 'Sze', 'Okt', 'Nov', 'Dec']
}

moment.locale('hu')

// dátum formázás: https://momentjs.com/ - Multiple Locale Support
export const formatDateTime = (val : any) => {
  return val === undefined || val === '' || val === null
    ? '' : moment(val).format('L LTS')
}
export const formatDate = (val: any) => {
  let md = moment(val)
  return md.isValid ? md.format('L') : '-'
}

export const getDayFromCurrentDay = (val: any) => {
  let md = moment(val)
  let currentMd = moment()
  return Math.floor(md.diff(currentMd) / (1000 * 60 * 60 * 24))
}

export const formatDateToCalendar = ({ val, empty } : {val: any, empty :any}) => {
  const noDate = empty === undefined ? 'nincs dátum' : empty
  return val === undefined || val === '' || val === null
    ? noDate : moment(val).format('L')
}

export const toJsonDate = (val :any) => {
  return val ? new Date(val).toJSON() : null
}

// load a locale
numeral.register('locale', 'hu', {
  delimiters: {
    thousands: ' ',
    decimal: ','
  },
  abbreviations: {
    thousand: 'e',
    million: 'm',
    billion: 'b',
    trillion: 't'
  },
  ordinal : (number :any) => {
    return number === 1 ? 'er' : 'ème'
  },
  currency: {
    symbol: 'Ft'
  }
})

// switch between locales
numeral.locale('hu')

export const formatCurrency = ({ val, curr = 'HUF' }:{val: number, curr?: any}) => {
  if (curr === 'HUF') {
    return numeral(precisionRound(val, 0)).format('0,0') + ' Ft'
  } else if (curr === 'EUR') {
    return numeral(precisionRound(val, 2)).format('0,0') + ' €'
  } else {
    return numeral(precisionRound(val, 2)).format('0,0') + (curr || '')
  }
}

export const formatNumber = (val: any) => {
  return Number(val).toLocaleString('hu',   { minimumIntegerDigits:1, maximumFractionDigits:1, useGrouping:false })
}

