import React, { useState } from 'react'
import { Dropdown } from 'primereact/dropdown'
import { InputMask } from 'primereact/inputmask'
import { useField } from 'formik'
import { MultiSelect } from 'primereact/multiselect'
import { Button } from 'primereact/button'

/**
 * Egy megrendelés tétel (OrderItem) szerkesztését megvalósító komponens.
 */

const OrderItemsEditor = ({ name, onDelete }) => {
	const [field, meta] = useField(name)

	const orderTypeOptions = [
		{ label: 'Interfész', value: 'Interface' },
		{ label: 'Szolgáltatás', value: 'Service' }
	]

	const { orderType: propsOrderType, ...propsOrderData } = field.value

	const [orderType, setOrderType] : [string | null, any] = useState<string | null>(propsOrderType)
	const [orderData, setOrderData] : [any, any] = useState(propsOrderData)

	const changeOrderType = ({ value }) => {
		setOrderType(value)
		setOrderData({})
		field.onChange( { target: { value: { orderType: value }, name: field.name } })
	}

	const changeOrderData = (orderData) => {
		setOrderData(orderData)
		field.onChange( { target: { value: { orderType, ...orderData }, name: field.name } })
	}

	return (
		<div className='p-panel-content'>
			<div className='row'>
				<div className='col-3 col-md-3'>
					<Dropdown
						className='form-control'
						id={'input' + field.name + '_orderType'}
						placeholder='Interfész vagy szolgáltatás'
						value={orderType}
						onChange={changeOrderType}
						options={orderTypeOptions}
					/>
				</div>
				{
					orderType === 'Interface' ?
						<OrderInterfaceEditor fieldName={field.name + 'orderInterface'} value={orderData} changeOrderData={changeOrderData} />
					: orderType === 'Service' ?
						<OrderServiceEditor fieldName={field.name + 'orderService'} value={orderData} changeOrderData={changeOrderData} />
					: null
				}
				<div className='col-1 col-md-1'>
					<Button onClick={onDelete} label='-' />
				</div>
			</div>
		</div>

	)
}

const OrderInterfaceEditor = ({ fieldName, value, changeOrderData } :
	{ fieldName:string, value:any, changeOrderData:any }) => {
	const interfaceTypeOptions = [
		{ label: 'JSON', value: 'Json' },
		{ label: 'Excel', value: 'Excel' },
		{ label: 'Web', value: 'Kezelo' },
		{ label: 'SOAP', value: 'Soap' }
	]

	const [interfaceType, setInterfaceType] = useState(value.interfaceType)
	const [quotaDays, setQuotaDays] = useState(value.quotaDays)

	const changeInterfaceType = ({ value }) => {
		setInterfaceType(value)
		changeOrderData({ interfaceType: value, quotaDays, rechargeCardQuantity: 1 })
	}

	const changeQuotaDays = ({ target: { value } }) => {
		setQuotaDays(value)
		changeOrderData({ interfaceType, quotaDays: value, rechargeCardQuantity: 1 })
	}

	return (
		<>
			<div className='col-3 col-md-3'>
				<div className='form-group w-100'>
					<Dropdown
						className='form-control'
						id={'input' + fieldName + '_interfaceType'}
						placeholder='Kapu típusa'
						value={interfaceType}
						onChange={changeInterfaceType}
						options={interfaceTypeOptions}
						editable={false}
						disabled={false}
					/>
				</div>
			</div>
			<div className='col-1 col-md-1'>
				<div className='form-group w-100'>
					<InputMask
						mask='999'
						slotChar=''
						autoClear={false}
						className='form-control'
						id={'input' + fieldName + '_quotaDays'}
						name={'input' + fieldName + '_quotaDays'}
						placeholder='Kapu nyitva (napok száma)'
						value={quotaDays}
						// @ts-ignore
						onChange={changeQuotaDays}
					/>
				</div>
			</div>
			<div className='col-3 col-md-3'>
			</div>
		</>
	)
}

const OrderServiceEditor = ({ fieldName, value, changeOrderData } :
								  { fieldName:string, value:any, changeOrderData:any }) => {
	const serviceTypes = [
		{ label: 'Lázmérés', value: 'Lazmeres' },
		{ label: 'Röntgen', value: 'Rontgen' },
		{ label: 'Tisztítás', value: 'Tisztitas' },
		{ label: 'Okosbeírás', value: 'Okosbeiras' }
	]

	const serviceOptionPossibleValues = {
		Lazmeres: [
			{ label: 'Lista', value: 'Lista' },
			{ label: 'Házszám', value: 'Hazszam' }
		],
		Rontgen: [
			{ label: 'Lista', value: 'Lista' },
			{ label: 'Házszám', value: 'Hazszam' }
		],
		Tisztitas: [
			{ label: 'Bontva', value: 'Bontva' },
			{ label: 'WGS', value: 'Terkeppont' },
			{ label: 'EOV', value: 'TerPont' },
			{ label: 'Lista', value: 'Lista' },
			{ label: 'Házszám', value: 'Hazszam' }
//			{ label: 'Sürgősségi', value: 'Surgossegi' },
//			{ label: 'Intenzív', value: 'Intenziv' },
//			{ label: 'Háziorvos', value: 'Haziorvos' },
		],
		Okosbeiras: [
			{ label: 'WGS', value: 'Terkeppont' },
			{ label: 'EOV', value: 'TerPont' },
			{ label: 'Házszám', value: 'Hazszam' }
		]
	}

	const [serviceType, setServiceType] = useState(value.serviceType)
	const [quotaAmount, setQuotaAmount] = useState(value.quotaAmount)
	const [serviceOptions, setServiceOptions] = useState(value.serviceOptions)

	const changeServiceType = (event) => {
		setServiceType(event.value)
		setServiceOptions([])
		changeOrderData({ serviceType: event.value, quotaAmount, serviceOptions: [], rechargeCardQuantity: 1 })
	}

	const changeQuotaAmount = (event) => {
		const intValue = parseInt(event.target.value)
		setQuotaAmount(intValue)
		changeOrderData({ serviceType, quotaAmount: intValue, serviceOptions, rechargeCardQuantity: 1 })
	}

	const changeServiceOptions = (event) => {
		const value = event.target.value
		setServiceOptions(value)
		changeOrderData({ serviceType, quotaAmount: quotaAmount, serviceOptions: value, rechargeCardQuantity: 1 })
	}

	return (
		<>
			<div className='col-3 col-md-3'>
				<div className='form-group w-100'>
					<Dropdown
						className='form-control'
						id={'input' + fieldName + '_interfaceType'}
						placeholder='Szolgáltatás típusa'
						value={serviceType}
						// @ts-ignore
						onChange={changeServiceType}
						options={serviceTypes}
						editable={false}
						disabled={false}
					/>
				</div>
			</div>
			<div className='col-1 col-md-1'>
				<div className='form-group w-100'>
					<InputMask
						mask='9999999'
						slotChar=''
						autoClear={false}
						className='form-control'
						id={'input' + fieldName + '_quotaDays'}
						name={'input' + fieldName + '_quotaDays'}
						placeholder='Kvóta (felhasználható egyenleg)'
						value={'' + quotaAmount}
						// @ts-ignore
						onChange={changeQuotaAmount}
					/>
				</div>
			</div>
			<div className='col-3 col-md-3'>
				<div className='form-group w-100'>
					<MultiSelect
						value={serviceOptions}
						options={serviceOptionPossibleValues[serviceType]}
						onChange={changeServiceOptions}
						filter={false}
					/>
				</div>
			</div>
		</>
	)
}

export default OrderItemsEditor
