import Menu from './Menu'
import React, { useRef, useState } from 'react'
import { Route } from 'react-router'
import { Button } from 'primereact/button'
import { OverlayPanel } from 'primereact/overlaypanel'
import { UserContextConsumer } from '../components/UserContext'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import english from '../images/english.png'
import hungarian from '../images/hungarian.png'
import imgUserLogin from '../images/user-login-24.png'
import { Formik } from 'formik'
import ComboEditor from '../components/editors/ComboEditor'

const RegistrationButton = ({ label }:{label:any}) => (
  <Route render={({ history }) => (
    <Button
      className='p-button-secondary border border-secondary'
      label={label}
      onClick={() => { history.push('/regisztracio') }} />
	)} />
)

const LogoutButton = ({ logoutUser, label }:{logoutUser:any, label:any}) => (
  <Route render={({ history }) => (
    <Button
      className={'btn btn-outline-light mr-2 my-2 my-sm-0 w-100'}
      label={label}
      onClick={(e) => {
			e.preventDefault()
			logoutUser()
			history.push('/')
		}} />
	)} />
)

const UserLoginPanel = ({ t } : {t:any}) => {
	let panelRef = useRef(null)
	const [loggedInButtonOpenClick, setLoggedInButtonOpenClick] = useState(false)
	return (
  <UserContextConsumer>
    {userContext => {
		return (
			<div className='user-login-panel' >
			  <div className='d-flex align-items-end ml-3 cursor-pointer' onClick={(e) => {
				  // @ts-ignore
				  panelRef.current.toggle(e)
				  // @ts-ignore
				  setLoggedInButtonOpenClick(panelRef.current.isVisible())
			  }}>
				  <div className='mx-2 logged-in-text'>{userContext.loggedInUser.user.email}</div>
				  <div><img src={imgUserLogin}/></div>
			  </div>
			  <OverlayPanel ref={panelRef}>
				<div className='mb-3'>
				  <div className='mb-2'>{t('Registration:login.accountData')}:</div>
				  <div>{userContext.loggedInUser.user.fullName}</div>
				  <div>{userContext.loggedInUser.account.company}</div>
				  <div>{userContext.loggedInUser.user.email}</div>
				</div>
				<div className='mb-2'>
				  <Link className='highlight-blue' to='/jelszocsere'>{t('change-password.title')}</Link>
				</div>
				{userContext.loggedInUser.accounts.length > 1 && <div className='mb-2'>
				  <SwitchToAccountForm userContext={userContext} panelRef={panelRef} />
				</div>}
				<LogoutButton label={t('logout.button-title')} logoutUser={userContext.logoutUser} />
			  </OverlayPanel>
			</div>
	)}}
  </UserContextConsumer>)
}

const SwitchToAccountForm = ({ userContext, panelRef } : {userContext:any, panelRef:any}) => {
	const { t, i18n } = useTranslation(['Registration'])
	const possibleAccounts: any[] = []
	userContext.loggedInUser.accounts && userContext.loggedInUser.accounts.forEach((value:any, idx:any) => {
		possibleAccounts.push({ label:value.shortName ? value.shortName : 'ID:' + value.id, value:value })
	})
	return (<Formik
  initialValues={{ switchToAccountValue: userContext.loggedInUser.account }}
  onSubmit={(e) => {}}
	>
  {({ values, errors, setFieldValue, submitForm }) => (
    <form
			>
      <div className='row'>
        <div className='col'>
			<ComboEditor
            fieldName='encoding'
            fieldLabel={t('switchToAccount.title')}
            value={values.switchToAccountValue}
            error={errors.switchToAccountValue}
            possibleValues={possibleAccounts}
            onChange={(value: any) => {
								setFieldValue('switchToAccountValue', value, true)
								userContext.switchToAccount(value.id)
								panelRef.current.hide()
								submitForm()
							}} />
        </div>
      </div>
    </form>
		)}
	</Formik>
	)
}

const LoginStatusPanel = ({ t }:{t:any}) => {
	return (<UserContextConsumer>
  {userContext => {
				if (userContext === null || !userContext.loggedInUser) {
					return <React.Fragment>
  <Link className='p-button button p-component btn btn-lg blue-button btn-primary btn-block p-button-text-only mr-1' to='/bejelentkezes'>
	  <span className='p-button-text p-c'>{t('login.button-title')}</span>
  </Link>
  <RegistrationButton label={t('registration.button-title')} />
					</React.Fragment>
				} else {
					return <React.Fragment>
  <UserLoginPanel t={t} />
					</React.Fragment>
				}
		}}
	</UserContextConsumer>
)
}

const Header = ({ children } : {children?: any}) => {
	const { t, i18n } = useTranslation(['Registration', 'Common'])
	return (
  <header className='ck-header d-flex flex-column flex-md-row align-items-center'>
    <div className='page-center-content'>
      <Menu t={t}>
        <LoginStatusPanel t={t} />
        <div style={{ display: 'none' }}>
          <img height={32} width={32} src={english} onClick={() => { i18n.changeLanguage('en') }} />
        </div>
        <div style={{ display: 'none' }}>
          <img height={32} width={32} src={hungarian} onClick={() => { i18n.changeLanguage('hu') }} />
        </div>
      </Menu>
      {children}
    </div>
  </header>
	)
}

export default Header
