import React, { useState } from 'react'
import { ApolloConsumer, Query } from 'react-apollo'
import gql from 'graphql-tag'
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import EnumUtils from '../../utils/EnumUtils'
import { formatCurrency, formatDateTime } from '../../utils/Localization'
import ApolloClient from 'apollo-client'
import { useTranslation } from 'react-i18next'
import { UserContextConsumer } from '../../components/UserContext'
import PageNotAvailable from './ResourceUse'
import OrderItemsTable, { getOrderItems } from '../../components/OrderItemsTable'

const LIST_ORDER_HEADERS = gql`
	query getCurrentAccount {
		getCurrentAccount{
			orders {
				id
				orderDate
				name
				orderStatus
				totalGrossAmount
			}
		}
	}
`

const OrderHeaderList = ({ orderHeaderValues } : {orderHeaderValues : any[]}) => {
  const { t } = useTranslation('Provisioning')
  let paginatorLeft = <Button icon='pi pi-refresh' />
  let paginatorRight = <Button icon='pi pi-cloud-upload' />
  let [orderItemsRows, setOrderItemsRows] = useState<any>([])
  let [expandedRows, setExpandedRows] = useState<any>([])

  const statusCellValue = (rowData :any, column: any) => {
    return rowData ? <EnumUtils enumClassName='OrderStatus' enumName={rowData.orderStatus} /> : ''
  }

  const orderDateCellValue = (rowData :any, column: any) => {
    return rowData ? (formatDateTime(rowData.orderDate)) : ''
  }

  const priceCellValue = (rowData :any, column: any) => {
    return rowData ? (formatCurrency({ val:rowData.totalGrossAmount })) : ''
  }

  const rowExpansionTemplate = (data: any) => (<OrderItemsTable data={orderItemsRows[data.id]} />)

  return (
    <div>
      {t('megrendelesekPage.table.count', { darab: orderHeaderValues ? orderHeaderValues.length : 0 })}
      <ApolloConsumer>
        {(client : ApolloClient<any>) => (
          <DataTable
            value={orderHeaderValues}
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 20]}
			dataKey='id'
            sortMode='multiple'
            expandedRows={expandedRows}
            onRowToggle={async (e) => {
				for (const key of Object.keys(e.data)) {
					if (!orderItemsRows.hasOwnProperty(key)) {
						const data = await getOrderItems(client, key)
						const newOrderItemRows = { ...orderItemsRows, [key]: data.getOrderItemsByOrderHeaderId }
						setOrderItemsRows(newOrderItemRows)
					}
				}
				setExpandedRows(e.data)
            }}
            rowExpansionTemplate={(data) => rowExpansionTemplate(data)}
            emptyMessage={t('megrendelesekPage.table.notFound')}
          >
            <Column expander />
            <Column field='name' header={t('megrendelesekPage.table.header.name')} sortable />
            <Column field='orderDate' header={t('megrendelesekPage.table.header.orderDate')} sortable body={orderDateCellValue} />
            {/*<Column field='orderStatus' header={t('megrendelesekPage.table.header.orderStatus')} body={statusCellValue} sortable />*/}
            <Column field='totalGrossAmount' header={t('megrendelesekPage.table.header.totalGrossAmount')} sortable body={priceCellValue} />
          </DataTable>
        )}
      </ApolloConsumer>
    </div>
  )
}

const Orders = () => {
  const { t } = useTranslation('Provisioning')
  return (
    <UserContextConsumer>
      { userContext => (
        <React.Fragment>
          {userContext && userContext.loggedInUser && userContext.loggedInUser !== null
            ? <React.Fragment>
              <div className='kezelo-container'>
                <h1 className='mt-5'>{t('megrendelesekPage.title')}</h1>
                <div className='kezelo-content mt-4'>
                  <Query query={LIST_ORDER_HEADERS}>
                    {(result) => {
                      if (result.data && (result.data.loading || !result.data.getCurrentAccount)) {
                        return <div>{t('message.loading')}</div>
                      } else if (!result) {
                        return <div>{t('message.error', { message: JSON.stringify(result) })}</div>
                      } else {
                        return <>{result && result.data && result.data.getCurrentAccount && result.data.getCurrentAccount.orders ? <OrderHeaderList orderHeaderValues={result.data.getCurrentAccount.orders} /> : ''}</>
                      }
                    }}
                  </Query>
                </div>
              </div>
            </React.Fragment>
            :						<PageNotAvailable />
          }
        </React.Fragment>
      )}
    </UserContextConsumer>

  )
}

export default Orders
