import React from 'react'
import { Field } from 'formik'
import { RadioButton } from 'primereact/radiobutton'
import { listEnumValues } from '../../utils/EnumUtils'

/* pageLayout values = vertical|horizontal */
const RadioManyEnumEditor = ({
							 fieldName,
							 value,
							 enumClass,
							 error,
							 tooltips,
							 onChange,
							 disabled = false,
							 pageLayout = 'vertical'
} :
							 {
							 	 fieldName: string,
								 value: any,
								 enumClass: string,
								 error: any,
								 tooltips?: string[],
								 onChange?: any,
								 disabled?: boolean,
								 pageLayout?:string
							 }) => {
  const onRadioChange = (e : any) => {
    if (e.checked) {
      value = e.value
    }
    onChange(value)
  }
  const possibleEnumValues = listEnumValues(enumClass)
  return (
    <div className='form-group'>
      {Object.entries(possibleEnumValues).map((fieldValue, i) => {
        let enumLabel = fieldValue[1]
        let enumValue = fieldValue[0]
        return (
          <div key={'form-group' + fieldName + i} className={pageLayout == 'horizontal' ? 'form-check-inline' : 'form-group'}>
            <div key={'form-check' + fieldName + i} className={`form-check pl-0 ${pageLayout == 'horizontal' ? 'form-check-inline' : ''}`}>
              <RadioButton
                inputId={'input' + fieldName + i}
                className={`${value === enumValue ? 'selected' : ''} ${error ? 'is-invalid' : ''}`}
                value={enumValue}
                name={fieldName}
                onChange={onRadioChange}
                tooltip={tooltips ? tooltips[i] : ''}
                checked={value === enumValue}
                disabled={disabled}
              />
              <label
                key={'label' + fieldName + i}
                htmlFor={'input' + fieldName + i}
                className='p-checkbox-label'>
                {enumLabel}
              </label>
            </div>
          </div>
        )
      })}
      <div className='error-message'>
        <Field
          name={fieldName}
          className='error-message'
          render={({ form } : {form : any}) => {
            return error || null
          }}
        />
      </div>
    </div>
  )
}

export default RadioManyEnumEditor
