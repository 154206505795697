import React from 'react'
import { useTranslation } from 'react-i18next'

const RegistrationPageLayout = ({ children, ...rest } : {children?: any, rest?: any}) => {
  const { t } = useTranslation('Common')
  return (
    <div className='ck-root registration-layout d-flex align-items-center'>
      <main className='ck-main container-fluid'>
        {children}
      </main>
    </div>
  )
}

export default RegistrationPageLayout
