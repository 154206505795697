import React, { useState } from 'react'
import imgRefTelekom from '../../images/ref_logo/magyar-telekom-logo.jpg'
import imgRefOtpBank from '../../images/ref_logo/otp-bank-logo.jpg'
import imgRefIp from '../../images/ref_logo/ingatlanpont-logo.jpg'
import imgRefJbz from '../../images/ref_logo/jelzalogbank-logo.jpg'
import { useTranslation } from 'react-i18next'
import MainPageOperator from './MainPageOperator'
import ScrollUpButton from 'react-scroll-up-button'
import imgScrollToTop48 from '../../images/arrowhead-up-48.png'

export const ScrScrollUpButton = () => {
  return <ScrollUpButton
      ContainerClassName='scroll-up-button-container'
      TransitionClassName='scroll-up-button-transition'
  >
    <img src={imgScrollToTop48}/>
  </ScrollUpButton>
}


const MainPage = () => {
	const { t, i18n } = useTranslation(['Registration', 'Common', 'Provisioning'])
	const [panelVisible, setPanelVisible] = useState(false)
	const [buttonsSelected, setButtonsSelected] = useState<string>('')

	const InfoBox = ({ children }:{children:any}) => {
		return (<div className='info-box col-md-12 col-lg-4 my-3'>
  <article className='d-flex h-100 align-items-center p-3'>
    <div>
      {children}
    </div>
  </article>
		</div>)
	}

	return <React.Fragment>
  <div className='main-page-container'>
    {/* <section className='more-info-content text-center page-center-content pt-5 pb-5'> */}
    {/* <h2 className='mb-5'>Mire lenne szükséged?</h2> */}
    {/* <div className='row justify-content-center'> */}
    {/* <InfoBox> */}
    {/* <div className='mb-3'> */}
    {/* <img src={imgInventory32}/> */}
    {/* </div> */}
    {/* <h3 className='font-weight-bold'>{t('Provisioning:mainPage.infoBox1.title')}</h3> */}
    {/* <p> */}
    {/* {t('Provisioning:mainPage.infoBox1.text')} */}
    {/* </p> */}
    {/* <Button label='Érdekel'></Button> */}
    {/* </InfoBox> */}
    {/* <InfoBox> */}
    {/* <div className='mb-3'> */}
    {/* <img src={imgClock32}/> */}
    {/* </div> */}
    {/* <h3 className='font-weight-bold'>{t('Provisioning:mainPage.infoBox2.title')}</h3> */}
    {/* <p> */}
    {/* {t('Provisioning:mainPage.infoBox2.text')} */}
    {/* </p> */}
    {/* <Button label='Érdekel'></Button> */}
    {/* </InfoBox> */}
    {/* <InfoBox> */}
    {/* <div className='mb-3'> */}
    {/* <img src={imgGear32}/> */}
    {/* </div> */}
    {/* <h3 className='font-weight-bold' dangerouslySetInnerHTML={{__html:t('Provisioning:mainPage.infoBox3.title')}}></h3> */}
    {/* <p> */}
    {/* {t('Provisioning:mainPage.infoBox3.text')} */}
    {/* </p> */}
    {/* <Button label='Érdekel'></Button> */}
    {/* </InfoBox> */}
    {/* </div> */}
    {/* </section> */}

    <MainPageOperator />
   <ScrScrollUpButton/>
    <section className='more-info-content2 py-4'>
      <div className='page-center-content px-2 px-md-5'>
        <h3 className='mt-5 mb-4'>{t('Provisioning:mainPage:infoContent2.title')}</h3>
        <p>
          {t('Provisioning:mainPage:infoContent2.text1')}
        </p>
      </div>
    </section>
    <section className='more-info-content3 py-4'>
      <div className='page-center-content px-2 px-md-5'>
        <h3 className='mt-5 mb-4'>{t('Provisioning:mainPage:infoContent3.title')}</h3>
        <ul className='row'>
          <li className='col-12 col-md-6'>
            <div>
              {t('Provisioning:mainPage:infoContent3.text1')}
            </div>
          </li>
          <li className='col-12 col-md-6'>
            <div>
              {t('Provisioning:mainPage:infoContent3.text2')}
            </div>
          </li>
          <li className='col-12 col-md-6'>
            <div>
              {t('Provisioning:mainPage:infoContent3.text3')}
            </div>
          </li>
          <li className='col-12 col-md-6'>
            <div>
              {t('Provisioning:mainPage:infoContent3.text4')}
            </div>
          </li>
          <li className='col-12 col-md-6'>
            <div>
              {t('Provisioning:mainPage:infoContent3.text5')}
            </div>
          </li>
          <li className='col-12 col-md-6'>
            <div>
              {t('Provisioning:mainPage:infoContent3.text6')}
            </div>
          </li>
          <li className='col-12 col-md-6'>
            <div>
              {t('Provisioning:mainPage:infoContent3.text7')}
            </div>
          </li>
          <li className='col-12 col-md-6'>
            <div>
              {t('Provisioning:mainPage:infoContent3.text8')}
            </div>
          </li>
          <li className='col-12 col-md-6'>
            <div>
              {t('Provisioning:mainPage:infoContent3.text9')}
            </div>
          </li>
        </ul>
      </div>
    </section>
    <section className='reference-content py-4'>
      <div className='page-center-content px-2 px-md-5'>
        <h5 className='mt-4 mb-4 text-center'>{t('Provisioning:mainPage:referenceContent.title')}</h5>
      </div>
      <div className='row align-items-center justify-content-center px-2 px-md-5 page-center-content'>
        <div className='image-box'>
          <img src={imgRefOtpBank} />
        </div>
        <div className='image-box'>
          <img src={imgRefJbz} />
        </div>
        <div className='image-box'>
          <img src={imgRefIp} />
        </div>
        <div className='image-box'>
          <img src={imgRefTelekom} />
        </div>
      </div>

    </section>
  </div>
		</React.Fragment>
}

export default MainPage
