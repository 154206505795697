import React from 'react'
import logo from '../images/logo.png'
import { Link } from 'react-router-dom'
import { UserContextConsumer } from '../components/UserContext'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'

const GET_COMMON_DATA = gql`{
    getCommonData {
        distribType
    }}`

export const Logo = ({ t }:{t:any}) => (
    <div className='d-flex align-items-center'>
        <Link className='logo d-inline-block' to='/'>
          <img src={logo} className='logo-img' alt='logo' />
        </Link>
        <Link className='logo d-inline-block' to='/'>
          <span className='logo-text ml-2'>{t('Common:menu.title')}</span>
        </Link>
    </div>
)

const DistribType = ({ t }:{t:any}) => {
    return (
        // @ts-ignore
        <Query query={GET_COMMON_DATA}>
            {(result: any) => {
                if (result.data && result.data.getCommonData) {
                    if (result.data.getCommonData.distribType === 'dev' || result.data.getCommonData.distribType === 'test') {
                        return <div className={`distrib-type ${result.data.getCommonData.distribType}`}>{t('Common:distribType.' + result.data.getCommonData.distribType)}</div>
                    }
                }
                return ''
            }}
        </Query>
    )
}

const Menu = ({ t, children } : {t : any, children? : any}) => (
  <UserContextConsumer>
    {userContext => (
      <>
        <nav className='navbar container-fluid navbar-expand-sm flex-lg-column navbar-light'>
          <div className='container-fluid no-gutters pl-0 pr-0'>
            <div className='navbar-brand position-relative'>
              <Logo t={t} />
                <DistribType t={t} />
            </div>
            <div className='form-inline my-2 my-lg-0 d-flex flex-nowrap order-0 order-sm-1'>
              <button className='navbar-toggler ml-2' type='button' data-toggle='collapse' data-target='#ckNavbar' aria-controls='ckNavbar'
                aria-expanded='false' aria-label='Toggle navigation'>
                <span className='navbar-toggler-icon' />
              </button>
              {children}
            </div>
            {(userContext !== null && userContext.loggedInUser) &&
            <>
              <div className='collapse navbar-collapse w-100 mt-2 justify-content-end order-1 order-sm-0' id='ckNavbar'>
                <ul className='navbar-nav mt-2 mt-lg-0'>
                  {/* <li className='nav-item text-nowrap flex-grow-1 text-uppercase pb-2 pb-lg-0'> */}
                  {/* <Link to='/proba_csomag'>{t('Common:menu.demo')}</Link> */}
                  {/* </li> */}
                  {/* <li className='nav-item text-nowrap flex-grow-1 text-uppercase pb-2 pb-lg-0'> */}
                  {/* <Link to='/recepcio'>{t('Common:menu.reception')}</Link> */}
                  {/* </li> */}
                  <li className='nav-item dropdown text-nowrap flex-grow-1 text-uppercase pb-2 pb-lg-0'>
                    <a className='nav-item dropdown-toggle' href='#' id='navbarDropdown' role='button' data-toggle='dropdown' aria-haspopup='true'
                      aria-expanded='false'>
                      {t('Common:menu.account.title')}
                    </a>
                    <div className='dropdown-menu' aria-labelledby='navbarDropdown'>
                      {/* <Link className="dropdown-item" to='/regisztracio'>Regisztráció</Link> */}
                      <Link className='dropdown-item' to='/torzsadatok'>{t('Common:menu.account.masterData')}</Link>
                      <Link className='dropdown-item' to='/megrendelesek'>{t('Common:menu.account.orders')}</Link>
                      <Link className='dropdown-item' to='/felhasznalasok'>{t('Common:menu.account.usage')}</Link>
                      <Link className='dropdown-item' to='/egyenlegek'>{t('Common:menu.account.balance')}</Link>
                    </div>
                  </li>
                  {/* <li className='nav-item text-nowrap flex-grow-1 text-uppercase pb-2 pb-lg-0'> */}
                  {/* <Link to='/kezelo'>{t('Common:menu.operation')}</Link> */}
                  {/* </li> */}
                </ul>
                { userContext.loggedInUser.roles.some( role => role === 'SZUPER') ?
                  <ul className='navbar-nav mt-2 mt-lg-0'>
                      <li className='nav-item dropdown text-nowrap flex-grow-1 text-uppercase pb-2 pb-lg-0'>
                          <a className='nav-item dropdown-toggle' href='#' id='navbarDropdown' role='button' data-toggle='dropdown' aria-haspopup='true'
                              aria-expanded='false'>
                              {t('Common:menu.superuser.title')}
                          </a>
                          <div className='dropdown-menu' aria-labelledby='navbarDropdown'>
                              <Link className='dropdown-item' to='/szuperfelhasznalo/felhasznalok'>{t('Common:menu.superuser.users')}</Link>
                              <Link className='dropdown-item' to='/szuperfelhasznalo/elofizetesek'>{t('Common:menu.superuser.accounts')}</Link>
                          </div>
                      </li>
                  </ul>
                    :
                  null
                }
              </div>
            </>
				    }
          </div>
        </nav>
      </>
    )}
  </UserContextConsumer>
)

export default Menu
