import React, { useState } from 'react'
import { UserContextConsumer } from '../../components/UserContext'
import { Formik } from 'formik'
import { Button } from 'primereact/button'
import TextEditor from '../../components/editors/TextEditor'
import { Mutation } from 'react-apollo'
import gql from 'graphql-tag'
import { useTranslation } from 'react-i18next'
import { RegistrationContentWrapper } from './RegistrationPage'
import { ProgressBar } from 'primereact/progressbar'
import { withRouter } from 'react-router'

const REMIND_PASSWORD_MUTATION = gql`
    mutation ForgotPassword($email: String) {
        forgotPassword(email: $email)
    }
`

const PasswordReminderForm = ({ email, remindPassword, props }: {email:string, remindPassword:any, props:any}) => {
	const { t } = useTranslation('Registration')
	let [working, setWorking] = useState(false)
	return <UserContextConsumer>
			{(userContext) => (
				<Formik
					initialValues={{ email: email }}
					validate={values => {
						let errors = {}
						if (!values.email) {
							// @ts-ignore
							errors.email = t('login.errors.email.required')
						} else if (
							!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
						) {
							// @ts-ignore
							errors.email = t('login.errors.email.invalid')
						}
						return errors
					}}
					onSubmit={async (values, { setSubmitting }) => {
						setWorking(true)
						let resultRemindPass : any = {}
						try {
							resultRemindPass = await remindPassword({
								variables: {
									email: values.email
								} })
							if ( resultRemindPass && 'code' in resultRemindPass) {
								userContext.growl({ severity: 'error', summary: resultRemindPass.message })
							}
							else {
								userContext.growl({ summary: 'A jelszó emlékeztető email sikeresen elküldve!' })
								// setTimeout(()=>{},5000)
								props.history.push('/')
							};
							setWorking(false)
						} catch (e) {
							console.log(JSON.stringify(e))
							setWorking(false)
							// @ts-ignore
							userContext.growl({ severity: 'error', summary: e.graphQLErrors[0].message })
						}
					}}
				>
					{({
						  values,
						  errors,
						  touched,
						  handleChange,
						  handleBlur,
						  handleSubmit,
						  isSubmitting,
						  setFieldValue
						  /* and other goodies */
					  }) => (
						<React.Fragment>
							<div>
								{t('login.password-reminder-dialog.message')}
							</div>
							{working && <ProgressBar mode='indeterminate' />}
							<form className='form-dialog mt-3'
								onSubmit={handleSubmit}
							>
								<div className='row mb-2'>
									<div className='col-12 col-md-12'>
										<TextEditor fieldName='email' placeholder={t('login.e-mail')} value={values.email} error={errors.email} handleChange={handleChange} handleBlur={handleBlur} />
									</div>
								</div>
								<div className='text-right'>
									<Button label={t('Common:button.ok')} className='' type='submit' disabled={working} />
								</div>
							</form>
						</React.Fragment>
					)}
				</Formik>
			)}
		</UserContextConsumer>
}

const PasswordReminderPage = (props) => {
	const { t } = useTranslation('Registration')
	return (
		<UserContextConsumer>
			{values => {
				let email = props.match.params.email
				return (
					<Mutation mutation={REMIND_PASSWORD_MUTATION}>
						{(remindPassword) => (
							<RegistrationContentWrapper t={t} title={t('login.password-reminder-dialog.title')}>
								<PasswordReminderForm email={email} remindPassword={remindPassword} props={props}/>
							</RegistrationContentWrapper>
						)}
					</Mutation>
				)
			}}
		</UserContextConsumer>
	)
}

export default withRouter(PasswordReminderPage)
