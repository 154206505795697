import { Field } from 'formik'
import React from 'react'
import { Calendar } from 'primereact/calendar'
import { CalendarLocale } from '../../utils/Localization'

// graphql-nek  moment(values.date).format() konvertálással kell átadni
// monthNavigator: hónap választó a dropdown-ban
// yearNavigator: év választó a dropdown-ban, kötelező hozzá a yearRange megadása
// yearRange: yearNavigatorral együtt használatos: pl: '2010:2030' formátumban

const DateEditor = ({
  fieldName,
  fieldLabel,
  placeholder,
  value,
  error,
  onChange,
  handleBlur,
  disabled = false,
  monthNavigator = false,
  yearNavigator = false,
  yearRange,
  minDate,
  maxDate } :
						{
							fieldName: any,
							fieldLabel?: any,
							placeholder?:any,
							value: any,
							error: any,
							onChange: any,
							handleBlur: any,
							disabled?: boolean,
							monthNavigator?:boolean,
							yearNavigator?:boolean,
							yearRange?:any,
							minDate?:Date,
							maxDate?:Date
						}) => {
  const onDateChange = (e: any) => {
    onChange(e.value)
  }
  return (<div className=''>
    <div className='form-group w-100'>
      {fieldLabel ? <label htmlFor={'input' + fieldName}>{fieldLabel}</label> : ''}
      <Calendar
        locale={CalendarLocale}
        className={`form-control p-0 border-0 ${error ? 'is-invalid' : ''}`}
        id={'input' + fieldName}
        name={fieldName}
        placeholder={placeholder || fieldLabel}
        value={value}
        onChange={onDateChange}
        monthNavigator={monthNavigator}
        yearNavigator={yearNavigator}
        yearRange={yearRange}
        minDate={minDate}
        maxDate={maxDate}
        onBlur={handleBlur}
        disabled={disabled}
        dateFormat='yy.mm.dd.'
        showIcon
        icon='pi pi-calendar'

        // showButtonBar={true}
      />
      <div className='error-message'>
        <Field
          name={fieldName}
          className='error-message'
          render={({ form } : {form : any}) => {
            return error || null
          }}
        />
      </div>
    </div>
  </div>
  )
}

export default DateEditor
