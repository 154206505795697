import React, { ReactComponentElement, useReducer, useState } from 'react'
import { Button } from 'primereact/button'
import ConcatenatedAddressInputForm from '../operator/inputPanel/ConcatenatedAddressInputForm'
import SelectButtonEnumEditor from '../../components/editors/SelectButtonEnumEditor'
import ProvisionedSetSelector from '../../components/ProvisionedSetSelector'
import { UserContextConsumer } from '../../components/UserContext'
import { Field, Formik } from 'formik'
import ApolloClient from 'apollo-client'
import { InputAddress, meterAddress, standardizeAddress, xRayAddress } from '../../makett/makett-service'
import { MAX_FILE_SIZE, REMAINED_AMOUNT, RemainedAmountQuery } from '../operator/IndividualValidation'
import { ApolloConsumer, Mutation } from 'react-apollo'
import OperatorPanelWrapper from '../operator/inputPanel/OperatorPanelWrapper'
import LazmeroResultPanel from './LazmeroResultPanel'
import RontgenResultPanel from './RontgenResultPanel'
import { FILEUPLOAD_MUTATION, ListBatchRequest } from './BatchForm'
import AddressResultPanel from '../operator/resultPanel/AddressResultPanel'
import imgThermoH from '../../images/kezelo_thermometer2y-64.png'
import imgThermo from '../../images/kezelo_thermometer2w-64.png'
import imgXRay from '../../images/kezelo-xray-w-64.png'
import imgXRayH from '../../images/kezelo-xray-y-64.png'
import imgList from '../../images/kezelo_shortlist-filled-w-64.png'
import imgListH from '../../images/kezelo_shortlist-filled-y-64.png'
import imgMedal48 from '../../images/main-medal-48.png'
import imgVerify48 from '../../images/main-verify-48.png'
import imgData48 from '../../images/main-data-48.png'
import imgPercent48 from '../../images/main-percent-48.png'
import imgShapeStar48 from '../../images/main-shape-star2-48.png'
import imgUserHeadphone48 from '../../images/main-user-headphone-48.png'
import imgCube48 from '../../images/main-molecule-48.png'
import imgGraph248 from '../../images/main-graph2-48.png'
import imgFaves48 from '../../images/main-faves-48.png'
import imgScale48 from '../../images/main-scale-48.png'
import imgExcel48 from '../../images/main-excel-48.png'
import imgClean48 from '../../images/main-clean-48.png'
import imgLayout48 from '../../images/main-layout-48.png'
import imgDoubleCheck48 from '../../images/main-double-check-48.png'
import imgPin48 from '../../images/main-maps-48.png'
import { AttributeBox } from '../main/MainPageOperator'
import { ScrScrollUpButton } from '../main/MainPage'
import ReactTooltip from 'react-tooltip'
import Scroll from 'react-scroll'
import { RouteComponentProps, withRouter } from 'react-router'
import { TFunction } from 'i18next'

export const SERVICETYPE_LAZMERES = 'Lazmeres'
export const SERVICETYPE_RONTGEN = 'Rontgen'
export const SERVICETYPE_TISZTITAS = 'Tisztitas'
export const SERVICETYPE_LOCALSTORAGE_KEY = 'serviceTypeInLocalStorage'


const BatchInfo = ({ t, className }:{t:any, className?:string}) => {
	return <div className={className}>
		<h6 className='my-4 font-weight-bold'>{t('Provisioning:upload-form.desc1')}</h6>
		<div className='mt-3'>
			<ul>
				<li>{t('Provisioning:upload-form.condition-text.item1')}</li>
				<li>{t('Provisioning:upload-form.condition-text.item2')}</li>
				<li>{t('Provisioning:upload-form.condition-text.item3')}</li>
				<li>{t('Provisioning:upload-form.condition-text.item4')}</li>
				<li>{t('Provisioning:upload-form.condition-text.item5')}</li>
				<li>{t('Provisioning:upload-form.condition-text.item6')}</li>
				<li>{t('Provisioning:upload-form.condition-text.item7')}</li>
				<li>{t('Provisioning:upload-form.condition-text.item8')}</li>
			</ul>
		</div>
	</div>
}

const InfoBox = ({ children }:{children:any}) => {
	return (<div className='info-box col-md-12 col-lg-4 my-3'>
		<article className='d-flex h-100 align-items-center p-3'>
			<div>
				{children}
			</div>
		</article>
	</div>)
}

const HrWithText = ({ value }:{ value:string }) => {
	return (
		<div className='hr-with-text d-flex align-items-center'>
			<span className='title'> {value}</span>
		</div>
		)
}

const useStateWithLocalStorage = localStorageKey => {
	const [value, setValue] = React.useState<string>(
		localStorage.getItem(localStorageKey) || ''
	)

	React.useEffect(() => {
		localStorage.setItem(localStorageKey, value)
	}, [localStorageKey, value])

	return [value, setValue]
}

const BatchInputPanel = withRouter<any, any>(({ serviceType: inputServiceType, t, history } : { serviceType: string, t: TFunction, history : any }) => {
	let [quota, setQuota] = useState(undefined)
	let [days, setDays] = useState(undefined)
	let [hour, setHour] = useState(undefined)
	let [minute, setMinute] = useState(undefined)
	let [result, setResult] = useState<any>(undefined)
	let [progress, setProgress] = useState(false)
	let [showTable, setShowTable] = useState(false)
	const [serviceType, setServiceType] = useState(inputServiceType)

	// const [lazmeresServiceOptions, setLazmeresServiceOptions] = useState<any>(['Hazszam'])
	// const [rontgenServiceOptions, setRontgenServiceOptions] = useState<any>(['Hazszam'])
	// const [tisztitasServiceOptions, setTisztitasServiceOptions] = useState<any>(['Terkeppont', 'Hazszam', 'Bontva'])

	//useState helyett useReducer (így egy helyen kezelhető a tömbbeli hozzáadás és törlés és alapértékek)
	const [lazmeresServiceOptions, setLazmeresServiceOptions] = useReducer((lazmeresServiceOptions, { type, value }) => {
		switch (type) {
			case 'add':
				return [...lazmeresServiceOptions, value]
			case 'remove':
				return lazmeresServiceOptions.filter((_, index) => index !== value)
			default:
				return lazmeresServiceOptions
		}}, ['Hazszam'])
	const [rontgenServiceOptions, setRontgenServiceOptions] = useReducer((rontgenServiceOptions, { type, value }) => {
		switch (type) {
			case 'add':
				return [...rontgenServiceOptions, value]
			case 'remove':
				return rontgenServiceOptions.filter((_, index) => index !== value)
			default:
				return rontgenServiceOptions
		}}, ['Hazszam'])
	const [tisztitasServiceOptions, setTisztitasServiceOptions] = useReducer((tisztitasServiceOptions, { type, value }) => {
		switch (type) {
			case 'add':
				return [...tisztitasServiceOptions, value]
			case 'remove':
				return tisztitasServiceOptions.filter((_, index) => index !== value)
			default:
				return tisztitasServiceOptions
		}}, ['Terkeppont', 'Hazszam', 'Bontva'])

	const KezeloButton = ({ icon, iconSelected, title, onClick }:{icon:any, iconSelected:any, title:string, onClick:any}) => {
		let selected = serviceType === title
		const onButtonClick=(e) => {
			onClick(e)
			//@ts-ignore
			setServiceType(title)
		}
		return <button className={`kezelo-button text-center ${selected ? 'selected' : ''}`}
			onClick={onButtonClick}
			type='submit'>
			<img src={selected ? iconSelected : icon } />
			<div className='mt-2 title' >{t(`Registration:landingPage.kezelo-buttons.${title}`) }</div>
			<div className='triangle-bl'></div>
		</button>
	}

	//módosítja a serviceOption-t, mind három típusnál. Ha a típushoz tartozó tömbben szerepel a kitörölt elem akkor törli a listákból.
	//Ha típushoz tartozó alapértékek között szerepel a hozzáadott elem akkor hozzáadja.
	const modifyServiceOptionsItem = (values:[], serviceOptions:[], serviceType:any) => {

		let result = getDiffArrays(getSelectedServiceOptions(serviceType), values) || ''
		let index = lazmeresServiceOptions.indexOf(result)
		if (index !== -1) {
			setLazmeresServiceOptions({ type:'remove', value:index })
		}
		else if (['Hazszam'].includes(result)) {
			setLazmeresServiceOptions({ type:'add', value:result })
		}
		index = rontgenServiceOptions.indexOf(result)
		if (index !== -1) {
			setRontgenServiceOptions({ type:'remove', value:index })
		}
		else if (['Hazszam'].includes(result)){
			setRontgenServiceOptions({ type:'add', value:result })
		}
		index = tisztitasServiceOptions.indexOf(result)
		if (index !== -1) {
			setTisztitasServiceOptions({ type:'remove', value:index })
		}
		else if (['Terkeppont', 'Hazszam', 'Bontva'].includes(result)) {
			setTisztitasServiceOptions({ type:'add', value:result })
		}
	}

	//megkeresi, hogy a két tömb között mi a különbség.
	// Mivel egérkattintásra hivódik ezért max egy elem különbségnek kell lennie
	let getDiffArrays = (array1:[], array2:[]) => {
		if (array1.length < array2.length) {
			let temp = array1
			array1 = array2
			array2 = temp
		}
		let result = array1.filter((e:never) => !array2.includes(e))
		if (result.length > 0) {
			return result[0]
		}
	}

	let getSelectedServiceOptions = (serviceType:any) => {
		if (serviceType === 'Lazmeres') {
			return lazmeresServiceOptions
		} else if (serviceType === 'Rontgen') {
			return rontgenServiceOptions
		} else if (serviceType === 'Tisztitas') {
			return tisztitasServiceOptions
		}
	}
	let setSelectedServiceOptions = (serviceType:any, serviceOptions:any) => {
		if (serviceType === 'Lazmeres') {
			setLazmeresServiceOptions(serviceOptions)
		} else if (serviceType === 'Rontgen') {
			setRontgenServiceOptions(serviceOptions)
		} else if (serviceType === 'Tisztitas') {
			setTisztitasServiceOptions(serviceOptions)
		}
	}

	return (
		<UserContextConsumer>
			{ userContext =>(
				<Mutation mutation={FILEUPLOAD_MUTATION}>
					{(createBatchRequest) => (
						<ApolloConsumer>
							{(client : ApolloClient<any>) => (
								<Formik
									initialValues={{ serviceType:serviceType, provisionedSetId:null, fullAddress:'', file: null, serviceOptions: getSelectedServiceOptions(serviceType) as Array<any>, runMakett: false }}
									validate={values => {
										let errors: any = {}
										if (!values.file && !values.fullAddress) {
											errors.fullAddress = t('Common:message.required')
										}
										if (!values.serviceType ) {
											// @ts-ignore
											errors.serviceType = [t('Common:message.required')]
										}
										if (!values.file && !values.fullAddress) {
											// @ts-ignore
											errors.file = [t('Common:message.required')]
										}
										return errors
									}}
									onSubmit={async (values, { setSubmitting, resetForm }) => {
										//@ts-ignore
										if (quota === 0 || days && days < 0) {
											//@ts-ignore
											if (days && days < 0) {
												userContext.growl({ severity: 'error', summary: t('Provisioning:message.interfaceClosed') })
											}
											if (quota === 0) {
												userContext.growl({ severity: 'error', summary: t('Provisioning:message.quotaRunOut') })
											}
										}
										else {
											setResult(null)
											setProgress(true)
											let address:InputAddress={}
											address.fullAddress = values.fullAddress
											try {
												let resultAddress = {}
												if (values.runMakett) {
													if (values.serviceType === SERVICETYPE_RONTGEN) {
														resultAddress = await xRayAddress(address, userContext.loggedInUser.account.webApiKey, values.serviceOptions.join('|'))
													} else if (values.serviceType === SERVICETYPE_LAZMERES) {
														resultAddress = await meterAddress(address, userContext.loggedInUser.account.webApiKey, values.serviceOptions.join('|'))
													}
													else if (values.serviceType === SERVICETYPE_TISZTITAS) {
														resultAddress = await standardizeAddress(address, userContext.loggedInUser.account.webApiKey, values.serviceOptions.join('|'))
													}
													setProgress(false)
													setResult(resultAddress)
												} else {
													//max file size ellenőrzés
													const { data } = await client.query({ query: MAX_FILE_SIZE })
													//@ts-ignore
													if (data.getMaxFileSize.maxFileSizeValue > 0 && values.file.size > data.getMaxFileSize.maxFileSizeValue)
													{
														setProgress(false)
														userContext.growl({ severity: 'error', summary: t('Provisioning:upload-form.error-message-max-file-size', { maxFileSize: data.getMaxFileSize.maxFileSize }) })
													}
													else {
														const resultUpload = await createBatchRequest({
															variables: {
																file: values.file,
																encoding: 'iso-8859-2',
																provisionedSetId: values.provisionedSetId,
																serviceType: values.serviceType,
																options: values.serviceOptions,
																separator: ''
															} })
														const resultAddress = resultUpload && resultUpload.data ? resultUpload.data.createBatchRequest.requestFileName : undefined
														const message = resultAddress ? (t('Provisioning:upload-form.success-message') + ' (' + resultAddress + ')') : ''
														resetForm()
														setProgress(false)
														userContext.growl({ severity: 'info', summary: message })
														//@ts-ignore
														document.getElementById('formikInpuFile').value= ''
														setShowTable(true)
														Scroll.scroller.scrollTo('batchRequestTable', {
															duration:500,delay:200, smooth:true
														})
													}
												}
												if (resultAddress) {
													const { data } = await client.query({ query: REMAINED_AMOUNT,
														variables: {
															apiKey: userContext.loggedInUser.account.webApiKey,
															serviceType: values.serviceType,
															interfaceType: 'Kezelo'
														},fetchPolicy:'network-only'
													})
													data.getRemainedAmount && setQuota(data.getRemainedAmount.quota)
													data.getRemainedAmount && setDays(data.getRemainedAmount.days)
												}
											} catch (e) {
												console.log(JSON.stringify(e))
												setProgress(false)
												// setResult({ message: t('Provisioning:kezelo-form.makett-error') })
												// if (values.serviceType === SERVICETYPE_TISZTITAS) {
													userContext.growl({ severity: 'error', summary: t('Provisioning:upload-form.error-message') })
												// }
											}
										}



									}}
								>
									{({
										  values,
										  errors,
										  touched,
										  handleChange,
										  handleBlur,
										  handleSubmit,
										  isSubmitting,
										  setFieldValue,
										  submitForm
										  /* and other goodies */
									  }) => (
										<form className='xray-form'
											onSubmit={e => {
												handleSubmit(e)
											}
											}
										>

											<ScrScrollUpButton/>
											{/*flex order bevezetése azért kell mert a default submit másképp nem volt megadható.
											Az első gomb a default így úgy kell rendezni, hogy A "Kérem az eredményt!" legyen az első!*/}
											<div className='d-flex flex-column'>
												<div className='order-3'>
													<div className='container-fluid'>
														<div className='page-center-content pt-5'>
															<OperatorPanelWrapper
																remainedAmountQuery={
																	<RemainedAmountQuery
																		apiKey={userContext.loggedInUser.account.webApiKey}
																		quota={quota}
																		setQuota={setQuota}
																		days={days}
																		setDays={setDays}
																		hour={hour}
																		setHour={setHour}
																		minute={minute}
																		setMinute={setMinute}
																		t={t}
																		serviceType={values.serviceType}
																	/>
																}
																leftColumnContent={
																	<>
																		<section>
																			<div className='row'>
																				<div className='col-12'>
																					<ProvisionedSetSelector
																						t={t}
																						accountId={userContext.loggedInUser.account.id}
																						value={values.provisionedSetId}
																						error={errors.provisionedSetId}
																						onChange={(value: any) => setFieldValue('provisionedSetId', value, true)}
																					/>
																				</div>
																			</div>
																		</section>
																		<section className='px-3'>
																			<div className='row'>
																				<div className='col-12 my-3'>
																					<HrWithText value={ values.serviceType === SERVICETYPE_TISZTITAS ?
																						t('Provisioning:batchInputPanel.select-options'):
																						t('Provisioning:batchInputPanel.select-option')
																					}/>
																				</div>
																				<div className='col-12'>
																					<div className='form-group w-100'>
																						<SelectButtonEnumEditor
																							fieldName='serviceOptions'
																							value={values.serviceOptions}
																							enumClass={values.serviceType === SERVICETYPE_TISZTITAS ? 'BatchServiceOptions' : 'XRayServiceOptions'}
																							error={errors.serviceOptions}
																							tooltips={values.serviceType === SERVICETYPE_TISZTITAS ? t('Provisioning:upload-form.service-options-tooltip') : t('Provisioning:rontgen-form.service-options-tooltip')}
																							onChange={(value: any) => {
																								setFieldValue('serviceOptions', value, true)
																								modifyServiceOptionsItem(value as never,
																									values.serviceOptions as never, values.serviceType)
																								// setSelectedServiceOptions(values.serviceType, value)
																								setResult(null)
																								}
																							}
																							multiple={true}
																						/>
																					</div>
																				</div>
																			</div>
																		</section>
                                                                        <section className='px-3 pb-3'>
																			<div className='row'>
																				<div className='col-12 my-3'>
																					<HrWithText value={t('Provisioning:batchInputPanel.enter-the-address')}/>
																				</div>
																				<div className='col-12 address-input-panel'>
																				  <div className='row'>
																					<div className='col-12'>
																						<ConcatenatedAddressInputForm
																							values={values}
																							placeholder={t('Provisioning:kezelo-form.addressInput')}
																							errors={errors}
																							onAddressChanged={(value:any)=>{}}
																							handleChange={(e) => {
																								setShowTable(false)
																								setResult(null)
																								handleChange(e)}}
																							handleBlur={handleBlur}/>
																					</div>
																					<div className='col-12'>
																						<div className='text-right'>
																							<Button
																								className='btn btn-outline-success my-2 my-sm-0 ml-2'
																								label={t('Provisioning:kezelo.button.run-makett')}
																								type='submit'
																								onClick={async (e) => {
																									e.preventDefault()
																									await setFieldValue('runMakett', true)
																									submitForm()
																								}}
																							/>
																						</div>
																					</div>
																				  </div>
																				</div>
																			</div>
                                                                        </section>
                                                                        <section className='px-3 pb-3'>
																			<div className='row'>
																			  <div className='col-12 my-3'>
																				<HrWithText value={t('Provisioning:batchInputPanel.upload-file')}/>
																			  </div>
																				<div className='col-12'>
																					<div className='form-group w-100 file-upload'>
																						<div className='row'>
																							<div className='col'>
																								<div className='form-control p-0 border-0'>
																									<Field name='inputfile'
																										render={({ field } : {field : any}) => (
																											<input id='formikInpuFile' type='file' name='inputfile'
																												accept='.csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
																												onChange={(e)=>(setFieldValue('file', (e.target.files && e.target.files.item(0))))
																												} />
																										)}>

																									</Field>

																								</div>
																								<div className='error-message mt-0'>
																									<Field
																										name='inputfile'
																										render={({ form }: { form: any }) => {
																											return errors.file ?  errors.file : null
																										}}
																									/>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																				<div className='col-12'>
																					<div className='text-right'>
																						<ReactTooltip/>
																						<Button
																							disabled={!values.file}
																							className='btn btn-outline-success my-2 my-sm-0 ml-2'
																							label={t('Provisioning:kezelo.button.do-file-upload')}
																							data-tip={t('Provisioning:kezelo.button.do-file-upload-tooltip')}
																							onClick={async (e) => {
																								e.preventDefault()
																								await setFieldValue('runMakett', false)
																								submitForm()
																							}}
																						/>
																						<Button
																							type='button'
																							className='btn invert-button my-2 my-sm-0 ml-2'
																							label={t('Provisioning:kezelo.button.open-table')}
																							// disabled={!showTable}
																							data-tip={t('Provisioning:kezelo.button.open-table-tooltip')}
																							onClick={e => {
																								!showTable && setShowTable(!showTable)
																								Scroll.scroller.scrollTo('batchRequestTable', {
																									duration:500,delay:200, smooth:true
																								})
																							}}>
																						</Button>
																					</div>
																				</div>
																			</div>
																		</section>
																	</>
																}

																rightColumnContent={
																	<>
																		{ values.serviceType === SERVICETYPE_LAZMERES &&
                                                                        <LazmeroResultPanel result={result} t={t} progress={progress}>
                                                                          <h6 className='font-weight-bold my-4'>{t('Provisioning:lazmero-form.title2')}</h6>
                                                                          <p>
																			  {t('Provisioning:lazmero-form.desc1')}
                                                                          </p>
                                                                          <p>
																			  {t('Provisioning:lazmero-form.desc2')}
                                                                          </p>
                                                                          <div>{t('Provisioning:rontgen-form.lista.title')}</div>
                                                                          <ul>
                                                                            <li>{t('Provisioning:rontgen-form.lista.item1')}</li>
                                                                            <li>{t('Provisioning:rontgen-form.lista.item2')}</li>
                                                                            <li>{t('Provisioning:rontgen-form.lista.item3')}</li>
                                                                            <li>{t('Provisioning:rontgen-form.lista.item4')}</li>
                                                                            <li>{t('Provisioning:rontgen-form.lista.item5')}</li>
                                                                          </ul>
                                                                          <BatchInfo className='mt-5' t={t}/>
                                                                        </LazmeroResultPanel>
																		}
																		{ values.serviceType === SERVICETYPE_RONTGEN &&
                                                                        <>
                                                                          <RontgenResultPanel result={result} t={t} progress={progress}>
                                                                            <h6 className='my-4 font-weight-bold'>{t('Provisioning:rontgen-form.title2')}</h6>
                                                                            <div>{t('Provisioning:rontgen-form.desc')}</div>
                                                                            <BatchInfo className='mt-5' t={t}/>
                                                                          </RontgenResultPanel>
                                                                        </>
																		}
																		{ values.serviceType === SERVICETYPE_TISZTITAS &&
                                                                        <>
                                                                          <AddressResultPanel
                                                                            result={result}
                                                                            additionalServices={values.serviceOptions}
                                                                            t={t}
                                                                            progress={progress}
                                                                          >
                                                                            <h6 className='my-4 font-weight-bold'>{t('Provisioning:kezelo-form.desc1')}</h6>
                                                                            <p>{t('Provisioning:kezelo-form.desc2')}</p>
                                                                            <BatchInfo className='mt-5' t={t}/>
                                                                          </AddressResultPanel>

                                                                        </>
																		}
																	</>
																}
															/>
															<Scroll.Element name='batchRequestTable'/>
															{showTable &&
                                                            <ListBatchRequest t={t} userContext={userContext} serviceType={serviceType}/>
															}
														</div>
													</div>
												</div>
												<div className='order-1'>
													<section className='kezelo-buttons'>
														<div className='page-center-content'>
															{/*TODO ezt a 3 gombot jó lenne kiemelni 1 componentbe, sajnos azonban componentben nem működik alaposabban körbe kell járni*/}
															<div className='row'>
																<div className='col-12 col-sm-4 text-center'>
																	<button type='button' className={`kezelo-button text-center ${serviceType === SERVICETYPE_LAZMERES ? 'selected' : ''}`}
																		onClick={(e) => {
																			setFieldValue('serviceType', SERVICETYPE_LAZMERES, true)
																			//@ts-ignore
																			setServiceType(SERVICETYPE_LAZMERES)
																			history.push(`/kezelo/${SERVICETYPE_LAZMERES}`)
																			setResult(null)
																			let newServiceOptions = getSelectedServiceOptions(SERVICETYPE_LAZMERES)
																			setFieldValue('serviceOptions', newServiceOptions)
																			setShowTable(false)
																																			}}
																	>
																		<img src={serviceType === SERVICETYPE_LAZMERES ? imgThermoH : imgThermo } />
																		<div className='mt-2 title' >{t(`Registration:landingPage.kezelo-buttons.${SERVICETYPE_LAZMERES}`) }</div>
																		<div className='triangle-bl'></div>
																	</button>
																</div>
																<div className='col-12 col-sm-4 text-center'>
																	<button type='button' className={`kezelo-button text-center ${serviceType === SERVICETYPE_RONTGEN ? 'selected' : ''}`}
																		onClick={(e) => {
																			setFieldValue('serviceType', SERVICETYPE_RONTGEN, true)
																			//@ts-ignore
																			setServiceType(SERVICETYPE_RONTGEN)
																			history.push(`/kezelo/${SERVICETYPE_RONTGEN}`)
																			setResult(null)
																			let newServiceOptions = getSelectedServiceOptions(SERVICETYPE_RONTGEN)
																			setFieldValue('serviceOptions', newServiceOptions)
																			setShowTable(false)

																		}}
																	>
																		<img src={serviceType === SERVICETYPE_RONTGEN ? imgXRayH : imgXRay } />
																		<div className='mt-2 title' >{t(`Registration:landingPage.kezelo-buttons.${SERVICETYPE_RONTGEN}`) }</div>
																		<div className='triangle-bl'></div>
																	</button>
																</div>
																<div className='col-12 col-sm-4 text-center'>
																	<button type='button' className={`kezelo-button text-center ${serviceType === SERVICETYPE_TISZTITAS ? 'selected' : ''}`}
																		onClick={(e) => {
																			let newServiceOptions = getSelectedServiceOptions(SERVICETYPE_TISZTITAS)
																			setFieldValue('serviceOptions', newServiceOptions)
																			setFieldValue('serviceType', SERVICETYPE_TISZTITAS, true)
																			//@ts-ignore
																			setServiceType(SERVICETYPE_TISZTITAS)
																			history.push(`/kezelo/${SERVICETYPE_TISZTITAS}`)
																			setResult(null)
																			setShowTable(false)

																		}}
																	>
																		<img src={serviceType === SERVICETYPE_TISZTITAS ? imgListH : imgList } />
																		<div className='mt-2 title' >{t(`Registration:landingPage.kezelo-buttons.${SERVICETYPE_TISZTITAS}`) }</div>
																		<div className='triangle-bl'></div>
																	</button>
																</div>
															</div>
														</div>
													</section>
												</div>
											</div>
											<section className='attribute-content mt-5'>
												<div className='row justify-content-center py-2 px-2 px-md-5 page-center-content'>
													{values.serviceType === SERVICETYPE_LAZMERES &&
													<>
													  <AttributeBox icon={imgMedal48} text={t('Provisioning:mainPage.thermometer.attribute1.text')} />
													  <AttributeBox icon={imgVerify48} text={t('Provisioning:mainPage.thermometer.attribute2.text')} />
													  <AttributeBox icon={imgData48} text={t('Provisioning:mainPage.thermometer.attribute3.text')} />
													  <AttributeBox icon={imgPercent48} text={t('Provisioning:mainPage.thermometer.attribute4.text')} />
													  <AttributeBox icon={imgShapeStar48} text={t('Provisioning:mainPage.thermometer.attribute5.text')} />
													  <AttributeBox icon={imgUserHeadphone48} text={t('Provisioning:mainPage.thermometer.attribute6.text')} />
													</>
													}
													{values.serviceType === SERVICETYPE_RONTGEN &&
													<>
													  <AttributeBox icon={imgCube48} text={t('Provisioning:mainPage.xRay.attribute1.text')} />
													  <AttributeBox icon={imgData48} text={t('Provisioning:mainPage.xRay.attribute2.text')} />
													  <AttributeBox icon={imgGraph248} text={t('Provisioning:mainPage.xRay.attribute3.text')} />
													  <AttributeBox icon={imgFaves48} text={t('Provisioning:mainPage.xRay.attribute4.text')} />
													  <AttributeBox icon={imgScale48} text={t('Provisioning:mainPage.xRay.attribute5.text')} />
													  <AttributeBox icon={imgUserHeadphone48} text={t('Provisioning:mainPage.xRay.attribute6.text')} />
													</>
													}
													{values.serviceType === SERVICETYPE_TISZTITAS &&
													<>
													  <AttributeBox icon={imgExcel48} text={t('Provisioning:mainPage.list.attribute1.text')} />
													  <AttributeBox icon={imgClean48} text={t('Provisioning:mainPage.list.attribute2.text')} />
													  <AttributeBox icon={imgLayout48} text={t('Provisioning:mainPage.list.attribute3.text')} />
													  <AttributeBox icon={imgDoubleCheck48} text={t('Provisioning:mainPage.list.attribute4.text')} />
													  <AttributeBox icon={imgPin48} text={t('Provisioning:mainPage.list.attribute5.text')} />
													  <AttributeBox icon={imgUserHeadphone48} text={t('Provisioning:mainPage.list.attribute6.text')} />
													</>
													}
												</div>
											</section>
										</form>
									)}
								</Formik>
							)}
						</ApolloConsumer>
					)}
				</Mutation>
			)}
		</UserContextConsumer>
	)
})

export default BatchInputPanel
