import React from 'react'
import { Route } from 'react-router-dom'
import MainPageLayout from '../../layout/MainPageLayout'
import BatchPageLayout from '../../layout/BatchPageLayout'
const BatchPageRoute = ({ path, exact, component: Component, ...rest } : {path: string, exact?: boolean, component?: any, rest? : any}) => {
  return (
    <Route path={path} {...rest} render={matchProps =>
      <BatchPageLayout>
        <Component {...matchProps} />
      </BatchPageLayout>}
    />
  )
}

export default BatchPageRoute
