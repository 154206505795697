import { Dropdown } from 'primereact/dropdown'
import { Field } from 'formik'
import React from 'react'
import { listEnumValues } from '../../utils/EnumUtils'

const ComboEnumEditor = ({
							 fieldName,
							 fieldLabel,
							 placeHolder,
							 value,
							 enumClass,
							 except,
							 error,
							 onChange,
							 emptyValue,
							 disabled = false
} : {
							 	fieldName: any,
								fieldLabel: any,
								placeHolder?: any,
								value: any,
								enumClass: any,
								except?: any[]
								error: any,
								onChange: any,
								emptyValue?: boolean,
								disabled?: boolean
							}) => {
  const onComboChange = (e : any) => {
    onChange(e.value)
  }
  const possibleEnumValues = listEnumValues(enumClass)
	type enumPossibleValue = {
		label: any;
		value: any;
	}

	const possibleValues = emptyValue ? [{ label:'Válasszon!', value:null }] : Array<enumPossibleValue>()
	Object.entries(possibleEnumValues).map((value, idx) => {
	  if (!except || !except.includes(value[0])) {
	    possibleValues.push({ label:value[1], value:value[0] })
	  }
	  return null
	})
	return (<div className=''>
  <div className='form-group w-100'>
    {fieldLabel ? <label htmlFor={'input' + fieldName}>{fieldLabel}</label> : ''}
    <Dropdown
	      // @ts-ignore
      className={`form-control ${error ? 'is-invalid' : ''}`}
      id={'input' + fieldName}
      placeholder={placeHolder}
      value={value}
      onChange={onComboChange}
      options={possibleValues}
      disabled={disabled}
	    />
    <div className='error-message'>
      <Field
        name={fieldName}
        className='error-message'
        render={({ form }: { form: any }) => {
	          return error || null
	        }}
	      />
    </div>
  </div>
	</div>)
}

export default ComboEnumEditor
