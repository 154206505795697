import { Field } from 'formik'
import React from 'react'
import { listEnumValues } from '../../utils/EnumUtils'
import { SelectButton } from 'primereact/selectbutton'
import ReactTooltip from 'react-tooltip'

const SelectButtonEnumEditor = ({
  fieldName,
  value,
  enumClass,
  tooltips,
  except,
  error,
  onChange,
  emptyValue,
  multiple=false
} : {
    fieldName: any,
    value: any,
    enumClass: any,
    tooltips?: string[],
    except?: any[]
    error: any,
    onChange: any,
    emptyValue?: boolean,
    multiple?:boolean
}) => {
  const onButtonChange = (e : any) => {
    onChange(e.value)
  }
  const possibleEnumValues = listEnumValues(enumClass)
    type enumPossibleValue = {
        label: any;
        value: any;
    }

    const possibleValues = emptyValue ? [{ label:'Válasszon!', value:null }] : Array<enumPossibleValue>()
    Object.entries(possibleEnumValues).map((value, idx) => {
      if (!except || !except.includes(value[0])) {
        possibleValues.push({ label:value[1], value:value[0] })
      }
      return null
    })
    return (<div className=''>
      <div className='form-group select-button-editor' >
        <ReactTooltip />
        <div className='tooltip-component' data-tip={tooltips} data-html data-class='tooltip-panel'>
          <SelectButton
            // @ts-ignore
            className={`form-control d-inline-block ${error ? 'is-invalid' : ''}`}
            id={'input' + fieldName}
            value={value}
            onChange={onButtonChange}

            options={possibleValues}
            multiple={multiple}
          />
        </div>
        <div className='error-message'>
          <Field
            name={fieldName}
            className='error-message'
            render={({ form }: { form: any }) => {
              return error || null
            }}
          />
        </div>
      </div>
    </div>)
}

export default SelectButtonEnumEditor
