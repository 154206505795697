import React from 'react'
import { Route } from 'react-router-dom'
import RegistrationPageLayout from '../../layout/RegistrationPageLayout'
const RegistrationPageRoute = ({ path, exact, component: Component, render, ...rest } : {path: string, exact?: boolean, component?: any, render?: any, rest? : any}) => {
  return (
    <Route path={path} {...rest} render={render != null ? render : matchProps =>
      <RegistrationPageLayout>
        <Component {...matchProps} />
      </RegistrationPageLayout>}
    />
  )
}

export default RegistrationPageRoute
