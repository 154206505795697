import { Dropdown } from 'primereact/dropdown'
import { Field } from 'formik'
import React from 'react'

const ComboEditor = ({ fieldName,
                       fieldLabel,
                       placeHolder,
                       editorClassName,
                       value,
                       dataKey,
                       possibleValues,
                       error,
                       onChange,
                       editable = true,
                       disabled = false
                      }: {
						   fieldName: any,
                           fieldLabel: any,
                           placeHolder?: any,
                           editorClassName?:string,
                           value: any,
                           dataKey?:any,
                           possibleValues: any[],
                           error: any,
                           onChange: any,
                           editable?: any,
                           disabled?: any
						 }) => {
  const onComboChange = (e : any) => {
    onChange(e.value)
  }
  return (<div className=''>
    <div className={`form-group w-100 ${editorClassName}`}>
      {fieldLabel ? <label htmlFor={'input' + fieldName}>{fieldLabel}</label> : ''}
      <Dropdown
        // @ts-ignore
        className={`form-control ${error ? 'is-invalid' : ''}`}
        id={'input' + fieldName}
        placeholder={placeHolder}
        value={value}
        // dataKey='key' ha meg van adva, akkor a comboban a kiválasztott elem sohe nem változik, pedig a value értéke módosul (pl switchToAccount)
        onChange={onComboChange}
        options={possibleValues}
        editable={editable}
        disabled={disabled}
      />
      <div className='error-message'>
        <Field
          name={fieldName}
          className='error-message'
          render={({ form }: { form: any }) => {
            return error || null
          }}
        />
      </div>
    </div>
  </div>)
}

export default ComboEditor
