import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ApolloConsumer } from 'react-apollo'
import { Dialog } from 'primereact/dialog'
import { Formik } from 'formik'
import { ApolloClient } from 'apollo-client'
import gql from 'graphql-tag'
import ComboEnumEditor from '../../../components/editors/ComboEnumEditor'
import TextEditor from '../../../components/editors/TextEditor'
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import EnumUtils from '../../../utils/EnumUtils'
import { Account } from './ProfileAccountData'
import { refreshAccountsAndUsers } from '../Profile'

// Cím típusa
enum AddressType {
	POSTAL     ='POSTAL',      //Postázási cím.
	HEADQUARTER='HEADQUARTER', //Székhely cím
	BILLING    ='BILLING'      //Számlázási cím
}

// Cím
export interface Address {
	id: number          // azonosító
	type: AddressType   // cím típusa
	countryCode: String // ország betűjele
	zip: String         // irsz
	city: String        // város
	other: String       // cím többi része
}
interface AddressInput extends Address {}

/*******************************************************************************
 * Cím módosítása
 *
 * @param client  Apollo kliens.
 * @param address Mentendő cím.
 */
export const modifyAddress = async (client: ApolloClient<any>, address: AddressInput, refreshFunc: any,
									setAccounts, selectedAccount, setSelectedAccount,setPossibleUsers, setSelectedUser, user) => {
// console.log(address)
  try {
    const res = await client.mutate({
      mutation: gql`
          mutation ModifyAddress($address: AddressInput!) {
              modifyAddress(address: $address) {
                  id, type, countryCode, zip, city, other
              }
          }
	  `,
		variables: { 'address': address }
    })
    // console.log(res)
	  refreshAccountsAndUsers(client, setAccounts, selectedAccount,
		  setSelectedAccount, user, setPossibleUsers, setSelectedUser)
	  refreshFunc( res.data.modifyAddress )
  } catch (error) {
	  console.log(JSON.stringify(error))
  }
}

/*******************************************************************************
 * Cím hozzáadása előfizetéshez
 *
 * @param client    Apollo kliens.
 * @param accountId Előfizetés ID, amihez hozzá akarjuk adni.
 * @param address   Hozzáadandó Cím.
 */
export const addAddressToAccount = async (client: ApolloClient<any>, accountId: number, address: AddressInput, refreshFunc: any,
										  setAccounts, selectedAccount, setSelectedAccount,setPossibleUsers, setSelectedUser, user) => {
  try {
    const res = await client.mutate({
      mutation: gql`
          mutation AddAddressToAccount($accountId: ID!, $address: AddressInput!) {
              addAddressToAccount(accountId: $accountId, address: $address) {
                  id, type, countryCode, zip, city, other
              }
          }
	  `,
		variables: { accountId, address }
    })
    // console.log(res)
	  refreshAccountsAndUsers(client, setAccounts, selectedAccount,
		  setSelectedAccount, user, setPossibleUsers, setSelectedUser)
	  refreshFunc( res.data.addAddressToAccount )
  } catch (error) {
	  console.log(JSON.stringify(error))
  }
}

/*******************************************************************************
 * Cím felvitele és módosítása dialógus
 *
 * @param accountId    Előfizetés ID-ja.
 * @param data         A módosítandó telefonszám.
 * @param openDialog   Ha TRUE, akkor megjelenik a dialógus.
 * @param onHideFunc   Dialógus becsukásakor meghívandó rutin.
 */
const AddressEditDialog = (props: {accountId: number, data: Address, openDialog: boolean, onHideFunc: any, refreshFunc: any,
	setAccounts, selectedAccount, setSelectedAccount,setPossibleUsers, setSelectedUser, user} ) => {
	const { t } = useTranslation('Registration')
	var uj=(props.data.id==null)
// console.log(props.data);
	return (
		<ApolloConsumer>
			{client => (
				<Dialog
					className='scr-dialog'
					header={uj ? t('torzsadatok.addressdialog.title.add') : t('torzsadatok.addressdialog.title.edit')} modal={true}
					visible={props.openDialog}
					onHide={()=>{props.openDialog ? props.onHideFunc() : ''}}
				>
					<Formik
						enableReinitialize={true}
						initialValues={{
							//azért kell a kavarás, mert az input elemek nagy része hibát dob  NULL-ra :(
							id     : props.data.id,
							type   : (props.data.type==null ? AddressType.POSTAL : props.data.type),
							countryCode: (props.data.countryCode==null? 'HU' : props.data.countryCode),
							zip    : (props.data.zip==null ? '' : props.data.zip),
							city   : (props.data.city==null ? '' : props.data.city),
							other  : (props.data.other==null ? '' : props.data.other)
						}}
						validate={values => {
							let errors: any = {}

							// típus kötelező
							if( !values.type ) {
								errors.type=t('torzsadatok.addressdialog.type.error.required')
							}

							// település kötelező
							if( !values.city ) {
								errors.city=t('torzsadatok.addressdialog.city.error.required')
							}

							return errors
						}}
						onSubmit={(values, { setSubmitting }) => {
							if( uj ){
								//új hozzáadása
								addAddressToAccount( client, props.accountId, values as AddressInput, props.refreshFunc,
									props.setAccounts, props.selectedAccount, props.setSelectedAccount,props.setPossibleUsers, props.setSelectedUser, props.user)
							}else{
								//módosítás mentése
								modifyAddress( client, values as AddressInput, props.refreshFunc,
									props.setAccounts, props.selectedAccount, props.setSelectedAccount,props.setPossibleUsers, props.setSelectedUser, props.user)
							}
							props.onHideFunc()
						}}
					>
						{({
							  values,
							  errors,
							  touched,
							  handleChange,
							  handleBlur,
							  handleSubmit,
							  isSubmitting,
							  setFieldValue
							  /* and other goodies */
						  }) => (
							<form className='form-torzsadat-addressedit' onSubmit={handleSubmit} >
								{/* Típus */}
								<ComboEnumEditor
									fieldName='type'
									fieldLabel={t('torzsadatok.addressdialog.type.label')}
									value={values.type}
									enumClass='AddressType'
									error={errors.type}
									onChange={(value: any) => setFieldValue('type', value, true)}
								/>

								{/* Ország */}
								<TextEditor
									fieldLabel={t('torzsadatok.addressdialog.countrycode.label')} fieldName='countryCode' value={values.countryCode}
									error={errors.countryCode} handleChange={handleChange} handleBlur={handleBlur}
									disabled={true}
								/>

								{/* Irányítószám (külföldi címnél bármi lehet, ezért síma szöveg...) */}
								<TextEditor
									fieldLabel={t('torzsadatok.addressdialog.zip.label')} fieldName='zip' value={values.zip}
									error={errors.zip} handleChange={handleChange} handleBlur={handleBlur}
								/>

								{/* Település */}
								<TextEditor
									fieldLabel={t('torzsadatok.addressdialog.city.label')} fieldName='city' value={values.city}
									error={errors.city} handleChange={handleChange} handleBlur={handleBlur}
								/>

								{/* Cím többi része */}
								<TextEditor
									fieldLabel={t('torzsadatok.addressdialog.other.label')} fieldName='other' value={values.other}
									error={errors.other} handleChange={handleChange} handleBlur={handleBlur}
								/>
								<div className='text-right'>
									<Button label={t('torzsadatok.addressdialog.button.save')} type='submit' className='btn ml-2'/>
									<Button label={t('torzsadatok.addressdialog.button.cancel')} type='button' onClick={()=>{props.onHideFunc()}} className='btn ml-2' />
								</div>
							</form>
						)}
					</Formik>
				</Dialog>
			)}
		</ApolloConsumer>
	)
}

/*******************************************************************************
 * Egy előfizetéshez tartozó címek táblázata
 *
 * @param account    Előfizetés adatai.
 * @param modifiable Módosítható-e.
 */
const AccountAddresses = (props: {account: Account, setAccounts, selectedAccount, setSelectedAccount,setPossibleUsers, setSelectedUser, user, modifiable: boolean}) => {
	const { t } = useTranslation('Registration')
	const [openAddrDialog, setOpenAddrDialog] = useState(false)
	const [selectedAddr, setSelectedAddr] = useState( {} as Address )
	const [addresses, setAddresses] = useState()
	useEffect(() => {
		setAddresses(props.account.addresses)
	})

	const refreshAddresses = (data: Address) => {
		let megvan=false
		let newAddresses = [...addresses]
		for( let i in newAddresses ) {
			if( newAddresses[i].id == data.id ) {
				megvan = true
				newAddresses[i] = data
				break
			}
		}
		if( !megvan ) { //ha új cím
			newAddresses.push( data )
		}
		setAddresses(newAddresses)
	}

	return (
		<div>
			{/* dialógusok */}
			<AddressEditDialog accountId={props.account.id} data={selectedAddr}  openDialog={openAddrDialog}
				onHideFunc={()=>{setOpenAddrDialog(false) }} refreshFunc={refreshAddresses}
				setAccounts={props.setAccounts}
				selectedAccount={props.selectedAccount}
				setSelectedAccount={props.setSelectedAccount}
				setPossibleUsers={props.setPossibleUsers}
				setSelectedUser={props.setSelectedUser}
				user={props.user}
				/>

			{/* Címek */}
			<DataTable responsive={true} className='scr-table' value={addresses} emptyMessage={t('message.noData')}>
				<Column field='type'        header={t('torzsadatok.accountdata.addresses.tableheader.type')}
					body={ (rowData: Address, column: number) => {
						return <EnumUtils enumClassName='AddressType' enumName={rowData.type}/>
					}}
				/>
				<Column field='countryCode' header={t('torzsadatok.accountdata.addresses.tableheader.countryCode')} />
				<Column field='zip'         header={t('torzsadatok.accountdata.addresses.tableheader.zip')} />
				<Column field='city'        header={t('torzsadatok.accountdata.addresses.tableheader.city')} />
				<Column field='other'        header={t('torzsadatok.accountdata.addresses.tableheader.other')} />
				<Column className='icon-column' body={ (rowData: Address, column: number) => {
					return <Button icon='pi pi-pencil'
						onClick={(e) => {setSelectedAddr(rowData); setOpenAddrDialog(true)} }
						disabled={!props.modifiable}
					/>
				}} />
			</DataTable>
			{/* Új cím hozzáadása */}
			<Button icon='pi pi-plus' label={t('torzsadatok.accountdata.addresses.button.add')}  type='button' className='btn mt-2'
				disabled={!props.modifiable}
				onClick={(e) => {setSelectedAddr( {} as Address); setOpenAddrDialog(true)} }
			/>
		</div>
	)
}

export default AccountAddresses
