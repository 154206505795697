import React, { useRef, useState } from 'react'
import { Form, Formik } from 'formik'
import AutoCompleteEditor from '../../components/editors/AutoCompleteEditor'
import { Growl } from 'primereact/growl'
import { containsPostalCode, InputAddress, makettComplete, standardizeAddress } from '../../makett/makett-service'
import ReactTooltip from 'react-tooltip'
import { useTranslation } from 'react-i18next'
import OperatorPanelWrapper from './inputPanel/OperatorPanelWrapper'
import AddressResultPanel from './resultPanel/AddressResultPanel'
import { REMAINED_AMOUNT, RemainedAmountQuery } from './IndividualValidation'
import ApolloClient from 'apollo-client'
import { ApolloConsumer } from 'react-apollo'
import SelectButtonEnumEditor from '../../components/editors/SelectButtonEnumEditor'
import { Button } from 'primereact/button'
import FormikWithConfirmDialog from '../../components/FormikWithConfirmDialog'

const postalCodeAndCitySuggestionsInitialValues:string[] = []
const streetNameAndTypeSuggestionsInitialValues:string[] = []
const houseNumberAndMoreSuggestionsInitialValues:string[] = []

const SmartFillForm = ({ apiKey } : {apiKey: string}) => {
	const growlEl = useRef(null)
	const tipZipCityEl = useRef(null)
	const tipStreetEl = useRef(null)
	const [postalCodeAndCitySuggestions, setPostalCodeAndCitySuggestions] = useState(postalCodeAndCitySuggestionsInitialValues)
	const [streetNameAndTypeSuggestions, setStreetNameAndTypeSuggestions] = useState(streetNameAndTypeSuggestionsInitialValues)
	const [houseNumberAndMoreSuggestions, setHouseNumberAndMoreSuggestions] = useState(houseNumberAndMoreSuggestionsInitialValues)

	const [singlePostalCode, setSinglePostalCode] = useState('')
	const [postalCode, setPostalCode] = useState('')
	const [city, setCity] = useState('')
	const [district, setDistrict] = useState('')
	const [street, setStreet] = useState('')
	let [result, setResult] = useState<any>(undefined)
	let [progress, setProgress] = useState(false)
	let [quota, setQuota] = useState(undefined)
	let [days, setDays] = useState(undefined)
	let [hour, setHour] = useState(undefined)
	let [minute, setMinute] = useState(undefined)

	let { t } = useTranslation(['Provisioning'])
	return <ApolloConsumer>
		{(client : ApolloClient<any>) => (
			<div>
				<Growl ref={growlEl} />
				<FormikWithConfirmDialog
					confirmOptions = {{
						needConfirm: () => {
							const val = localStorage.getItem('smartTypeUseCleanQuota')
							return val && val != null ? JSON.parse(val) : true
						},
						setNeedConfirm: (val) => localStorage.setItem('smartTypeUseCleanQuota', val),
						dialogTitle: t('prevencio-form.warn-dialog-title'),
						dialogBody: <p>{t('prevencio-form.warn-dialog-message')}</p>,
						yesButtonLabel: t('prevencio-form.warn-dialog-yes'),
						noButtonLabel: t('prevencio-form.warn-dialog-no'),
					}}
					initialValues={{
						postalCodeAndCity: '',
						streetNameAndType: '',
						houseNumberAndMore: '',
						additionalServices: ['Hazszam','Bontva']
					}}
					validate={()=>{}}
					onSubmit={async (values, { setSubmitting }) => {
						try {
							//@ts-ignore
							if (quota === 0 || days && days < 0) {
								//@ts-ignore
								if (quota === 0 || days && days < 0) {
									//@ts-ignore
									growlEl && growlEl.current.show({ severity: 'error', summary: t('Provisioning:message.interfaceClosed') })
								} else if (quota === 0) {
									//@ts-ignore
									growlEl && growlEl.current.show({ severity: 'error', summary: t('Provisioning:message.quotaRunOut') })
								}
							}
							else {
								setProgress(true)
								let address:InputAddress={}
								address.fullAddress = singlePostalCode + ' ' + city + ' ' +
									(district ? district + 'ker. ' : '') + ' ' +
									street + ' ' +
									values.houseNumberAndMore
								const resultAddress = await standardizeAddress(address, apiKey, 'Hazszam|Bontva')
								if (result && 'code' in result){
									//@ts-ignore
									growlEl && growlEl.current.show({ severity: 'error', summary: resultAddress.message })
									setProgress(false)
								} else {
									setProgress(false)
									setResult(resultAddress)
									const { data } = await client.query({ query: REMAINED_AMOUNT,
										variables: {
											apiKey: apiKey,
											serviceType: 'Tisztitas',
											interfaceType: 'Kezelo'
										},fetchPolicy:'network-only'
									})
									data.getRemainedAmount && setQuota(data.getRemainedAmount.quota)
									data.getRemainedAmount && setDays(data.getRemainedAmount.days)
								}
							}
						} catch (e) {
							console.log(JSON.stringify(e))
							setResult({ message: t('Provisioning:kezelo-form.makett-error') })
							setProgress(false)
						}
					}}
					render={({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => (
						<Form>
							<OperatorPanelWrapper
								remainedAmountQuery={
									<RemainedAmountQuery
										apiKey={apiKey}
										quota={quota}
										setQuota={setQuota}
										days={days}
										setDays={setDays}
										hour={hour}
										setHour={setHour}
										minute={minute}
										setMinute={setMinute}
										t={t}
										serviceType='Okosbeiras'
									/>
								}
								leftColumnContent={
									<>
										<div className='row p-3'>
											<div className='col-12'>
												<AutoCompleteEditor
													fieldName='postalCodeAndCity'
													fieldLabel={t('prevencio-form.postalCodeAndCity')}
													value={values.postalCodeAndCity}
													size={40}
													error={errors.postalCodeAndCity}
													handleChange={handleChange}
													onFocus={() => {
														// @ts-ignore
														ReactTooltip.show(tipZipCityEl.current)
													}}
													handleBlur={() => {
														const regex = /([0-9]{0,4})\s*([^\s]+)\s*(.*)?/g
														const matched = regex.exec(values.postalCodeAndCity)
														if (matched != null) {
															setPostalCode(matched[1])
															setSinglePostalCode(matched[1])
															setCity(matched[2])
															setDistrict(matched[3])
														} else {
															setPostalCode('')
															setSinglePostalCode('')
															setCity('')
															setDistrict('')
														}
													}}
													suggestions={postalCodeAndCitySuggestions}
													completeMethod={()=> {
														const isPostalCode = values.postalCodeAndCity.match(/[0-9]{1,4}/g) !== null
														const queryAddress = isPostalCode ?
															{ postalCode: values.postalCodeAndCity } :
															{ city: values.postalCodeAndCity }
														setFieldValue('streetNameAndType', '', false)
														setFieldValue('houseNumberAndMore', '', false)
														makettComplete(
															apiKey,
															queryAddress,
															setPostalCodeAndCitySuggestions,
															(a:any) => (a.postalCode + ' ' + a.city + ' ' + a.district).trim(),
															growlEl.current
														)
													}} />
											</div>
											<div className='col-12'>
												<AutoCompleteEditor
													fieldName='streetNameAndType'
													fieldLabel={t('prevencio-form.streetNameAndType')}
													value={values.streetNameAndType}
													size={40}
													error={errors.streetNameAndType}
													handleChange={handleChange}
													onFocus={() => {
														// @ts-ignore
														ReactTooltip.show(tipStreetEl.current)
													}}
													handleBlur={() => {
														setFieldValue('houseNumberAndMore', '', false)

														const regex = /^([^(]+) \(([0-9]{0,4}(?:,[0-9]{0,4})*)\)$/g
														const matched = regex.exec(values.streetNameAndType)
														if (matched != null) {
															setStreet(matched[1])
															setPostalCode(matched[2])
															setSinglePostalCode(matched[2].substring(0, 4))
														} else {
															setStreet(values.streetNameAndType)
														}
													}}
													suggestions={streetNameAndTypeSuggestions}
													completeMethod={()=> {
														const regex = /([0-9]{0,4})\s*([^\s]+)\s*(.*)?/g
														const matched = regex.exec(values.postalCodeAndCity)
														if (matched != null) {
															setPostalCode(matched[1])
															setSinglePostalCode(matched[1])
															setCity(matched[2])
															setDistrict(matched[3])
														} else {
															setPostalCode('')
															setSinglePostalCode('')
															setCity('')
															setDistrict('')
														}
														makettComplete(
															apiKey,
															{
																postalCode,
																city: city + (district && district.length > 0 ? ' ' + district : ''),
																streetName: values.streetNameAndType
															},
															setStreetNameAndTypeSuggestions,
															(a:any) => a.streetName + ' ' + a.streetType +
																(!containsPostalCode(a.postalCode, postalCode) ?' (' + a.postalCode + ')' : ''),
															growlEl.current
														)
													}} />
											</div>
											<div className='col-12'>
												<AutoCompleteEditor
													fieldName='houseNumberAndMore'
													fieldLabel={t('prevencio-form.houseNumberAndMore')}
													value={values.houseNumberAndMore}
													size={40}
													error={errors.houseNumberAndMore}
													handleChange={handleChange}
													handleBlur={() => {
													}}
													suggestions={houseNumberAndMoreSuggestions}
													completeMethod={()=> {
														if (values.houseNumberAndMore.match(/^[0-9]+$/)) {
															makettComplete(
																apiKey,
																{
																	postalCode,
																	city: city + (district && district.length > 0 ? ' ' + district : ''),
																	streetName: street,
																	houseNumber: values.houseNumberAndMore
																},
																setHouseNumberAndMoreSuggestions,
																(a:any) => a.houseNumber,
																growlEl.current, true
															)
														} else {
															setHouseNumberAndMoreSuggestions([])
														}
													}} />
											</div>
											<div className='col-12'>
												{t('Provisioning:batchInputPanel.select-options')}
												<SelectButtonEnumEditor
													fieldName='additionalService'
													value={values.additionalServices}
													enumClass='SmartFillServiceOptions'
													error={errors.additionalServices}
													tooltips={t('Provisioning:prevencio-form.service-options-tooltip')}
													onChange={(value: any) => {
														setFieldValue('additionalServices', value, true)
														setResult(null)
													}}
													multiple={true}
												/>
											</div>
											<div className='col-12'>
												<div className='text-right'>
													<Button
														className='btn btn-outline-success my-2 my-sm-0 ml-2'
														label={t('Provisioning:kezelo.button.run-makett')}
														type='submit'>
													</Button>
												</div>
											</div>
										</div>
										{ /**
										<div>
											<div>Debug</div>
											<div>postalCode: {postalCode}</div>
											<div>city: {city}</div>
											<div>district: {district}</div>
										</div>
										*/ }
									</>
								}
								rightColumnContent={
								  <AddressResultPanel
									result={result}
									additionalServices={values.additionalServices}
									t={t}
									progress={progress}
								  >
									<h6 className='font-weight-bold my-4'>{t('Provisioning:prevencio-form.subtitle')}</h6>
									<p>
										{t('Provisioning:prevencio-form.desc')}
									</p>
									<h6 className='font-weight-bold mt-4 mb-2'>{t('prevencio-form.postalCodeAndCity')}:</h6>
									<p>{t('prevencio-form.tipZipCity')}</p>
									<h6 className='font-weight-bold mt-4 mb-2'>{t('prevencio-form.streetNameAndType')}:</h6>
									<p>{t('prevencio-form.tipStreet')}</p>
								    <h6 className='font-weight-bold mt-4 mb-2'>{t('prevencio-form.houseNumberAndMore')}:</h6>
								    <p>{t('prevencio-form.tipHouseNumber')}</p>
								    <h6 className='font-weight-bold mt-4 mb-2'>{t('prevencio-form.addressCleaner')}:</h6>
								    <p>{t('prevencio-form.tipAddressCleaner')}</p>
								  </AddressResultPanel>
								}
							/>
						</Form>
					)}/>
			</div>
		)}
	</ApolloConsumer>
}

export default SmartFillForm
