import React, { useState } from 'react'
import { Button } from 'primereact/button'
import ApolloClient from 'apollo-client'
import gql from 'graphql-tag'
import ApolloButton from '../../components/ApolloButton'
import { Dialog } from 'primereact/dialog'
import validImg from '../../images/valid-48.png'
import invalidImg from '../../images/invalid-48.png'
import { Formik } from 'formik'
import TextEditor from '../../components/editors/TextEditor'
import { Query } from 'react-apollo'
import { demoStandardizeAddress } from '../../makett/makett-service'
import { useTranslation } from 'react-i18next'
import AddressResultPanel from '../operator/resultPanel/AddressResultPanel'
import { KezeloFormPanelWrapper } from './MainPageOperator'

const AVAILABLE_COUNT = gql`
    query DemoAvailable($serviceType: ServiceType)
    {
        demoAvailable(serviceType : $serviceType)
    }
`

const registerAndApplyTrial = async (client: ApolloClient<any>, bonus: boolean, reportRegistration: any) => {
  try {
    const res = await client.mutate({
      mutation: gql`mutation RegisterAndApplyTrial($bonus : Boolean!) {registerAndApplyTrial (bonus: $bonus)}`,
      variables: { bonus }
    })
    console.log(res)
    reportRegistration(res)
  } catch (error) {
  	console.log(JSON.stringify(error))
    if (error.graphQLErrors && error.graphQLErrors.length > 0) {
      reportRegistration({ errors: error.graphQLErrors })
    } else {
      reportRegistration({ errors: [{ message: 'Ismeretlen hiba.' }] })
    }
  }
}

const AvailableCountQuery = () => {
  let [result, setResult] = useState<any>(undefined)
  let [demoAvailable, setdemoAvailable] = useState<any>(undefined)
  return (<Query query={AVAILABLE_COUNT} variables={{ serviceType: 'Tisztitas' }}>
    {(result : any) => {
      if (result.data && (result.data.loading)) {
        return <div>Betöltés...</div>
      } else if (!result) {
        return <div>Hiba történt: {JSON.stringify(result)}</div>
      } else {
        return <FormikIngyenesProbaForm availableCountResult={result} />
      }
    }}
  </Query>
  )
}
const FormikIngyenesProbaForm = ({ availableCountResult }: {availableCountResult:any}) => {
  const { t } = useTranslation('Registration')
  let [result, setResult] = useState<any>(undefined)
  let [moreResultPanel, setMoreResultPanel] = useState(false)
  let [progress, setProgress] = useState(false)
  return (<Formik
    initialValues={{ address: '' }}
    validate={values => {
      let errors = {}
      if (!values.address) {
        // @ts-ignore
        errors.address = t('demoPage.error.needAddress')
      }
      return errors
    }}
    onSubmit={async (values, { setSubmitting }) => {
      setProgress(true)
      setResult({ message: t('demoPage.message.cleanInProgress') })
      try {
        const resultAddress = await demoStandardizeAddress({
          fullAddress: values.address
        })
        setProgress(false)
        setResult(resultAddress)
      } catch (e) {
        console.log(JSON.stringify(e))
        setResult({ message: t('demoPage.error.makettCall') })
        setProgress(false)
      }
      availableCountResult.refetch()
    }}
  >
    {({
				  values,
				  errors,
				  touched,
				  handleChange,
				  handleBlur,
				  handleSubmit,
				  isSubmitting,
				  setFieldValue
				  /* and other goodies */
			  }) => (<>
  <form className='form-demo form-inline'
    onSubmit={handleSubmit}
  >
    <div className='container-fluid'>

      <div className='row'>
        <div className='col pl-0 pr-1'>
          <TextEditor
            className='w-100'
            fieldName='address'
            value={values.address}
            error={errors.address}
            placeholder={t('demoPage.field.address')}
            handleChange={handleChange}
            handleBlur={handleBlur} />
        </div>
        <div className='col-auto p-0 result-button'>
          <Button
            disabled={!availableCountResult.data || availableCountResult.data.demoAvailable == 0}
            className='btn btn-outline-success my-2 my-sm-0 ml-2'
            label={t('demoPage.button.submit')}
            type='submit' />
        </div>
      </div>
    </div>
    <>{availableCountResult.data ? (
						  availableCountResult.data.demoAvailable == 0
        ? t('demoPage.noMore')
						    : t('demoPage.remaining', { count: availableCountResult.data.demoAvailable }))
						  : ''}
    </>
  </form>
  {result
    ? <div className='address-result mt-5' >
      <h4>{t('demoPage.result.title')}:</h4>
      <AddressResultPanel
        additionalServices={['Hazszam']}
        result={result}
        t={t}
        displayTypeValue='demo'
        progress={progress}
					    />
    </div>
    : ''}
				</>
    )}
  </Formik>
  )
}

const ProbaCsomagPageContent = () => {
  const { t } = useTranslation('Registration')
  let [showModal, setShowModal] = useState(false)
  let [result, setResult] = useState({ errors: [] })
  const reportRegistration = (res: any) => {
    setResult(res)
    setShowModal(true)
  }

  const footer = (
    <div>
      <Button label={t('demoPage.button.ok')} icon='pi pi-check' onClick={() => setShowModal(false)} />
    </div>
  )

  let hasError = (result.errors) && result.errors.length > 0
  // @ts-ignore
  let errorMessage = hasError ? result.errors[0].message : ''
  return <div className=''>
    <Dialog header={t('demoPage.trialRegistration.title')} visible={showModal} style={{ width: '50vw' }} footer={footer} onHide={() => setShowModal(false)} maximizable={false}>
      {
        hasError
          ? <div><img className='mr-3' src={invalidImg} alt='valid image' />{t('demoPage.trialRegistration.error', { errorMessage })}</div>
          : <div><img className='mr-3' src={validImg} alt='invalid image' />{t('demoPage.trialRegistration.success')}</div>
      }
    </Dialog>

    <div className=''>
      <AvailableCountQuery/>
    </div>
  </div>
}

const QuickCleanPage = ({ title }:{title:any}) => {
  const { t } = useTranslation('Common')
  return <KezeloFormPanelWrapper
      title={title}
      subTitle={t('Registration:demoPage.title2')}>
    <div className='row'>
      <div className='col col-md-10 offset-md-1'>
        <p className='text-left'>{t('Registration:demoPage.desc')}</p>
        <ProbaCsomagPageContent/>
      </div>
    </div>
  </KezeloFormPanelWrapper>
}

export default QuickCleanPage
