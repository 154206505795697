import { Checkbox } from 'primereact/checkbox'
import React from 'react'
import { Field } from 'formik'

const CheckboxEditor = ({ fieldName, fieldLabel, value, error, tooltip, onChange, children } : {fieldName: string, fieldLabel?: any, value: any, error: any, tooltip?: string, onChange?: any, children?:any}) => {
	const onCheckChange = (e : any) => {
		value = e.checked
		onChange(value)
	}
	return (
		<div className='form-group'>
			<div className='form-check d-flex pl-0'>
				<Checkbox
					key={'input' + fieldName}
					className={error ? 'is-invalid' : ''}
					inputId={'input' + fieldName}
					value={value}
					onChange={onCheckChange}
					tooltip={tooltip}
					checked={value}
				/>
				<label
					key={'label' + fieldName}
					htmlFor={'input' + fieldName}
					className='p-checkbox-label'>
					{fieldLabel}
					{children}
				</label>
			</div>
			<div className='error-message'>
				<Field
					name={fieldName}
					className='error-message'
					render={({ form } : {form : any}) => {
						return error || null
					}}
				/>
			</div>
		</div>
	)
}

export default CheckboxEditor
