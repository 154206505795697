import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ApolloConsumer } from 'react-apollo'
import { Dialog } from 'primereact/dialog'
import { Formik } from 'formik'
import TextEditor from '../../../components/editors/TextEditor'
import { InputSwitch } from 'primereact/inputswitch'
import { Button } from 'primereact/button'
import { Account } from './ProfileAccountData'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import gql from 'graphql-tag'
import { ApolloClient } from 'apollo-client'
import { refreshAccountsAndUsers } from '../Profile'

// Telefonszámok
export interface Phone {
	id: number     // azonosító
	number: String // telefonszám
	sms: Boolean   // tud-e sms-t fogadni
	other: String  // egyéb
}
interface PhoneInput extends Phone {}

/*******************************************************************************
 * Telefonszám hozzáadása előfizetéshez
 *
 * @param client    Apollo kliens.
 * @param accountId Előfizetés ID, amihez hozzá akarjuk adni.
 * @param address   Hozzáadandó telefonszám.
 */
export const addPhoneToAccount = async (client: ApolloClient<any>, accountId: number, phone: PhoneInput, refreshFunc: any,
										setAccounts, selectedAccount, setSelectedAccount,setPossibleUsers, setSelectedUser, user) => {
  try {
    const res = await client.mutate({
      mutation: gql`
          mutation AddPhoneToAccount($accountId: ID!, $phone: PhoneInput!) {
              addPhoneToAccount(accountId: $accountId, phone: $phone) {
                  id, number, sms, other
              }
          }
	  `,
		variables: { accountId, phone }
    })
    // console.log(res)
	  refreshAccountsAndUsers(client, setAccounts, selectedAccount,
		  setSelectedAccount, user, setPossibleUsers, setSelectedUser)
	  refreshFunc( res.data.addPhoneToAccount )
  } catch (error) {
	  console.log(JSON.stringify(error))
  }
}

/*******************************************************************************
 * Telefonszám módosítása
 *
 * @param client Apollo kliens.
 * @param phone  Mentendő telefonszám.
 */
export const modifyPhone = async (client: ApolloClient<any>, phone: PhoneInput, refreshFunc: any,
								  setAccounts, selectedAccount, setSelectedAccount,setPossibleUsers, setSelectedUser, user) => {
  // console.log(phone)
  try {
    const res = await client.mutate({
      mutation: gql`
          mutation ModifyPhone($phone: PhoneInput!) {
              modifyPhone(phone: $phone) {
                  id, number, sms, other
              }
          }
	  `,
		variables: { 'phone': phone }
    })
    // console.log(res)
	  refreshAccountsAndUsers(client, setAccounts, selectedAccount,
		  setSelectedAccount, user, setPossibleUsers, setSelectedUser)
	  refreshFunc( res.data.modifyPhone )
  } catch (error) {
	  console.log(JSON.stringify(error))
  }
}

/*******************************************************************************
 * Telefonszám felvitele és módosítása dialógus
 *
 * @param accountId    Előfizetés ID-ja.
 * @param data         A módosítandó telefonszám.
 * @param openDialog   Ha TRUE, akkor megjelenik a dialógus.
 * @param onHideFunc   Dialógus becsukásakor meghívandó rutin.
 */
const PhoneEditDialog = (props: {accountId: number, data: Phone, openDialog: boolean, onHideFunc: any, refreshFunc: any,
	setAccounts, selectedAccount, setSelectedAccount,setPossibleUsers, setSelectedUser, user} ) => {
	const { t } = useTranslation('Registration')
	var uj=(props.data.id==null)
// console.log(props.data);
	return (
		<ApolloConsumer>
			{client => (
				<Dialog
					className='scr-dialog'
					header={uj ? t('torzsadatok.phonedialog.title.add') : t('torzsadatok.phonedialog.title.edit')} modal={true}
					visible={props.openDialog}
					onHide={()=>{props.openDialog ? props.onHideFunc() : ''}}
				>
					<Formik
						enableReinitialize={true}
						initialValues={{
							id     : props.data.id,
							number : (props.data.number==null ? '' : props.data.number),
							sms    : (props.data.sms==null ? false : props.data.sms as boolean),
							other  : (props.data.other==null ? '' : props.data.other)
						}}
						validate={values => {
							let errors: any = {}

							//telefonszám kötelező
							if( !values.number) {
								errors.number=t('torzsadatok.phonedialog.number.error.required')
							}

							return errors
						}}
						onSubmit={(values, { setSubmitting }) => {
							if( uj ){
								//új hozzáadása
								addPhoneToAccount( client, props.accountId, values as PhoneInput, props.refreshFunc,
									props.setAccounts, props.selectedAccount, props.setSelectedAccount,props.setPossibleUsers, props.setSelectedUser, props.user)
							}else{
								//módosítás mentése
								modifyPhone( client, values as PhoneInput, props.refreshFunc,
									props.setAccounts, props.selectedAccount, props.setSelectedAccount,props.setPossibleUsers, props.setSelectedUser, props.user)
							}
							props.onHideFunc()
						}}
					>
						{({
							  values,
							  errors,
							  touched,
							  handleChange,
							  handleBlur,
							  handleSubmit,
							  isSubmitting,
							  setFieldValue
							  /* and other goodies */
						  }) => (
							<form className='form-torzsadat-phoneedit' onSubmit={handleSubmit} >
								{/* Telefonszám */}
								<TextEditor
									fieldLabel={t('torzsadatok.phonedialog.number.label')} fieldName='number' value={values.number}
									error={errors.number} handleChange={handleChange} handleBlur={handleBlur}
								/>

								{/* SMS */}
								<div>
									<InputSwitch checked={values.sms} onChange={(e: any) => setFieldValue('sms', e.value, true)} />
									<label className='p-checkbox-label'>{t('torzsadatok.phonedialog.sms.label')}</label>
								</div>

								{/* Egyéb */}
								<TextEditor
									fieldLabel={t('torzsadatok.phonedialog.other.label')} fieldName='other' value={values.other}
									error={errors.other} handleChange={handleChange} handleBlur={handleBlur}
								/>
								<div className='text-right'>
									<Button label={t('torzsadatok.phonedialog.button.save')} type='submit' className='btn ml-2'/>
									<Button label={t('torzsadatok.phonedialog.button.cancel')} type='button' onClick={()=>{props.onHideFunc()}} className='btn ml-2' />
								</div>
							</form>
						)}
					</Formik>
				</Dialog>
			)}
		</ApolloConsumer>
	)
}

/*******************************************************************************
 * Egy előfizetéshez tartozó telefonszámok táblázata
 *
 * @param account    Előfizetés adatai.
 * @param modifiable Módosítható-e.
 */
const AccountPhones = (props: {account: Account, setAccounts, selectedAccount, setSelectedAccount,setPossibleUsers, setSelectedUser, user,  modifiable: boolean}) => {
	const { t } = useTranslation('Registration')
	const [openPhoneDialog, setOpenPhoneDialog] = useState(false)
	const [selectedPhone, setSelectedPhone] = useState( {} as Phone )
	const [phones, setPhones] = useState()
	useEffect(() => {
		setPhones(props.account.phones)
	})

	const refreshPhones = (data: Phone) => {
		let megvan=false
		let newPhones = [...phones]
		for( let i in newPhones ) {
			if( newPhones[i].id == data.id ) {
				megvan = true
				newPhones[i] = data
				break
			}
		}
		if( !megvan ) { //ha új telefonszám
			newPhones.push( data )
		}
		setPhones(newPhones)
	}

	return (
		<div>
			{/* dialógusok */}
			<PhoneEditDialog   accountId={props.account.id} data={selectedPhone} openDialog={openPhoneDialog}
				onHideFunc={()=>{setOpenPhoneDialog(false)}} refreshFunc={refreshPhones}
				setAccounts={props.setAccounts}
				selectedAccount={props.selectedAccount}
				setSelectedAccount={props.setSelectedAccount}
				setPossibleUsers={props.setPossibleUsers}
				setSelectedUser={props.setSelectedUser}
				user={props.user}
			/>

			{/* Telefonszámok */}
			<DataTable responsive={true} className='scr-table' value={phones} emptyMessage={t('message.noData')}>
				<Column field='number' header={t('torzsadatok.accountdata.phones.tableheader.number')} />
				<Column field='sms'    header={t('torzsadatok.accountdata.phones.tableheader.sms')}
					body={ (rowData: Phone, column: number) => {
						return t(rowData.sms ? 'message.true' : 'message.false')
					}}
				/>
				<Column field='other'  header={t('torzsadatok.accountdata.phones.tableheader.other')} />
				<Column className='icon-column' body={ (rowData: Phone, column: number) => {
					return <Button icon='pi pi-pencil'
						onClick={(e) => {setSelectedPhone(rowData); setOpenPhoneDialog(true)} }
						disabled={!props.modifiable}
					/>
				}} />
			</DataTable>
			{/* Új telefonszám hozzáadása */}
			<Button icon='pi pi-plus' label={t('torzsadatok.accountdata.phones.button.add')}  type='button' className='btn mt-2'
				disabled={!props.modifiable}
				onClick={(e) => {setSelectedPhone( {} as Phone); setOpenPhoneDialog(true)} }
			/>
		</div>
	)
}

export default AccountPhones
