import React from 'react'
import { useTranslation } from 'react-i18next'

const PageNotAvailable = ({ children } : {children? : any}) => {
  const { t } = useTranslation(['Common'])
  return (
    <div className='container mt-5'>
      <div className='row'>
        <div className='card white mb-3 p-0 col-sm'>
          <div className='card-body bg-light'>
            <h3>{t('Common:pageNotAvailable.title')}</h3>
            <div>{t('Common:pageNotAvailable.desc1')}<a href='regisztracio'>{t('Common:pageNotAvailable.desc2')}</a>!</div>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PageNotAvailable
