import fetch from 'node-fetch'

export interface InputAddress {
	postalCode?: string;
	city?: string;
	streetName?: string;
	streetType?: string;
	houseNumber?: string;
	building?: string;
	staircase?: string;
	floor?: string;
	door?: string;
	topographicalNumber?: string;
	postalBox?: string;
	fullAddress?: string;
}

export interface ResultAddress {
	postalCode?: string;
	city?: string;
	streetName?: string;
	streetType?: string;
	houseNumber?: string;
	building?: string;
	staircase?: string;
	floor?: string;
	door?: string;
	topographicalNumber?: string;
	postalBox?: string;

	qualification ?: string;
	checkLevel ?: string;
	conformity?: number;
	identificationLevel ?: string;

	citySection?: string;
	county?: string;
	district?: string;
	ksh?: string;
	standardAfterCity?: string;
	standardAfterStreet?: string;
	standardAddress?: string;
	invalid?: string;

	eovY?: number;
	eovX?: number;
	wgsLat?: number;
	wgsLong?: number;
}

export interface ResultXRay {
	qualification ?: string;
	checkLevel ?: string;
	identificationLevel ?: string;
	conformity?: number;
	conformityGrade?: string;
	accuracy?: number;
	correctness?: number;
	correctnessGrade ?: string;
	potentialCount?: number;
	houseNumberValid ?: boolean;
}

export interface ResultMeter {
	correctness?: number;
	correctnessGrade ?: string;
}

export interface ResultError {
	code: any,
	message: string,
}

const jsonRpcUrl = window.location.protocol + '/jsonrpc'

function convertMakettError (res:any, jsonrpc: any) : ResultError {
  let ret:ResultError = { code: res.status, message: '' }
  if (jsonrpc.error && jsonrpc.error.data.exceptionTypeName.includes('Provisioning')) {
    if (jsonrpc.error.data.message.includes('notEnoughQuota')) {
      ret = { ...ret, message: 'Nincs az egyenlegén elegendő felhasználható erőforrás.' }
    }
  }
  if (ret.message === '') {
    ret.message = 'Ismeretlen hiba: ' + jsonrpc.error.data.message
  }
  return ret
}

export async function standardizeAddress (address: InputAddress, apiKey : string, options: string) : Promise<ResultAddress|ResultError> {
  const payload = {
    jsonrpc: '2.0',
    method: 'standardizeAddress',
    params: {
      apiKey,
      address,
      options
    },
    id: '1'
  }
  const res = await fetch(
    jsonRpcUrl,
    { method: 'POST', body: JSON.stringify(payload), headers: { 'CIMKLINIKA_INTERFACE': 'Kezelo' } }
  )
  const jsonrpc = await res.json()
  if (res.ok) {
    return jsonrpc.result as ResultAddress
  } else {
    return convertMakettError(res, jsonrpc)
  }
}

export async function xRayAddress (address: InputAddress, apiKey : string, options : string) : Promise<ResultXRay|ResultError> {
  const payload = {
    jsonrpc: '2.0',
    method: 'xRayAddress',
    params: {
      apiKey,
      address,
      options
    },
    id: '1'
  }
  const res = await fetch(
    jsonRpcUrl,
    { method: 'POST', body: JSON.stringify(payload), headers: { 'CIMKLINIKA_INTERFACE': 'Kezelo' } }
  )
  const jsonrpc = await res.json()
  if (res.ok) {
    return jsonrpc.result as ResultXRay
  } else {
    return convertMakettError(res, jsonrpc)
  }
}

export async function meterAddress (address: InputAddress, apiKey : string, options : string) : Promise<ResultMeter|ResultError> {
	const payload = {
		jsonrpc: '2.0',
		method: 'meterAddress',
		params: {
			apiKey,
			address,
			options
		},
		id: '1'
	}
	const res = await fetch(
		jsonRpcUrl,
		{ method: 'POST', body: JSON.stringify(payload), headers: { 'CIMKLINIKA_INTERFACE': 'Kezelo' } }
	)
	const jsonrpc = await res.json()
	if (res.ok) {
		return jsonrpc.result as ResultMeter
	} else {
		return convertMakettError(res, jsonrpc)
	}
}

export async function demoStandardizeAddress (address: InputAddress) : Promise<ResultAddress|ResultError> {
  const payload = {
    jsonrpc: '2.0',
    method: 'demoStandardizeAddress',
    params: {
      address
    },
    id: '1'
  }
  const res = await fetch(
    jsonRpcUrl,
    { method: 'POST', body: JSON.stringify(payload) }
  )
  const jsonrpc = await res.json()
  if (res.ok) {
    return jsonrpc.result as ResultAddress
  } else {
    return convertMakettError(res, jsonrpc)
  }
}

export async function completeAddress (address: InputAddress, apiKey : string) : Promise<ResultAddress[]|ResultError> {
  const payload = {
    jsonrpc: '2.0',
    method: 'completeAddress',
    params: {
      apiKey,
      address
    },
    id: '1'
  }
  const res = await fetch(
    jsonRpcUrl,
    { method: 'POST', body: JSON.stringify(payload), headers: { 'CIMKLINIKA_INTERFACE': 'Kezelo' } }
  )
  const jsonrpc = await res.json()
  if (res.ok) {
    return jsonrpc.result as ResultAddress[]
  } else {
    return convertMakettError(res, jsonrpc)
  }
}

export const makettComplete = (apiKey:any, makettAddress:any, setSuggestions:any, getDisplayString:any, growl:any, splitSuggestions:boolean = false) => {
  const res = completeAddress(makettAddress, apiKey).then((res) => {
    // @ts-ignore
    if (res.code) {
      setSuggestions([])
      // @ts-ignore
      growl.show({ severity: 'error', summary: 'Hiba', detail: res.message })
    } else {
      if (res) {
        // @ts-ignore
        const suggestions:string[] = res.map((a, i) => {
          return getDisplayString(a)
        })
		if (splitSuggestions && suggestions.length > 0) {
			const toSplit = suggestions[0]
			if (toSplit) {
				setSuggestions(toSplit.split(','))
			} else {
				setSuggestions([])
			}
		} else {
			setSuggestions(suggestions)
		}
      }
    }
  }).catch(() => {
    growl.show({ severity: 'error', summary: 'Hiba', detail: 'Nem érhető el a szolgáltatás.' })
  })
}

export const houseNumberValid = (additionalServices: any, result: ResultAddress) => {
  const needHouseNumber = additionalServices && additionalServices.includes('Hazszam')
  return !needHouseNumber || result.checkLevel === 'houseNumber'
}

/**
 * Visszaadja, hogy az input irányítószám lista tartalmazza-e a megadott irányítószámot.
 * @param postalCodeList vesszővel elválaszott irányítószám lista
 * @param postalCode az irányítószám
 */
export const containsPostalCode = (postalCodeList: string, postalCode: string): boolean => {
	let contains = false
	postalCodeList.split(',').forEach((code: string) => {
		if (code === postalCode) {
			contains = true
		}
	})
	return contains
}
