/*eslint no-unused-expressions: "warn"*/
/*******************************************************************************
 * Törzsadatok oldal
 *******************************************************************************/
import React, { useState } from 'react'
import { Query, ApolloConsumer } from 'react-apollo'
import gql from 'graphql-tag'
import { Panel } from 'primereact/panel'
import { InputSwitch } from 'primereact/inputswitch'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { Button } from 'primereact/button'
import ApolloClient from 'apollo-client'
import { UserContextConsumer } from '../../components/UserContext'
import { getEnumValue } from '../../utils/EnumUtils'
import PageNotAvailable from '../../components/PageNotAvailable'
import ProfileAccountUserSelector from './profile/ProfileAccountUserSelector'
import UserData from './profile/ProfileUserData'
import ProfileAccountData, { Account, AccountState, Role, User } from './profile/ProfileAccountData'
import AccountAddresses from './profile/ProfileAddress'
import AccountPhones from './profile/ProfilePhone'

// Értesítések
enum Notification {
	TOPUP  ='TOPUP',   // Egyenleg 10% alá esik, feltöltés történik.
	MODIFY ='MODIFY',  // Státusz vagy jog változásról, meghívásról.
	SUPPORT='SUPPORT', // Támogatási kérés indításáról.
	PWD    ='PWD',     // Sikeres vagy sikertelen jelszó változtatásról.
	NEW    ='NEW',     // Új böngészőből és OS-ről való sikere4s belépéskor.
	FAIL   ='FAIL'     // 4 sikertelen belépés 10 percen belül.
}

const LIST_ACCOUNTS = gql`
	query GetCurrentUserAccounts{
  		getCurrentUserAccounts{
			id
			segment
			businessRole
			vegfelhasznalo
			viszontelado
			promoter
			state
			shortName
			longName
			activity
			capital
			registrationNumber
			taxNumber
			addresses {
				id
				type
				countryCode
				zip
				city
				other
			}
			riskOfFailure
			phones {
				id
				number
				sms
				other
			}
			pincode
			accountUsers {
				user {
					id
					state
					email
					firstName
					lastName
					homeCity
					birthday
					workplace
				}
				roles
				notifications
				status
			}
		}
	}
`

/*******************************************************************************
 * Jogosultságok mentése
 *
 * @param client    Apollo kliens.
 * @param accountId Előfizetés ID.
 * @param userId    Felhasználó ID.
 * @param newRoles  Új jogosultságok.
 */
export const modifyRoles = async (client: ApolloClient<any>, accountId: Number, userId: Number, newRoles: Array<Role>) => {
	try {
		const res = await client.mutate({
			mutation: gql`
				mutation ModifyRoles($accountId: ID!, $userId: ID!, $newRoles: [Role]!) {
					modifyRoles(accountId: $accountId, userId: $userId, newRoles: $newRoles) {
						status
					}
				}
			`,
			variables: { 'accountId': accountId, 'userId': userId, 'newRoles': newRoles }
		})
		console.log(res)
		//TODO: adatok és felület frissítése...
	} catch (error) {
		console.log(JSON.stringify(error))
		//TODO hibajelzés...
	}
}

/*******************************************************************************
 * Értesítések mentése
 *
 * @param client           Apollo kliens.
 * @param accountId        Előfizetés ID.
 * @param userId           Felhasználó ID.
 * @param newNotifications Új értesítések.
 */
export const modifyNotifications = async (client: ApolloClient<any>, accountId: Number, userId: Number, newNotifications: Array<Notification>) => {
	try {
		const res = await client.mutate({
			mutation: gql`
				mutation ModifyNotifications($accountId: ID!, $userId: ID!, $newNotifications: [Notification]!) {
					modifyNotification(accountId: $accountId, userId: $userId, newNotifications: $newNotifications) {
						status
					}
				}
			`,
			variables: { accountId, userId, newNotifications }
		})
		console.log(res)
		//TODO: adatok és felület frissítése...
	} catch (error) {
		console.log(JSON.stringify(error))
		//TODO hibajelzés...
	}
}

export const refreshAccounts = (client) => {
	 return client.query({ query: LIST_ACCOUNTS, fetchPolicy: 'network-only' })
		.then((accounts) => {
			return accounts.data.getCurrentUserAccounts
		})
		.catch(error => {
			console.log(JSON.stringify(error))
		})
}

/*******************************************************************************
 * Egy folyószámla+felhasználóhoz tartozó jogosultságok
 *
 * @param roles       Jogosultságok listája.
 * @param modifiable  Módosítható-e.
 * @param accountId   Előfizetés ID-ja.
 * @param userId      Felhasználó ID-ja.
 * @param userContext Bejelentkezett felhasználó context.
 */
const RolesData = (props: {roles: Array<Role>, modifiable: boolean, accountId: Number, userId: Number, userContext:any}) => {
	const { t } = useTranslation('Registration')
	return (
		<ApolloConsumer>
			{client => (
				<Formik
					initialValues={{
						balance  : props.roles.includes(Role.BALANCE),
						order    : props.roles.includes(Role.ORDER),
						admin    : props.roles.includes(Role.ADMIN),
						szerviz  : props.roles.includes(Role.SZERVIZ),
						apolo    : props.roles.includes(Role.APOLO),
						konzilium: props.roles.includes(Role.KONZILIUM),
						api      : props.roles.includes(Role.API),
						kezelo   : props.roles.includes(Role.KEZELO),
						disabled : !props.modifiable
					}}
					validate={values => {
						let errors: any = {}
						//Nincs mit ellenőrizni :)
						return errors
					}}
					onSubmit={(values, { setSubmitting }) => {
						let newRoles: Array<Role> = new Array<Role>()

						if ( values.balance   ) { newRoles.push( Role.BALANCE   ) };
						if ( values.order     ) { newRoles.push( Role.ORDER     ) };
						if ( values.admin     ) { newRoles.push( Role.ADMIN     ) };
						if ( values.szerviz   ) { newRoles.push( Role.SZERVIZ   ) };
						if ( values.apolo     ) { newRoles.push( Role.APOLO     ) };
						if ( values.konzilium ) { newRoles.push( Role.KONZILIUM ) };
						if ( values.api       ) { newRoles.push( Role.API       ) };
						if ( values.kezelo    ) { newRoles.push( Role.KEZELO    ) };

						// console.log( newRoles )
						//módosítások mentése
						modifyRoles(client, props.accountId, props.userId, newRoles).then(
							//bejelentkezett felhasználó frissítése
							props.userContext.updateUser()
						)
					}}
				>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
						isSubmitting,
						setFieldValue
						/* and other goodies */
					  }) => (
						<form className='form-torzsadat-rolesdata' onSubmit={handleSubmit} >
							<Panel header={t('torzsadatok.role.panel.1.0')} className='mb-2'>
								{/* BALANCE */}
								<div>
									<InputSwitch checked={values.balance} onChange={(e: any) => setFieldValue('balance', e.value, true)}
										disabled={values.disabled}
									/>
									<label className='p-checkbox-label'>{t('torzsadatok.role.balance.label')}</label>
								</div>
								{/* ORDER */}
								<div>
									<InputSwitch checked={values.order} onChange={(e: any) => setFieldValue('order', e.value, true)}
										disabled={values.disabled}
									/>
									<label className='p-checkbox-label'>{t('torzsadatok.role.order.label')}</label>
								</div>
								{/* Admin */}
								<div>
									<InputSwitch checked={values.admin} onChange={(e: any) => setFieldValue('admin', e.value, true)}
										disabled={values.disabled}
									/>
									<label className='p-checkbox-label'>{t('torzsadatok.role.admin.label')}</label>
								</div>

								<Panel header={t('torzsadatok.role.panel.1.1')} className='mb-2'>
									{/* SZERVIZ */}
									<div>
										<InputSwitch checked={values.szerviz} onChange={(e: any) => setFieldValue('szerviz', e.value, true)}
											disabled={values.disabled}
										/>
										<label className='p-checkbox-label'>{t('torzsadatok.role.szerviz.label')}</label>
									</div>
									{/* APOLO */}
									<div>
										<InputSwitch checked={values.apolo} onChange={(e: any) => setFieldValue('apolo', e.value, true)}
											disabled={values.disabled}
										/>
										<label className='p-checkbox-label'>{t('torzsadatok.role.apolo.label')}</label>
									</div>
									{/* KONZILIUM */}
									<div>
										<InputSwitch checked={values.konzilium} onChange={(e: any) => setFieldValue('konzilium', e.value, true)}
											disabled={values.disabled}
										/>
										<label className='p-checkbox-label'>{t('torzsadatok.role.konzilium.label')}</label>
									</div>
								</Panel>
							</Panel>

							<Panel header={t('torzsadatok.role.panel.2.0')} className='mb-2'>
								<Panel header={t('torzsadatok.role.panel.2.1')} className='mb-2'>
									{/* API */}
									<div>
										<InputSwitch checked={values.api} onChange={(e: any) => setFieldValue('api', e.value, true)}
											disabled={values.disabled}
										/>
										<label className='p-checkbox-label'>{t('torzsadatok.role.api.label')}</label>
									</div>
									{/* KEZELO */}
									<div>
										<InputSwitch checked={values.kezelo} onChange={(e: any) => setFieldValue('kezelo', e.value, true)}
											disabled={values.disabled}
										/>
										<label className='p-checkbox-label'>{t('torzsadatok.role.kezelo.label')}</label>
									</div>
								</Panel>
							</Panel>

							<Button label={t('torzsadatok.role.button.submit')} type='submit' className='btn'
								disabled={values.disabled}
							/>
						</form>
					)}
				</Formik>
			)}
		</ApolloConsumer>
	)
}

/*******************************************************************************
 * Egy folyószámla+felhasználóhoz tartozó értesítések
 *
 * @param notifications Értesítések listája.
 * @param modifiable    Módosítható-e.
 * @param accountId     Előfizetés ID-ja.
 * @param userId        Felhasználó ID-ja.
 * @param userContext   Bejelentkezett felhasználó context.
 */
const NotificationsData = (props: {notifications: Array<Notification>, modifiable: boolean, accountId: Number, userId: Number, userContext:any}) => {
	const { t } = useTranslation('Registration')
	return (
		<ApolloConsumer>
			{client => (
				<Formik
					initialValues={{
						topup   : props.notifications.includes(Notification.TOPUP),
						modify  : props.notifications.includes(Notification.MODIFY),
						support : props.notifications.includes(Notification.SUPPORT),
						pwd     : props.notifications.includes(Notification.PWD),
						new     : props.notifications.includes(Notification.NEW),
						fail    : props.notifications.includes(Notification.FAIL),
						disabled: !props.modifiable
					}}
					validate={values => {
						let errors: any = {}

						return errors
					}}
					onSubmit={(values, { setSubmitting }) => {
						let newNotifications: Array<Notification> = new Array<Notification>()

						if ( values.topup   ) { newNotifications.push( Notification.TOPUP   ) };
						if ( values.modify  ) { newNotifications.push( Notification.MODIFY  ) };
						if ( values.support ) { newNotifications.push( Notification.SUPPORT ) };
						if ( values.pwd     ) { newNotifications.push( Notification.PWD     ) };
						if ( values.new     ) { newNotifications.push( Notification.NEW     ) };
						if ( values.fail    ) { newNotifications.push( Notification.FAIL    ) };

						// console.log( newNotifications )
						//módosítások mentése
						modifyNotifications(client, props.accountId, props.userId, newNotifications).then(
							//bejelentkezett felhasználó frissítése
							props.userContext.updateUser()
						)
					}}
				>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
						isSubmitting,
						setFieldValue
						/* and other goodies */
					  }) => (
						<form className='form-torzsadat-notificationsdata' onSubmit={handleSubmit} >
							<Panel header={t('torzsadatok.notification.panel.1')} className='mb-2'>
								{/* TOPUP */}
								<div>
									<InputSwitch checked={values.topup} onChange={(e: any) => setFieldValue('topup', e.value, true)}
										disabled={values.disabled}
									/>
									<label className='p-checkbox-label'>{t('torzsadatok.notification.topup.label')}</label>
								</div>
							</Panel>

							<Panel header={t('torzsadatok.notification.panel.2')} className='mb-2'>
								{/* MODIFY */}
								<div>
									<InputSwitch checked={values.modify} onChange={(e: any) => setFieldValue('modify', e.value, true)}
										disabled={values.disabled}
									/>
									<label className='p-checkbox-label'>{t('torzsadatok.notification.modify.label')}</label>
								</div>
								{/* SUPPORT */}
								<div>
									<InputSwitch checked={values.support} onChange={(e: any) => setFieldValue('support', e.value, true)}
										disabled={values.disabled}
									/>
									<label className='p-checkbox-label'>{t('torzsadatok.notification.support.label')}</label>
								</div>
							</Panel>

							<Panel header={t('torzsadatok.notification.panel.3')} className='mb-2'>
								{/* PWD */}
								<div>
									<InputSwitch checked={values.pwd} onChange={(e: any) => setFieldValue('pwd', e.value, true)}
										disabled={values.disabled}
									/>
									<label className='p-checkbox-label'>{t('torzsadatok.notification.pwd.label')}</label>
								</div>
								{/* NEW */}
								<div>
									<InputSwitch checked={values.new} onChange={(e: any) => setFieldValue('new', e.value, true)}
										disabled={values.disabled}
									/>
									<label className='p-checkbox-label'>{t('torzsadatok.notification.new.label')}</label>
								</div>
								{/* FAIL */}
								<div>
									<InputSwitch checked={values.fail} onChange={(e: any) => setFieldValue('fail', e.value, true)}
										disabled={values.disabled}
									/>
									<label className='p-checkbox-label'>{t('torzsadatok.notification.fail.label')}</label>
								</div>
							</Panel>

							<Button label={t('torzsadatok.notification.button.submit')} type='submit' className='btn'
								disabled={values.disabled}
							/>
						</form>
					)}
				</Formik>
			)}
		</ApolloConsumer>
	)
}

export const refreshAccountsAndUsers = (client, setAccounts, selectedAccount, setSelectedAccount, user, setPossibleUsers, setSelectedUser) => {
	refreshAccounts(client).then((accounts) => {
		setAccounts(accounts)
		let users: any = []
		let selectedUser = null
		let selectedAccountbyFind = accounts.find(account => account.id === selectedAccount.id)
		setSelectedAccount(selectedAccountbyFind)
		selectedAccountbyFind.accountUsers.forEach((value: any, idx: any) => {
			let state = value.status == null ? '' : getEnumValue('AccountState', value.status)
			state = state.length > 0 ? (' - ' + state) : ''
			users.push({ key: 'pu_' + idx, label: (value.user.email + state), value: value.user })
			if (user.id === value.user.id)
			{
				selectedUser = value.user
			}
		})
		setPossibleUsers(users)
		setSelectedUser(selectedUser)
	})
}

const TorzsadatokForm = (props: {accounts: Array<Account>}) => {
	const { t } = useTranslation('Registration')
	// const [selectedAccountIdx, setSelectedAccountIdx] = useState(0)
	// const [selectedUserIdx, setSelectedUserIdx] = useState(0)
	const [accounts, setAccounts] = useState(props.accounts)
	const [selectedAccount, setSelectedAccount] = useState<Account>(props.accounts[0]) //kiválasztott folyószámla
	const [selectedUser, setSelectedUser] = useState<User>(props.accounts[0].accountUsers[0].user) //kiválasztott felhasználó

	let users: any = []
	selectedAccount.accountUsers.forEach((value: any, idx: any) => {
		let state = value.status == null ? '' : getEnumValue('AccountState', value.status)
		state = state.length > 0 ? (' - ' + state) : ''
		users.push({ key: 'pu_' + idx, label: (value.user.email + state), value: value.user })
	})

	const [possibleUsers, setPossibleUsers] = useState<any>(users) //folyószámlához tartozó felhasználók

	return (
		<UserContextConsumer>
			{ (userContext: any) => (
				<div>
					{/* Kapcsolódó folyószámlák */}
					<Panel header={t('torzsadatok.panel.0')} className='mb-2'>
						<ProfileAccountUserSelector accounts={accounts}
							setAccounts={setAccounts}
							selectedAccount={selectedAccount}
							setSelectedAccount={setSelectedAccount}
							selectedUser={selectedUser}
							setSelectedUser={setSelectedUser}
							possibleUsers={possibleUsers}
							setPossibleUsers={setPossibleUsers}
						/>
					</Panel>
					{/*					{selectedUserIdx >= 0 ? ( */}
						<div>
							{/* Felhasználó törzsadatai */}
							{selectedUser && <Panel header={t('torzsadatok.panel.2')} className='mb-2'>
								 <UserData
									//@ts-ignore
									user={selectedUser}
									setAccounts={setAccounts}
                                   	selectedAccount={selectedAccount}
                                   	setSelectedAccount={setSelectedAccount}
                              		setPossibleUsers={setPossibleUsers}
                                   	setSelectedUser={setSelectedUser}
									currentUserId={userContext.loggedInUser.user.id}
									modifiable={userContext.loggedInUser.roles.includes(Role.ADMIN)}
								/>
							</Panel> }

							{/* Jogosultságok */}
							{ /*
								<Panel header={t('torzsadatok.panel.3')} className='mb-2'>
									<RolesData
										roles={props.accounts[selectedAccountIdx].accountUsers[selectedUserIdx].roles}
										modifiable={userContext.loggedInUser.roles.includes(Role.ADMIN)}
										accountId={props.accounts[selectedAccountIdx].id}
										userId={props.accounts[selectedAccountIdx].accountUsers[0].user.id}
										userContext={userContext}
									/>
								</Panel>
								*/ }

							{/* Értesítések */}
							{ /*
								<Panel header={t('torzsadatok.panel.4')} className='mb-2'>
									<NotificationsData
										notifications={props.accounts[selectedAccountIdx].accountUsers[selectedUserIdx].notifications}
										modifiable={userContext.loggedInUser.roles.includes(Role.ADMIN)}
										accountId={props.accounts[selectedAccountIdx].id}
										userId={props.accounts[selectedAccountIdx].accountUsers[selectedUserIdx].user.id}
										userContext={userContext}
									/>
								</Panel>
								*/ }
						</div>
					{/*) : ( */}
						<div>
							{/*Előfizetés adatai*/}
							{
								//@ts-ignore
								selectedAccount && selectedAccount.segment !== 'MAGAN' &&
								<Panel header={t('torzsadatok.panel.1')} className='mb-2'>
								  <ProfileAccountData
								  	//@ts-ignore
									account={selectedAccount}
                                    user={selectedUser}
                                    setAccounts={setAccounts}
                                    selectedAccount={selectedAccount}
                                    setSelectedAccount={setSelectedAccount}
                                    setPossibleUsers={setPossibleUsers}
                                    setSelectedUser={setSelectedUser}
									modifiable={userContext.loggedInUser.roles.includes(Role.ADMIN)}
								  />
								</Panel>
							}
							{selectedAccount && <div className='mb-5 p-15'>
								<h3 className='panel-title'>
									{t('torzsadatok.accountdata.addresses.label')}
								</h3>
								<AccountAddresses
									//@ts-ignore
									account={selectedAccount}
									user={selectedUser}
									setAccounts={setAccounts}
									selectedAccount={selectedAccount}
									setSelectedAccount={setSelectedAccount}
									setPossibleUsers={setPossibleUsers}
									setSelectedUser={setSelectedUser}
									modifiable={userContext.loggedInUser.roles.includes(Role.ADMIN)}
								/>
							</div>}
							{selectedAccount && <div className='mb-2 p-15'>
								<h3 className='panel-title'>
									{t('torzsadatok.accountdata.phones.label')}
								</h3>
								<AccountPhones
									//@ts-ignore
									account={selectedAccount}
									user={selectedUser}
									setAccounts={setAccounts}
									selectedAccount={selectedAccount}
									setSelectedAccount={setSelectedAccount}
									setPossibleUsers={setPossibleUsers}
									setSelectedUser={setSelectedUser}
									modifiable={userContext.loggedInUser.roles.includes(Role.ADMIN)}
								/>
							</div>}
						</div>
					{/*) } */}
				</div>
			) }
		</UserContextConsumer>
	)
}

const Profile = () => {
	const { t } = useTranslation('Registration')
	return (
		<UserContextConsumer>
			{ userContext => (
				<React.Fragment>
					{userContext && userContext.loggedInUser && userContext.loggedInUser !== null ?
						<React.Fragment>
							<div className='kezelo-container'>
								<h1 className='mt-5'>{t('torzsadatok.title')}</h1>
								<div className='kezelo-content mt-4'>
									<Query query={LIST_ACCOUNTS}>
										{(result) => {
											if (result.loading) {
												return <div>{t('message.loading')}</div>
											} else if (!result.data) {
												return <div>{t('message.error', { message: JSON.stringify(result) } )}</div>
											}  else {
												return <TorzsadatokForm accounts={result.data.getCurrentUserAccounts} />
											}
										}}
									</Query>
								</div>
							</div>
						</React.Fragment> :
						<PageNotAvailable />
					}
				</React.Fragment>
			)}
		</UserContextConsumer>
	)
}

export default Profile
