import React, { useRef, useState } from 'react'
import { UserContextConsumer } from '../../components/UserContext'
import { Formik } from 'formik'
import { Growl } from 'primereact/growl'
import { Button } from 'primereact/button'
import TextEditor from '../../components/editors/TextEditor'
import PasswordEditor from '../../components/editors/PasswordEditor'
import { Mutation } from 'react-apollo'
import gql from 'graphql-tag'
import { useTranslation } from 'react-i18next'
import { RegistrationContentWrapper } from './RegistrationPage'

export const CHANGE_PASSWORD_MUTATION = gql`
    mutation ChangePassword($email: String, $changeCode: String, $oldPassword: String, $newPassword: String) {
        changePassword(email: $email, changeCode: $changeCode, oldPassword: $oldPassword, newPassword: $newPassword) {
            id
            email
        }
    }
`

const ChangePasswordForm = ({ email, changeCode, changePassword, userContext } : {email:string, changeCode:string, changePassword: any, userContext:any}) => {
  let [result, setResult] = useState<any>(undefined)
  let [successPasswordModify, setSuccessPasswordModify] = useState(false)
  const growlRef = useRef(null)
  const { t } = useTranslation('Registration')
  return (<Formik
    initialValues={{ email: email, changeCode:changeCode, oldPassword: '', password: '', password2: '' }}
    validate={values => {
      let errors: any = {}
      // jelszó
      if (!values.password || values.password.length < 6) {
        errors.password = t('registration.password.error.invalid')
      }

      if (values.password !== values.password2) {
        errors.password = t('registration.password.error.notEquals')
        errors.password2 = t('registration.password.error.notEquals')
      }
      return errors
    }}
    onSubmit={async (values, { setSubmitting }) => {
      // setResult({message: 'Jelszó módosítás folyamatban...'})
      let resultChangePass : any = {}
      try {
        resultChangePass = await changePassword({
          variables: {
            email: values.email,
            changeCode: values.changeCode,
            oldPassword:values.oldPassword,
            newPassword: values.password
          } })
        { resultChangePass && 'code' in resultChangePass
        // @ts-ignore
          ? growlRef && growlRef.current.show({ severity: 'error', summary: resultChangePass.message })
        // @ts-ignore
        // growlRef && growlRef.current.show({severity: 'info', summary: 'Sikeres jelszó módosítás!'})
          : setSuccessPasswordModify(true)
        };
        { changeCode && userContext.loginUser(values.email, values.password) }
      } catch (e) {
        console.log(JSON.stringify(e))
        // @ts-ignore
        growlRef && growlRef.current.show({ severity: 'error', summary: e.graphQLErrors[0].message })
      }
    }}
  >
    {({
				  values,
				  errors,
				  touched,
				  handleChange,
				  handleBlur,
				  handleSubmit,
				  isSubmitting,
				  setFieldValue
				  /* and other goodies */
			  }) => (
  <React.Fragment>
    {successPasswordModify ? t('change-password.success-message')
					  : <form className='form-change-password'
  onSubmit={handleSubmit}
					  >
  <Growl ref={growlRef} />
        <div className='row'>
          <div className='col'>
            <TextEditor
              fieldName='email'
              fieldLabel={t('login.e-mail')}
              value={values.email}
              error={errors.email}
              handleChange={handleChange}
              handleBlur={handleBlur}
              disabled />
            {!changeCode &&
            <PasswordEditor
              fieldName='oldPassword'
              fieldLabel={t('change-password.old-password')}
              value={values.oldPassword}
              error={errors.oldPassword}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />}
            <PasswordEditor
              fieldName='password'
              fieldLabel={t('change-password.new-password')}
              value={values.password}
              error={errors.password}
              handleChange={handleChange}
              handleBlur={handleBlur}
											  />
            <PasswordEditor
              fieldName='password2'
              fieldLabel={t('change-password.new-password2')}
              value={values.password2}
              error={errors.password2}
              handleChange={handleChange}
              handleBlur={handleBlur}
											  />
          </div>
        </div>
        <div className='text-right'>
          <Button
            className='btn btn-outline-success'
            label={t('change-password.title')}
            type='submit' />
        </div>
      </form>
      }
  </React.Fragment>
    )}
  </Formik>
  )
}

const ChangePasswordPage = ({ routes, match }:{routes:any, match:any}) => {
  const { t } = useTranslation('Registration')
  return (
    <UserContextConsumer>
      {values => {
        let email = ''
        let changeCode = ''
        if (values && values.loggedInUser) {
          email = values.loggedInUser.user.email
        } else if (match.params.email) {
          email = match.params.email
          changeCode = match.params.kod
        }
        return (
          <Mutation mutation={CHANGE_PASSWORD_MUTATION}>
            {(changePassword) => (
                <RegistrationContentWrapper t={t} title={t('change-password.title')}>
                      {
                        ((match.params.email && match.params.kod) || (values && values.loggedInUser)) ?
                            <ChangePasswordForm email={email} changeCode={changeCode} changePassword={changePassword} userContext={values} /> :
                            t('change-password.bad-url')
                      }
                </RegistrationContentWrapper>
            )}
          </Mutation>
        )
      }}
    </UserContextConsumer>
  )
}

export default ChangePasswordPage
