import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import 'primereact/resources/themes/nova-light/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import './styles/main.scss'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'

import CimklinikaApp from './CimklinikaApp'
import * as serviceWorker from './serviceWorker'
// import i18n (needs to be bundled ;))
import i18n from './i18n'

import { ApolloProvider } from 'react-apollo'
import { ApolloClient } from 'apollo-client'
import { BatchHttpLink } from 'apollo-link-batch-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloLink, concat } from 'apollo-link'
import { UserContextProvider } from './components/UserContext'
import { I18nextProvider } from 'react-i18next'

const { createUploadLink } = require('apollo-upload-client')

// const httpLink = new BatchHttpLink({ uri: "/graphql" })

const httpLink = createUploadLink(new BatchHttpLink({ uri: '/graphql' }))

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext({
    headers: {
      authToken: localStorage.getItem('authToken') || null
    }
  })

  // @ts-ignore
  return forward(operation)
})

const client = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache()
})

ReactDOM.render(
  <Suspense fallback='...'>
    <I18nextProvider i18n={i18n}>
      <ApolloProvider client={client}>
        <UserContextProvider>
          <CimklinikaApp />
        </UserContextProvider>
      </ApolloProvider>
    </I18nextProvider>
  </Suspense>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
