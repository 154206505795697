import React from 'react'
import Login from '../../layout/Login'
import { useTranslation } from 'react-i18next'
import { RegistrationContentWrapper } from './RegistrationPage'


const LoginPage = () => {
	const { t } = useTranslation('Registration')
	return (
		<RegistrationContentWrapper t={t} className='col-12 col-md-6 col-xl-4 offset-md-3 offset-xl-4'>
			<Login label={t('login.button-title')}/>
		</RegistrationContentWrapper>
	)
}

export default LoginPage
