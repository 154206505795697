import React from 'react'

import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import FileDownload from '../../components/editors/FileDownload'
import { NavLink } from 'react-router-dom'

const FIND_ALL_ACCOUNTS = gql`
	query FindAllAccounts {
		findAllAccounts {
			id
			state
			shortName
			longName
		}
	}
`
const accountButtonCellRenderer = (rowData: any, column: any) => {
	return <>
		<NavLink to={`/szuperfelhasznalo/megrendeles/${rowData.id}`} >Megrendelés felvétel</NavLink>
    </>
}

const AccountListPage = ({ routes, match }:{routes:any, match:any}) => {
	const { t } = useTranslation()
	const queryListFelhasznalok = useQuery(FIND_ALL_ACCOUNTS)
	const serviceType = match.params && match.params.szolgaltatas
	return <div className='batch-page-content'>
		{!queryListFelhasznalok.loading ?
			<DataTable autoLayout={true} className='w-100' value={queryListFelhasznalok.data ? queryListFelhasznalok.data.findAllAccounts : []}>
				{/*<Column className="icon-column" body={iconTemplate} />*/}
				<Column sortable field='state' header='Állapot' />
				<Column sortable field='shortName' header='Rövid név' />
				<Column sortable field='longName' header='Teljes név' />
				<Column sortable field='orderButton' header='Megrendelés' body={accountButtonCellRenderer}/>
			</DataTable>
			:
			null
		}
	</div>
}

export default AccountListPage
