import React, { useRef, useState } from 'react'
import { refreshAccountsAndUsers } from '../Profile'
import { useTranslation } from 'react-i18next'
import { ApolloConsumer } from 'react-apollo'
import { Formik } from 'formik'
import moment from 'moment-with-locales-es6'
import { Growl } from 'primereact/growl'
import TextEditor from '../../../components/editors/TextEditor'
import gql from 'graphql-tag'
import { Button } from 'primereact/button'
import DateEditor from '../../../components/editors/DateEditor'
import ApolloClient from 'apollo-client'
import { AccountState, User } from './ProfileAccountData'

/*******************************************************************************
 * Ügyfél módosítása.
 *
 * @param client    Apollo kliens.
 * @param id        Módosítandó ügyfél ID.
 * @param state     Státusz.
 * @param email     E-mail cím (bejeletkezési név).
 * @param firstName Első név.
 * @param lastName  További név
 * @param homeCity  Település (tartózkodási hely)
 * @param birthday  Születési idő
 * @param workplace Munkahely
 *
 */
export const modifyUser = async (client: ApolloClient<any>, userId: Number, state: AccountState, email: String, firstName: String, lastName: String, homeCity: String, birthday: String, workplace: String) =>
client.mutate({
  mutation: gql`
      mutation ModifyUser($userId: ID!, $state: AccountState, $email: String!, $firstName: String, $lastName: String, $homeCity: String, $birthday: String, $workplace: String) {
          modifyUser(userId: $userId, state: $state, email: $email, firstName: $firstName, lastName: $lastName, homeCity: $homeCity, birthday: $birthday, workplace: $workplace) {
              id
          }
      }
  `,
	variables: { 'userId': userId, 'state': state, 'email': email, 'firstName': firstName, 'lastName': lastName, 'homeCity': homeCity, 'birthday': birthday, 'workplace': workplace }
})

/*******************************************************************************
 * Egy felhasználó inaktiválása gomb.
 *
 * @param userId Felhasználó ID-ja.
 */
const InactivateUserButton = (props: { userId: number } ) => {
	const { t } = useTranslation('Registration')
// console.log("InactivateUserButton(userId="+props.userId+")");
  return <ApolloConsumer>
	  {client => (
		  <Button
			  type='button'
			  label={t('torzsadatok.button.inactivate')}
			  className='btn'
			  onClick={async () => {
				  const result = await client.mutate({
					  mutation: gql`mutation InactivateUser($userId: ID!){inactivateUser(userId: $userId)}`,
					  variables: { userId: props.userId }
				  })
				  // TODO: oldal frissítése...
			  }}
		  />
	  )}
  </ApolloConsumer>
}

/*******************************************************************************
 * Egy felhasználó adatai
 *
 * @param user       Felhasználó adatai.
 * @param modifiable Módosítható-e.
 */
const UserData = (props: {user: User, setAccounts, selectedAccount, setSelectedAccount,setPossibleUsers, setSelectedUser, currentUserId: Number, modifiable: boolean}) => {
	const growlRef = useRef(null)
	const formRef = useRef(null)
	const { t } = useTranslation('Registration')
	const [ firstInit, setFirstInit ] = useState(false)
	//a string-es null értékeket cserélni kell '', hogy a Formik tudja frissíteni az üres értékekre.
	props.user.firstName = props.user.firstName === null ? '' : props.user.firstName
	props.user.lastName = props.user.lastName === null ? '' : props.user.lastName
	props.user.homeCity = props.user.homeCity === null ? '' : props.user.homeCity
	props.user.workplace = props.user.workplace === null ? '' : props.user.workplace

	return (
		<ApolloConsumer>
			{client => (
				<Formik
					enableReinitialize
					initialValues={{
						email: props.user.email,
						state: props.user.state,
						firstName: props.user.firstName,
						lastName: props.user.lastName,
						homeCity: props.user.homeCity,
						birthday: props.user.birthday ? moment(props.user.birthday).toDate() : '',
						workplace:props.user.workplace,
						disabled : !props.modifiable
					}}
					validate={values => {
						let errors: any = {}

						//E-mail cím: NEM módosítható!
						//Státusz: NEM módosítható!
						//Vezetéknév: kötelező
						// if( !values.firstName ) {
						// 	errors.firstName = t('torzsadatok.userdata.firstname.error.required')
						// }
						//Keresztnév: kötelező
						// if( !values.lastName ) {
						// 	errors.lastName = t('torzsadatok.userdata.lastname.error.required')
						// }
						//Település: NEM kötelező
						//Születési dátum: ???
						//Munkahely: ???

						return errors
					}}
					onSubmit={(values, { setSubmitting }) => {
						//módosítások mentése
						modifyUser(
							client,
							props.user.id, values.state, values.email, values.firstName, values.lastName, values.homeCity,
							(values.birthday ? values.birthday.getFullYear() + '-' + ('0' + (values.birthday.getMonth() + 1)).slice(-2) + '-' + ('0' + values.birthday.getDate()).slice(-2) : ''),
							values.workplace
						).then((user) => {
							// @ts-ignore
							growlRef && growlRef.current.show({ severity: 'success', summary: t('torzsadatok.button.success') })
							//folyószámlák frissítése
							refreshAccountsAndUsers(client, props.setAccounts, props.selectedAccount,
								props.setSelectedAccount, props.user, props.setPossibleUsers, props.setSelectedUser)
							// if (typeof errors === 'string' && errors.length > 0) {
							// @ts-ignore
							// growlRef && growlRef.current.show({ severity: 'error', summary: t(errors) })
							// }
						}).catch((error) => {
							// @ts-ignore
							growlRef && growlRef.current.show({ severity: 'error', summary: t('torzsadatok.button.fail') })
						})
					}}
				>
					{({
						  values,
						  errors,
						  touched,
						  handleChange,
						  handleBlur,
						  handleSubmit,
						  isSubmitting,
						  setFieldValue,

						  /* and other goodies */
					  }) => (
						<form className='form-torzsadat-userdata' onSubmit={handleSubmit} >
							<Growl ref={growlRef}/>
							<div className='row'>
								{/* email */}
								<div className='col-12 col-md-6'>
									<TextEditor
										fieldLabel={t('torzsadatok.userdata.email.label')} fieldName='email' value={values.email}
										error={errors.email} handleChange={handleChange} handleBlur={handleBlur}
										disabled={true}
									/>
								</div>
								{/* státusz */}
								<div className='col-12 col-md-6'>
									<TextEditor
										fieldLabel={t('torzsadatok.userdata.state.label')} fieldName='state' value={values.state}
										error={errors.state} handleChange={handleChange} handleBlur={handleBlur}
										disabled={true}
									/>
									{ (props.modifiable && values.state == AccountState.AKTIV && props.currentUserId != props.user.id) ?
										<InactivateUserButton userId={props.user.id} />
										: ''
									}
								</div>
								{/* vezetéknév */}
								<div className='col-12 col-md-6'>
									<TextEditor
										fieldLabel={t('torzsadatok.userdata.lastname.label')} fieldName='lastName' value={values.lastName}
										error={errors.lastName} handleChange={handleChange} handleBlur={handleBlur}
										disabled={values.disabled}
									/>
								</div>
								{/* keresztnév */}
								<div className='col-12 col-md-6'>
									<TextEditor
										fieldLabel={t('torzsadatok.userdata.firstname.label')} fieldName='firstName' value={values.firstName}
										error={errors.firstName} handleChange={handleChange} handleBlur={handleBlur}
										disabled={values.disabled}
									/>
								</div>
								{/* település */}
								<div className='col-12 col-md-6'>
									<TextEditor
										fieldLabel={t('torzsadatok.userdata.homecity.label')} fieldName='homeCity' value={values.homeCity}
										error={errors.homeCity} handleChange={handleChange} handleBlur={handleBlur}
										disabled={values.disabled}
									/>
								</div>
								{/* születési dátum */}
								<div className='col-12 col-md-6'>
									<DateEditor
										fieldLabel={t('torzsadatok.userdata.birthday.label')} fieldName='birthday' value={values.birthday && isNaN(values.birthday.getTime()) ? null : values.birthday}
										monthNavigator={true} yearNavigator={true}
										yearRange={moment().subtract(100, 'years').format('YYYY')+':'+moment().format('YYYY')}
										minDate={moment().subtract(100, 'years').set('month', 0).set('day', '1').toDate()}
										maxDate={moment().toDate()}
										error=''  onChange={
										(value: any) => setFieldValue('birthday', value, true)
									} handleBlur={handleBlur}
										disabled={values.disabled}
									/>
								</div>
								{/* munkahely */}
								<div className='col-12 col-md-6'>
									<TextEditor
										fieldLabel={t('torzsadatok.userdata.workplace.label')} fieldName='workplace' value={values.workplace}
										error={errors.workplace} handleChange={handleChange} handleBlur={handleBlur}
										disabled={values.disabled}
									/>
								</div>
							</div>
							<div className='text-right'>
								<Button label={t('torzsadatok.userdata.button.submit')} type='submit' className='btn'
									disabled={values.disabled}
								/>
							</div>
						</form>
					)}
				</Formik>
			)}
		</ApolloConsumer>
	)
}

export default UserData
