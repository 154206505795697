import React from 'react'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import mapPin from '../images/map-pin-128.png'
import { useTranslation } from 'react-i18next'

const MainPageLayout = ({ children, ...rest } : {children?: any, rest?: any}) => {
	const { t } = useTranslation('Registration')
	return (
  <div className='ck-root main-layout'>
    <div className='ck-header-main'>
      <Header>
        <section className='intro-content text-center pt-0 pb-2 px-2 px-md-3'>
          <h1 className='mt-3'>{t('landingPage.title1')}</h1>
          <hr className='short-red' />
          <h2>{t('landingPage.title2')}</h2>
          <div className=''>
            <div className='d-inline-block'>
              <img src={mapPin} className='mapPin mt-3' alt='map pin' />
            </div>
          </div>
          <h3 className='mt-4 mb-4'>{t('landingPage.title3')}</h3>
          {/* <hr className='short-red'/> */}
        </section>
      </Header>
      <main className='ck-main'>
        <div className=''>
          {children}
        </div>
      </main>
    </div>
    <Footer className='mt-5'/>
  </div>
	)
}

export default MainPageLayout
