import React, { useRef, useState } from 'react'
import { Formik } from 'formik'
import { InputAddress, standardizeAddress } from '../../makett/makett-service'
import { Button } from 'primereact/button'
import { getEnumValue } from '../../utils/EnumUtils'
import ConcatenatedAddressInputForm from './inputPanel/ConcatenatedAddressInputForm'
import MultiFieldAddressInputForm from './inputPanel/MultiFieldAddressInputForm'
import AddressResultPanel from './resultPanel/AddressResultPanel'
import { Growl } from 'primereact/growl'
import { useTranslation } from 'react-i18next'
import SelectButtonEnumEditor from '../../components/editors/SelectButtonEnumEditor'
import gql from 'graphql-tag'
import { ApolloConsumer, Query } from 'react-apollo'
import ApolloClient from 'apollo-client'
import ReactTooltip from 'react-tooltip'
import OperatorPanelWrapper from './inputPanel/OperatorPanelWrapper'
import { KezeloFormPanelWrapper } from '../main/MainPageOperator'
import imgClean48 from '../../images/pointer-32.png'
export const REMAINED_AMOUNT = gql`
    query GetRemainedAmount($apiKey: String!, $serviceType: ServiceType!, $interfaceType: InterfaceType!) {
        getRemainedAmount(apiKey: $apiKey, serviceType: $serviceType, interfaceType: $interfaceType){
            quota
            days
			hour
			minute
        }
    }
`
export const MAX_FILE_SIZE = gql`
    query GetMaxFileSize {
        getMaxFileSize {
            maxFileSize
            maxFileSizeValue
        }
    }
`
export const RemainedAmountQuery = ({ apiKey, quota, setQuota, days, setDays, hour, setHour, minute, setMinute, t, serviceType }:
										{apiKey:String, quota:any, setQuota:any, days:any, setDays:any, hour:any, setHour:any, minute:any, setMinute:any, t:any, serviceType:any}) => {
	let [result, setResult] = useState<any>(undefined)
	return (<Query query={REMAINED_AMOUNT} variables={{ apiKey: apiKey, serviceType: serviceType, interfaceType: 'Kezelo' }}>
			{(result : any) => {
				if (result.data && (result.data.loading)) {
					return <div>Betöltés...</div>
				} else if (!result) {
					return <div>Hiba történt: {JSON.stringify(result)}</div>
				}  else {
					if (result.data && result.data.getRemainedAmount)
					{
						setQuota(result.data.getRemainedAmount.quota)
						setDays(result.data.getRemainedAmount.days)
						setHour(result.data.getRemainedAmount.hour)
						setMinute(result.data.getRemainedAmount.minute)
					}
					return <div className='d-flex py-3 align-items-center amount-panel-wrapper'>
							<ReactTooltip />
						<div className='col-auto '>
						  <img src={imgClean48} data-tip={t('Provisioning:kezelo.reamined-amount')}/>
						</div>
						<div className='col'>
							<div className='amount-panel'>
								<div className='amount-value' data-tip={t('Provisioning:kezelo.reamined-amount')} >
									{days > 1 &&
										<span className='quota'>{t('Provisioning:kezelo.quota-and-days', { quota: quota, days: days } )}</span>
									}
									{days == 0 &&
                                    	<span className='quota'>{t('Provisioning:kezelo.quota-and-time', { quota: quota, hour: hour, minute: minute })}</span>
									}
									{days < 0 &&
                                    	<span className='quota'>{t('Provisioning:kezelo.quota-and-close')}</span>
									}
								</div>
							</div>
                    	</div>
                  </div>
				}
			}}
		</Query>
	)
}

const FormikKezeloForm = ({ apiKey } : {apiKey: string}) => {
	let { t } = useTranslation(['Common', 'Provisioning'])
	let [result, setResult] = useState<any>(undefined)
	let [progress, setProgress] = useState(false)
	let [quota, setQuota] = useState(undefined)
	let [days, setDays] = useState(undefined)
	let [hour, setHour] = useState(undefined)
	let [minute, setMinute] = useState(undefined)

	const growlRef = useRef(null)
	const displayEnumName = ((enumClass: any, enumValues: any) => {
		const values = getEnumValue(enumClass,enumValues)
		return values ? ' - ' + values : ''
	})


	return <ApolloConsumer>
		{(client : ApolloClient<any>) => (
			<>
				<KezeloFormPanelWrapper title={t('Provisioning:kezelo-form.title')}>
					<Formik
						initialValues={{ fillType: 'single', additionalServices: ['Hazszam', 'TerPont', 'Terkeppont'], fullAddress:'', postalCode:'', city:'', streetName:'', houseNumber:'' }}
						// validationSchema={object().shape()}
						validate={values => {
							let errors = {}
							// if (values.address.postalCode && !/^\d{4}(?:[-\s]\d{4})?$/i.test(values.address.postalCode)) {
							// 	// @ts-ignore
							// 	errors.postalCode = 'Nem megfelelő irányítószám!';
							// }
							if (!values.fillType) {
								// @ts-ignore
								errors.fillType = [t('message.required')]
							}
							if (values.fillType === 'single' && !values.fullAddress) {
								// @ts-ignore
								errors.fullAddress = [t('message.required')]
							}
							//Todo: ellenőrizni, hogy valamelyik mező ki van-e töltve (postalCode city streetName houseNumber)
							if (values.fillType === 'multi' && !values.postalCode && !values.city && !values.streetName && !values.houseNumber) {
								// @ts-ignore
								errors.fullAddress = [t('message.required')]
							}
							return errors
						}}
						onSubmit={async (values, { setSubmitting }) => {
							try {
								//@ts-ignore
								if (quota === 0 || days && days < 0) {
									//@ts-ignore
									if (days && days < 0) {
										//@ts-ignore
										growlRef && growlRef.current.show({ severity: 'error', summary: t('Provisioning:message.interfaceClosed') })
									}
									if (quota === 0) {
										//@ts-ignore
										growlRef && growlRef.current.show({ severity: 'error', summary: t('Provisioning:message.quotaRunOut') })
									}
								}
								else {
									setProgress(true)
									let address:InputAddress={}
									address.fullAddress = values.fullAddress
									address.postalCode = values.postalCode
									address.city = values.city
									address.streetName = values.streetName
									address.houseNumber = values.houseNumber
									let newAdditionalServices = [...values.additionalServices,'Bontva']
									const resultAddress = await standardizeAddress(address, apiKey, newAdditionalServices.join('|'))
									if (result && 'code' in result){
										//@ts-ignore
										growlRef && growlRef.current.show({ severity: 'error', summary: resultAddress.message })
										setProgress(false)
									} else {
										setProgress(false)
										setResult(resultAddress)
										const { data } = await client.query({ query: REMAINED_AMOUNT,
											variables: {
												apiKey: apiKey,
												serviceType: 'Tisztitas',
												interfaceType: 'Kezelo'
											},fetchPolicy:'network-only'
										})
										data.getRemainedAmount && setQuota(data.getRemainedAmount.quota)
										data.getRemainedAmount && setDays(data.getRemainedAmount.days)
									}
								}
							} catch (e) {
								console.log(JSON.stringify(e))
								setResult({ message: t('Provisioning:kezelo-form.makett-error') })
								setProgress(false)
							}
						}}
					>
						{({
							  values,
							  errors,
							  touched,
							  handleChange,
							  handleBlur,
							  handleSubmit,
							  isSubmitting,
							  setFieldValue,
							  resetForm
							  /* and other goodies */
						  }) => (
							<form className='form-signin'
								onSubmit={handleSubmit}
							>
								<Growl ref={growlRef}/>

								<OperatorPanelWrapper
									remainedAmountQuery={
										<RemainedAmountQuery
											apiKey={apiKey}
											quota={quota}
											setQuota={setQuota}
											days={days}
											setDays={setDays}
											hour={hour}
											setHour={setHour}
											minute={minute}
											setMinute={setMinute}
											t={t}
											serviceType='Tisztitas'
										/>
									}
									leftColumnContent={
										<>
											<div className='row pt-3 px-3'>
												<div className='col-12'>
													{t('Provisioning:kezelo-form.accordion1.title')}
													<SelectButtonEnumEditor
														fieldName='fillType'
														value={values.fillType}
														enumClass='FillType'
														error={errors.fillType}
														onChange={(value: any) => {
															setFieldValue('fillType', value, true)
															setFieldValue('postalCode', '', true)
															setFieldValue('city', '', true)
															setFieldValue('streetName', '', true)
															setFieldValue('houseNumber', '', true)
															setFieldValue('fullAddress', '', true)

														}}
													/>
												</div>
											</div>
											<div className='row px-3 py-2'>
												<div className='col-12'>
													{t('Provisioning:kezelo-form.accordion2.title')}
													<SelectButtonEnumEditor
														fieldName='additionalService'
														value={values.additionalServices}
														enumClass='AdditionalService'
														error={errors.additionalServices}
														tooltips={t('Provisioning:kezelo-form.accordion2.tooltip')}
														onChange={
															(value: any) => {
																setFieldValue('additionalServices', value, true)
																setResult(null)
															}
														}
														multiple={true}
													/>
												</div>
											</div>
											<div className='row pb-3 px-3'>
												<div className='col-12'>
													{t('Provisioning:kezelo-form.accordion3.title')}
													{values.fillType == 'single' ?
														<ConcatenatedAddressInputForm values={values} errors={errors} onAddressChanged={(value:any)=>{}} handleChange={handleChange} handleBlur={handleBlur}/> :
														<MultiFieldAddressInputForm values={values} errors={errors} onAddressChanged={(value:any)=>{}} handleChange={handleChange} handleBlur={handleBlur}/>}
													<div className='error-message'>
														{Object.keys(errors).length > 0 ? t('Provisioning:kezelo.message.check-data') : ''}
													</div>
													<div className='text-right'>
														<Button
															className='btn btn-outline-success my-2 my-sm-0 ml-2'
															label={t('Provisioning:kezelo.button.run-makett')}
															type='submit'>
														</Button>
													</div>
												</div>
											</div>
										</>
									}
									rightColumnContent={
										<AddressResultPanel
											result={result}
											additionalServices={values.additionalServices}
											t={t}
											progress={progress}
										>
											<h6 className='my-4 font-weight-bold'>{t('Provisioning:kezelo-form.desc1')}</h6>
											<p>{t('Provisioning:kezelo-form.desc2')}</p>
										</AddressResultPanel>
									}
								/>
							</form>
						)}
					</Formik>
				</KezeloFormPanelWrapper>
			</>
		)}
	  </ApolloConsumer>
}

export default FormikKezeloForm
