import DefaultPageLayout from './DefaultPageLayout'
import React from 'react'
import warningImage from '../images/warning-128.png'

const ErrorPage = ({ children, ...rest } : {children?: any, rest?: any}) => {
  return (
    <DefaultPageLayout>
      <div className='error-page col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 text-center my-5 '>
        <div className='d-flex align-items-center'>
          <div className='col-auto'>
            <img src={warningImage} alt='warning-image' />
          </div>
          <div className='col text-left'>
						Ez akkor jelenik meg, ha ismeretlen URL-re navigálnak.
          </div>
        </div>
      </div>
    </DefaultPageLayout>
  )
}
export default ErrorPage
