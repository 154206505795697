import validImg from '../../../images/valid-128.png'
import invalidImg from '../../../images/invalid-128.png'
import moreResultImg from '../../../images/help-128.png'
import React, { useState } from 'react'
import { RadioButton } from 'primereact/radiobutton'
import { ProgressBar } from 'primereact/progressbar'
import imgInfo32 from '../../../images/info-32.png'
import { houseNumberValid } from '../../../makett/makett-service'

const CKRadioButton = ({
						   value,
						   label,
						   name,
						   checked,
						   onChange,
						   additionalServices
}:
							{
						   		value:any,
								label:string,
								name:string,
								checked:boolean,
								onChange:any,
								additionalServices?:[]
							}) => {
  const onRadioChange = (e:any) => {
    onChange(e.value)
  }
  return (
    <div className='form-check-inline pl-0'>
      <RadioButton
        inputId={`${value}_id`}
        value={value}
        name={name}
        onChange={onRadioChange}
        checked={checked} />
      <label
        htmlFor={`${value}_id`}
        className='p-checkbox-label'>
        {label}
      </label>
    </div>
  )
}
export const CKLabelValue = ({ label, value, rendered = true, className }:{label:string, value:any, rendered?:boolean, className?:string}) => {
  return <React.Fragment>
    {rendered && <label className={`col-sm-5 col-form-label ${className || ''}`}>{label}</label>}
    {rendered && <div className={`col-sm-7 form-control-plaintext ${className || ''}`}>{value}</div>}
  </React.Fragment>
}

const AddressFields = ({ result, details, className, additionalServices, t }:{result:any, details:boolean, className?:string, additionalServices?:string[], t:any}) => {
	let isHouseNumberValid = houseNumberValid(additionalServices, result)
	let bontva = additionalServices && additionalServices.includes('Bontva')
	return (
    <div className={`form-group row ${className}`}>
      <CKLabelValue label={t('Provisioning:kezelo.postalCode')} value={result.postalCode} rendered={result.postalCode} />
      <CKLabelValue label={t('Provisioning:kezelo.city')} value={result.city} rendered={result.city} />
      <CKLabelValue label={t('Provisioning:kezelo.district')} value={result.district} rendered={result.district} />
      <CKLabelValue label={t('Provisioning:kezelo.streetName')} value={result.streetName} rendered={bontva && result.streetName} />
      <CKLabelValue label={t('Provisioning:kezelo.streetType')} value={result.streetType} rendered={bontva && result.streetType} />
	  <CKLabelValue label={t('Provisioning:kezelo.houseNumber')} value={result.houseNumber} rendered={details && result.houseNumber} className={isHouseNumberValid ? '' : 'highlight-red'} />
      <CKLabelValue label={t('Provisioning:kezelo.standardAfterStreet')} value={result.standardAfterCity} rendered={!bontva && result.standardAfterCity} className={isHouseNumberValid ? '' : 'highlight-red'} />
      <CKLabelValue label={t('Provisioning:kezelo.standardAfterStreet')} value={result.standardAfterStreet} rendered={!details && bontva && result.standardAfterStreet} className={isHouseNumberValid ? '' : 'highlight-red'} />
      <CKLabelValue label={t('Provisioning:kezelo.building')} value={result.building} rendered={details && result.building} className={isHouseNumberValid ? '' : 'highlight-red'} />
      <CKLabelValue label={t('Provisioning:kezelo.staircase')} value={result.staircase} rendered={details && result.staircase} className={isHouseNumberValid ? '' : 'highlight-red'} />
      <CKLabelValue label={t('Provisioning:kezelo.floor')} value={result.floor} rendered={details && result.floor} className={isHouseNumberValid ? '' : 'highlight-red'} />
      <CKLabelValue label={t('Provisioning:kezelo.door')} value={result.door} rendered={details && result.door} className={isHouseNumberValid ? '' : 'highlight-red'} />
      <CKLabelValue label={t('Provisioning:kezelo.topographicalNumber')} value={result.topographicalNumber} rendered={details && result.topographicalNumber} className={isHouseNumberValid ? '' : 'highlight-red'} />
      <CKLabelValue label={t('Provisioning:kezelo.postalBox')} value={result.postalBox} rendered={details && result.postalBox} />
	  <CKLabelValue label={t('Provisioning:kezelo.citySection')} value={result.citySection} rendered={details && result.citySection} />
      <CKLabelValue label={t('Provisioning:kezelo.eovX')} value={result.eovX} rendered={result.eovX && additionalServices && additionalServices.includes('TerPont')} />
      <CKLabelValue label={t('Provisioning:kezelo.eovY')} value={result.eovY} rendered={result.eovY && additionalServices && additionalServices.includes('TerPont')} />
      <CKLabelValue label={t('Provisioning:kezelo.wgsLat')} value={result.wgsLat} rendered={result.wgsLat && additionalServices && additionalServices.includes('Terkeppont')} />
      <CKLabelValue label={t('Provisioning:kezelo.wgsLong')} value={result.wgsLong} rendered={result.wgsLong && additionalServices && additionalServices.includes('Terkeppont')} />
      {result.wgsLat && result.wgsLong && additionalServices && additionalServices.includes('Terkeppont') &&
      <a className='col-sm-12' href={`http://maps.google.com/maps?q=${result.wgsLat},${result.wgsLong})`} target='_blank' >
			  Térkép
      </a>
      }
      {result.invalidParts && <CKLabelValue className='highlight-red' label={t('Provisioning:kezelo.invalidParts')} value={result.invalidParts} />}
    </div>
  )
}

const DisplayAddress = ({ result, details, additionalServices, t }:{result:any, details:boolean, additionalServices?:any[], t:any}) => {
  let [moreResultPanel, setMoreResultPanel] = useState(true)
  return (
    <React.Fragment>
      <AddressFields result={result} details={details} additionalServices={additionalServices} t={t} />
      {(result.moreResults && result.moreResults.length > 0)
        ? <div className='mt-3'>
          {
            result.moreResults.map((value:any) => {
              return (
                <React.Fragment>
                  <hr />
                  <AddressFields result={value} details={details} additionalServices={additionalServices} t={t} />
                </React.Fragment>
              )
            }
            )}
        </div>
        :				''
      }
    </React.Fragment>
  )
}

const AddressFieldsInline = ({ result, className, additionalServices, t }:{result:any, className?:string, additionalServices?:string[], t:any}) => {
  return (
    <div className={`form-group row ${className !== undefined ? className : ''}`}>
      <CKLabelValue label={t('Provisioning:kezelo.address')} value={result.standardAddress} />
      <CKLabelValue label={t('Provisioning:kezelo.eov')} value={(result.eovX ? result.eovX : '') + ' ' + (result.eovY ? result.eovY : '')} rendered={additionalServices && additionalServices.includes('TerPont')} />
      <CKLabelValue label={t('Provisioning:kezelo.wgs')} value={(result.wgsLat ? result.wgsLat : '') + ' ' + (result.wgsLong ? result.wgsLong : '')} rendered={additionalServices && additionalServices.includes('Terkeppont')} />
      {additionalServices && additionalServices.includes('Terkeppont') && result.wgsLat && result.wgsLong &&
      <a className='col-sm-12' href={`http://maps.google.com/maps?q=${result.wgsLat},${result.wgsLong})`} target='_blank' >
              Térkép
      </a>
      }
      {result.invalidParts && <CKLabelValue className='highlight-red' label={t('Provisioning:kezelo.invalidParts')} value={result.invalidParts} />}
    </div>
  )
}

const DisplayAddressInline = ({ result, additionalServices, t }:{result:any, additionalServices?:any[], t:any}) => {
  let [moreResultPanel, setMoreResultPanel] = useState(true)
  return (
    <React.Fragment>
      <AddressFieldsInline result={result} additionalServices={additionalServices} t={t} />
      {(result.moreResults && result.moreResults.length > 0)
        ? <div className='mt-3'>
          {
            result.moreResults.map((value:any) => {
              return (
                <React.Fragment>
                  <hr />
                  <AddressFieldsInline result={value} additionalServices={additionalServices} t={t} />
                </React.Fragment>
              )
            }
            )}
        </div>
        :				''
      }
    </React.Fragment>
  )
}

const DisplayAddressDemo = ({ result, additionalServices, t }:{result:any, additionalServices?:any[], t:any}) => {
  const CKDemoValues = ({ result }) => <>
    <div>{result.streetName} {result.streetType} <span className={houseNumberValid(additionalServices, result) ? '' : 'highlight-red'}>{result.standardAfterStreet}</span></div>
    <div className='text-uppercase'>{result.city}</div>
    <div>{result.postalCode}</div>
    {result.invalidParts && <div className='highlight-red'>
      <span className='mr-1'>{t('Provisioning:kezelo.invalidParts')}:</span>
      <span>{result.invalidParts}</span>
    </div>}
  </>

  return (
    <React.Fragment>
      <CKDemoValues result={result}/>
      {(result.moreResults && result.moreResults.length > 0)
        ? <div className='mt-3'>
          {
            result.moreResults.map((value:any) => {
              return (
                <React.Fragment>
                  <hr />
                  <CKDemoValues result={value}/>
                </React.Fragment>
              )
            }
            )}
        </div>
        :				''
      }
    </React.Fragment>
  )
}

const AddressResultPanel = ({
  children,
  result = undefined,
  additionalServices,
  t,
  progress = false,
  displayTypeValue
}:{
									children?:any,
									result?:any,
									additionalServices?:string[],
									t:any,
									progress?:boolean,
									displayTypeValue?:any
							}) => {
  let [displayType, setDisplayType] = useState<any>(displayTypeValue || 'multi')
  return (
    <div className='h-100'>
      <div className='result card h-100'>
        {((!result || result === undefined) && !progress) &&
        <div className='m-4'>
          {children && <div className=' mb-3 text-center'>
            <img src={imgInfo32} />
          </div>}
          {children}
        </div>
        }
        {progress && <div className='m-3'>
          <div>{t('Provisioning:kezelo.process')}</div>
          <ProgressBar mode='indeterminate' />
        </div>}
        {result && 'standardAddress' in result &&
        <div className='d-flex result-content'>
          <div className='col data-col'>
            <div className='result'>
				<div className='row'>
					<CKLabelValue label={t('Provisioning:kezelo-form.result.qualification')}
					  value={t('Provisioning:kezelo-form.result.qualification-values.'+result.qualification) + ', ' + t('Provisioning:kezelo-form.result.checkLevel-values.'+result.checkLevel)} />
				</div>
			</div>
            {displayType !== 'demo' && additionalServices && additionalServices.includes('Bontva') &&
			<>
              <hr />
              <div className='row mb-3'>
                <div className='col-auto'>
                  <label>{t('Provisioning:kezelo.result')}:</label>
				</div>
                <div className='col'>
				  <CKRadioButton value='single' label={t('Provisioning:kezelo.single')} name='displayTypeRadio'
					onChange={(value: any) => setDisplayType(value)} checked={displayType === 'single'} />
				  <CKRadioButton value='multi' label={t('Provisioning:kezelo.multi')} name='displayTypeRadio'
					onChange={(value:any) => setDisplayType(value)} checked={displayType === 'multi'} />
				  <CKRadioButton value='details' label={t('Provisioning:kezelo.details')} name='displayTypeRadio'
					onChange={(value: any) => setDisplayType(value)} checked={displayType === 'details'} />
				</div>
              </div>
            </>}
			<div className='result'>
              {displayType == 'demo' && <DisplayAddressDemo result={result} additionalServices={additionalServices} t={t} />}
              {displayType == 'single' && <DisplayAddressInline result={result} additionalServices={additionalServices} t={t} />}
              {displayType == 'multi' && <DisplayAddress result={result} details={false} additionalServices={additionalServices} t={t} />}
              {displayType == 'details' && <DisplayAddress result={result} details additionalServices={additionalServices} t={t} />}
               {/*{JSON.stringify(result)}*/}
            </div>
          </div>
          <div className='col-auto image-col'>
            {result.qualification === 'invalid' ?
				  <img src={invalidImg} alt='invalid image' /> :
				  	((result.qualification === 'ambiguous') ?
                      <img src={moreResultImg} alt='more result image' /> :
					  <img src={validImg} alt='valid image' />)
					    }
          </div>
        </div>
        }
      </div>
    </div>
  )
}

export default AddressResultPanel
