import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { Button } from 'primereact/button'
import OrderItemsEditor from './OrderItemsEditor'
import { FieldArray, Form, Formik } from 'formik'
import ComboEditor from '../../components/editors/ComboEditor'
import { OrderItemsTableByOrderId } from '../../components/OrderItemsTable'


const GET_ACCOUNT_QUERY = gql`
	query GetAccount($id: ID!) {
		getAccount(id: $id) {
			shortName
			longName
			users {
				id
				email
				firstName
				lastName
			}
		}
	}
`

const CREATE_AND_APPLY_ORDER = gql`
	mutation CreateAndApplyOrder($order: OrderInput!) {
		createAndApplyOrder(order: $order) {
			id
			orderStatus
		}
	}
`
const ManualOrderPage = ({ routes, match }:{routes:any, match:any}) => {
	const { t } = useTranslation()
	const [result, setResult] : [any, any] = useState(null)
	// @ts-ignore
	const { loading: accountLoading, error: accountError, data: accountData } = useQuery(GET_ACCOUNT_QUERY, { variables: { id: match.params.accountId } })
	const [createAndApplyOrder, { data }] = useMutation(CREATE_AND_APPLY_ORDER)
	const serviceType = match.params && match.params.szolgaltatas
	const initialValues = {
		header: {
			name: 'Szolgáltató által rögzített rendelés',
			accountId: match.params.accountId,
			userId: accountData && accountData.getAccount && accountData.getAccount.users.length > 0 ?
				accountData.getAccount.users[0].id : null
		},
		items: [
			/**
			{
				orderType: 'Interface',
				interfaceType: 'Json',
				quotaDays: 1,
				rechargeCardQuantity: 1
			},
			{
				orderType: 'Service',
				serviceType: 'Tisztitas',
				quotaAmount: 100,
				rechargeCardQuantity: 1,
				serviceOptions: ['Bontva', 'Hazszam']
			}
			 */
		]
	}

	function createForm()
	{
		const userComboValue = (users) =>
			users.map(user => ({ value: user.id, label: user.lastName + ' ' + user.firstName }))

		return <Formik
			initialValues={initialValues}
			// @ts-ignore
			validate={() => true}
			onSubmit={values => {
				createAndApplyOrder({ variables: { order: { ...values } } })
				// @ts-ignore
					.then((res) => setResult(res))
			}}
			render={({ values, setFieldValue, errors }) => (
				<Form>
					<div className='row'>
						<div className='col-4'>
							<ComboEditor
								fieldName='header.userId'
								fieldLabel='Megrendelés leadó felhasználó:'
								value={values.header.userId}
								// @ts-ignore
								error={errors.header ? errors.header.userId : null}
								editable={false}
								possibleValues={userComboValue(accountData.getAccount.users)}
								onChange={(value: any) => {
									// @ts-ignore
									setFieldValue('header.userId', value, true)
								}} />
						</div>
					</div>
					<FieldArray
						name='items'
						render={arrayHelpers => (
							<div>
								{
									values.items.map((item, index) => (
											<div key={index}>
												<OrderItemsEditor name={`items.${index}`} onDelete={() => arrayHelpers.remove(index)}/>
											</div>
										)
									)
								}
								<Button onClick={() => arrayHelpers.push({ orderType: null })} label='Új tétel felvétele' />
							</div>
						)}
					/>
					<div className='row float-right'>
						<div className='col-12'>
							<Button label='Megrendelés létrehozása' type='submit' className='btn ml-2'/>
						</div>
					</div>
				</Form>
			)}
		>
		</Formik>
	}

	return <div className='batch-page-content'>
		{result === null && match.params && match.params.accountId ?
			<div className='kezelo-container'>
				{
					!accountLoading && !accountError && accountData ? <>
						<h1 className='mt-5'>Új megrendelés felvétele</h1>
						<div>
							Előfizető: {accountData.getAccount.shortName || accountData.getAccount.longName}
							{/**
							<ul>
								{
									accountData.getAccount.users.map((user, index) =>
										<li key={index}>{user.email} / {user.firstName} {user.lastName}</li>
									)
								}
							</ul>
							*/}
						</div>
						<div>
							{createForm()}
						</div>
					</> :
					accountError ? <div>
						Az előfizetés adatai nem érhetők el.
					</div> : <div>
						Előfizetés adatainak betöltése...
					</div>
				}
			</div>
		: result !== null ?
			<div>
				A megrendelés státusza: {result.data.createAndApplyOrder.orderStatus}
				<OrderItemsTableByOrderId orderHeaderId={result.data.createAndApplyOrder.id} />
			</div>
		:
			<div>
				Nincs megadva előfizetés azonosító!
			</div>
		}
	</div>
}

export default ManualOrderPage
