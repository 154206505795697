import React, { useState } from 'react'
import imgHouse from '../../images/kezelo_house-red-64.png'
import imgBuilding from '../../images/kezelo_building-64.png'
import imgXRay from '../../images/kezelo-xray-64.png'
import imgList from '../../images/kezelo_shortlist-filled-64.png'
import imgThermo from '../../images/kezelo_thermometer2-64.png'
import imgWriter from '../../images/kezelo_typewriter-64.png'
import imgDoubleCheck48 from '../../images/main-double-check-48.png'
import imgGraph48 from '../../images/main-graph-48.png'
import imgGift48 from '../../images/main-gift-48.png'
import imgBox48 from '../../images/main-box-48.png'
import imgPin48 from '../../images/main-maps-48.png'
import imgClean48 from '../../images/main-clean-48.png'
import imgData48 from '../../images/main-data-48.png'
import imgLayout48 from '../../images/main-layout-48.png'
import imgMeter48 from '../../images/main-meter-48.png'
import imgStudentLaptop48 from '../../images/main-student-laptop-48.png'
import imgKeyboard48 from '../../images/main-keyboard-48.png'
import imgBlocker48 from '../../images/main-blocker-48.png'
import imgStopClock48 from '../../images/main-stop-clock-48.png'
import imgUserHeadphone48 from '../../images/main-user-headphone-48.png'
import { useTranslation } from 'react-i18next'
import { Collapse } from 'react-collapse'
import QuickCleanPage from './QuickCleanPage'
import FormikKezeloForm from '../operator/IndividualValidation'
import { UserContextConsumer } from '../../components/UserContext'
import SmartFillForm from '../operator/SmartFillForm'
import LazmeroForm from '../batch/LazmeroForm'
import RontgenForm from '../batch/RontgenForm'
import BatchForm from '../batch/BatchForm'
import Scroll from 'react-scroll'
import { Link } from 'react-router-dom'
import { Card } from 'primereact/card'
import { SERVICETYPE_LAZMERES, SERVICETYPE_LOCALSTORAGE_KEY, SERVICETYPE_RONTGEN, SERVICETYPE_TISZTITAS } from '../batch/BatchInputPanel'

const KezeloNotAvailable: React.FunctionComponent<{}> = ({ children }) => {
	const { t } = useTranslation('Provisioning')
	return <div className='container mt-3'>
  <Card title={t('kezelo.not-access')} className='mb-3'>
    {children}
  </Card>
	</div>
}

export const OperatorNotAvailableWrapper = ({ children, loggedInUser, t } : { children:any, loggedInUser:any, t:any }) =>{
	return (
		loggedInUser && loggedInUser !== null
			? !loggedInUser.account.webApiKey
					? <KezeloNotAvailable >
						<div>
							{t('Provisioning:kezelo.no-quota-or-deadline-expired')}
						</div>
					</KezeloNotAvailable>
					: <>
						{ children }
					</>
			:												<KezeloNotAvailable>
				<div>
					{t('Provisioning:kezelo.has-to-login1')}
					{<Link to='/bejelentkezes'>{t('Provisioning:kezelo.has-to-login2')}</Link>}
					{t('Provisioning:kezelo.has-to-login3')}
					<Link to='/regisztracio'>{t('Provisioning:kezelo.has-to-login4')}</Link>
					{t('Provisioning:kezelo.has-to-login5')}
				</div>
			</KezeloNotAvailable>
	)
}

export const KezeloFormPanelWrapper = ({ children, title, subTitle } : {children: any, title?:any, subTitle?:any}) => {
	return (
		<>
			<div className='container-fluid'>
				<div className='mx-2 mx-md-5'>
					<div className='mb-4 align-items-end text-center'>
						<h1 className='pt-5 mb-2 font-weight-bold'>{title}</h1>
						{/* <hr className='short-red mb-5'/> */}
						<h2 className='mt-3 mb-5'>{subTitle}</h2>
					</div>
					{children}
				</div>
			</div>
		</>
	)
}

export const AttributeBox = ({ icon, text }:{icon:any, text:string}) => {
	return (<div className='attribute-box col col-md-6 col-lg-4 col-xl-4 my-3'>
		<article className='d-flex align-items-center'>
			<div className='col-auto pr-0'>
				<img src={icon} />
			</div>
			<div className='col'>
				{text}
			</div>
		</article>
	</div>)
}

const MainPageOperator = () => {
	const { t, i18n } = useTranslation(['Registration', 'Common', 'Provisioning'])
	const [panelVisible, setPanelVisible] = useState(false)
	const [buttonsSelected, setButtonsSelected] = useState<string>('')

	const KezeloButton = ({ icon, title }:{icon:any, title:string}) => {
		return <Scroll.Link activeClass='active' className='test5' to='kezeloToScroll' spy smooth duration={500} delay={200}>
  <div className={`kezelo-button text-center cursor-pointer ${buttonsSelected === title ? 'selected' : ''}`}
    onClick={(e) => {
					setButtonsSelected(title)
					setPanelVisible(true)
				}}>
    <img src={icon} />
	  <div className='mt-2 title' >{t(`landingPage.kezelo-buttons.${title}`) }</div>
	  {title === 'demo' &&
      	<div className='title' >({t('landingPage.without-registration') })</div>
	  }
  </div>
		</Scroll.Link>
	}

	const KezeloLink = ({ icon, title, serviceType }:{icon:any, title:string, t:any, serviceType:string}) => {
		return <Link to={`kezelo/${title}`}>
			<div className='kezelo-button text-center'>
				<img src={icon} />
				<div className='mt-2 title' dangerouslySetInnerHTML={{ __html: t(`Registration:landingPage.kezelo-buttons.${title}`) }} />

			</div>
		</Link>
	}

	return <UserContextConsumer>
  { ({ loggedInUser } : { loggedInUser:any }) =>
    <React.Fragment>
      <section className='kezelo-buttons p-4'>
        <div className='page-center-content'>
          <div className='row'>
            <div className='col col-md-6 col-lg-4 text-center'>
              <KezeloButton icon={imgHouse} title='demo' />
            </div>
            <div className='col col-md-6 col-lg-4 text-center'>
              <KezeloButton icon={imgBuilding} title='customize' />
            </div>
            <div className='col col-md-6 col-lg-4 text-center'>
              <KezeloButton icon={imgWriter} title='prevencio' />
            </div>
            <div className='col col-md-6 col-lg-4 text-center'>
              <KezeloLink icon={imgThermo} title='Lazmeres' t={t} serviceType={SERVICETYPE_LAZMERES} />
            </div>
            <div className='col col-md-6 col-lg-4 text-center'>
              <KezeloLink icon={imgXRay} title='Rontgen' t={t} serviceType={SERVICETYPE_RONTGEN}  />
            </div>
            <div className='col col-md-6 col-lg-4 text-center'>
              <KezeloLink icon={imgList} title='Tisztitas' t={t} serviceType={SERVICETYPE_TISZTITAS}  />
            </div>
          </div>
        </div>
      </section>
      <Scroll.Element name='kezeloToScroll' className='element collapse-panel'>
        <Collapse isOpened={panelVisible}>
          <section className={'kezelo-forms inverse-bg pb-5 '} >
            <div className='kezelo-form-content'>
              {buttonsSelected === 'demo' &&
			  <QuickCleanPage
				  title = {t(`landingPage.kezelo-buttons.${buttonsSelected}`)}
			  />
								}
              {buttonsSelected !== 'demo' &&
				  <OperatorNotAvailableWrapper loggedInUser={loggedInUser} t={t}>
					  {loggedInUser &&
						  <>
						  {buttonsSelected === 'customize' &&
							<FormikKezeloForm apiKey={loggedInUser.account.webApiKey}/>
						  }
						  {buttonsSelected === 'prevencio' &&
                          	<KezeloFormPanelWrapper title={t('Provisioning:prevencio-form.title')}>
							  <SmartFillForm apiKey={loggedInUser.account.webApiKey} />
						  	</KezeloFormPanelWrapper>
						  }
						  {buttonsSelected === 'thermometer' &&
							  <KezeloFormPanelWrapper >
							  <LazmeroForm apiKey={loggedInUser.account.webApiKey} t={t} />
							  </KezeloFormPanelWrapper>
						  }
						  {buttonsSelected === 'x-ray' &&
							  <KezeloFormPanelWrapper >
							  <RontgenForm apiKey={loggedInUser.account.webApiKey} t={t} />
							  </KezeloFormPanelWrapper>
						  }
						  {buttonsSelected === 'list' &&
							  <KezeloFormPanelWrapper >
							  <BatchForm />
							  </KezeloFormPanelWrapper>
						  }
						  </>
					  }
				  </OperatorNotAvailableWrapper>
			  }
            </div>
          </section>
          <section className='attribute-content'>
            <div className='row justify-content-center pt-4 pb-4 px-2 px-md-5 page-center-content'>
              {buttonsSelected === 'demo' &&
              <>
                <AttributeBox icon={imgGift48} text={t('Provisioning:mainPage.demo.attribute1.text')} />
                <AttributeBox icon={imgStopClock48} text={t('Provisioning:mainPage.demo.attribute2.text')} />
                <AttributeBox icon={imgBox48} text={t('Provisioning:mainPage.demo.attribute3.text')} />
              </>
								}
              {buttonsSelected === 'customize' &&
              <>
                <AttributeBox icon={imgMeter48} text={t('Provisioning:mainPage.customize.attribute1.text')} />
                <AttributeBox icon={imgClean48} text={t('Provisioning:mainPage.customize.attribute2.text')} />
                <AttributeBox icon={imgLayout48} text={t('Provisioning:mainPage.customize.attribute3.text')} />
                <AttributeBox icon={imgDoubleCheck48} text={t('Provisioning:mainPage.customize.attribute4.text')} />
                <AttributeBox icon={imgPin48} text={t('Provisioning:mainPage.customize.attribute5.text')} />
                <AttributeBox icon={imgUserHeadphone48} text={t('Provisioning:mainPage.customize.attribute6.text')} />
              </>
								}
              {buttonsSelected === 'prevencio' &&
              <>
                <AttributeBox icon={imgBlocker48} text={t('Provisioning:mainPage.prevencio.attribute1.text')} />
                <AttributeBox icon={imgKeyboard48} text={t('Provisioning:mainPage.prevencio.attribute2.text')} />
                <AttributeBox icon={imgStudentLaptop48} text={t('Provisioning:mainPage.prevencio.attribute3.text')} />
                <AttributeBox icon={imgGraph48} text={t('Provisioning:mainPage.prevencio.attribute4.text')} />
                <AttributeBox icon={imgData48} text={t('Provisioning:mainPage.prevencio.attribute5.text')} />
                <AttributeBox icon={imgUserHeadphone48} text={t('Provisioning:mainPage.prevencio.attribute6.text')} />
              </>
								}
            </div>
          </section>
        </Collapse>
      </Scroll.Element>
    </React.Fragment>
		}
	</UserContextConsumer>
}

export default MainPageOperator
