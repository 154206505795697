/**
 * Az aktuálisan bejelentkezett felhasználó contextusát biztosítja a React context API-n keresztül.
 *
 * A <UserContextProvider>-t egyszer használjuk az alkalmazás index oldalán, ApolloClientProvider-en belül lehet
 * csak használni.
 *
 * A bejelentkezett felhasználó elérése:
 * ```typescript
 *   <UserContextConsumer> {values => <div>{values.loggedInUser}</div></UserContextConsumer>
 * ```
 *
 * A values-ban van egy loginUser és logoutUser is, ezt általában az alkalmazáson belül csak egy helyen használjuk,
 * elvégzi a GraphQL-es bejelentkezést és kijelentkezést, biztosítja.
 */

import React, { useState, Context, useRef } from 'react'
import gql from 'graphql-tag'
import { ApolloQueryResult } from 'apollo-client'
import { ApolloConsumer } from 'react-apollo'
import { Growl } from 'primereact/growl'

const LOGOUT_MUTATION = gql`
    mutation Logout {
        logout
    }
`

const LOGIN_MUTATION = gql`
    mutation Login($userName: String!, $password: String!) {
        login(userName: $userName, password: $password) {
            authToken
            accounts {
                id
                shortName
                webApiKey
            }
        }
    }
`

const SWITCH_TO_ACCOUNT_MUTATION = gql`
    mutation SwitchToAccount($accountId: ID!) {
        switchToAccount(accountId: $accountId) {
            user {
                id
                email
                birthday
				fullName
            }
            account {
                id
                shortName
                webApiKey
            }
            roles
            accounts {
                id
                shortName
                webApiKey
            }
        }
    }
`
const CURRENT_USER_QUERY = gql`{
    currentUser {
        user {
            id
            email
            birthday
			fullName
        }
        account {
            id
            shortName
			webApiKey
        }
        roles
		accounts {
            id
            shortName
            webApiKey
		}
    }}`

const UserContext: Context<any> = React.createContext(null)

const FetchCurrentUser = ({ client, loggedInUser, children, setLoggedInUser } : {client:any, loggedInUser:any, children:any, setLoggedInUser:any}) => {
  if (loggedInUser === null) {
    client.query({ query: CURRENT_USER_QUERY }).then(
      (result : any) => {
        if (result.data && result.data.currentUser) {
          setLoggedInUser(result.data.currentUser)
        }
      }
    ).catch(
      () => {
        // TODO: nincs bejelentkezett felhasználó
        // console.log(error)
      }
    )
  }

  return children
}

export const UserContextProvider = ({ children } : { children : any }) => {
  const [loggedInUser, setLoggedInUser] = useState(null)

  const growlEl = useRef(null)
  return (
    <ApolloConsumer>
      {client =>
        <FetchCurrentUser client={client} loggedInUser={loggedInUser} setLoggedInUser={(user:any) => setLoggedInUser(user)}>
          <Growl ref={growlEl} />
          <UserContext.Provider
            value={{
              loginUser: async (userName: string, password: string) => {
                const result = await client.mutate({ mutation: LOGIN_MUTATION, variables: { userName, password } })
                const authToken = result.data.login.authToken
                localStorage.setItem('authToken', authToken)
                return new Promise((resolve, reject) => {
                    client.query({ query: CURRENT_USER_QUERY }).then(
                        async (qr : any) => {
                            if (qr.data && qr.data.currentUser) {
                                setLoggedInUser(qr.data.currentUser)
                                const reset: ApolloQueryResult<any>[] | null = await client.resetStore()
                            }
                            resolve(result)
                        }
                    ).catch(
                        (e) => {
                            // TODO: nincs bejelentkezett felhasználó
                            // console.log(error)
                            reject(e)
                        }
                    )
                })
              },
              logoutUser: async () => {
                const result = await client.mutate({ mutation: LOGOUT_MUTATION })
                setLoggedInUser(null)
                const reset: ApolloQueryResult<any>[] | null = await client.resetStore()
                localStorage.removeItem('authToken')
              },
              switchToAccount: async (accountId: any) => {
                const result = await client.mutate({ mutation: SWITCH_TO_ACCOUNT_MUTATION, variables: { accountId } })
                try {
                  if (result.data && result.data.switchToAccount) {
                    setLoggedInUser(result.data.switchToAccount)
                    const reset: ApolloQueryResult<any>[] | null = await client.resetStore()
                  }
                } catch (e) {
                  console.log(JSON.stringify(e))
                }
              },
              updateUser: async () => {
                client.query({ query: CURRENT_USER_QUERY }).then(
                  async (result : any) => {
                    if (result.data && result.data.currentUser) {
                      setLoggedInUser(result.data.currentUser)
                      const reset: ApolloQueryResult<any>[] | null = await client.resetStore()
                    }
                  }
                ).catch(
                  (error : any) => {
                    console.log(JSON.stringify(error))
                  }
                )
              },
              loggedInUser,
              growl: ({ severity = 'info', summary, detail, life = 3000 }:{severity?:any, summary:any, detail?:any, life?:any}) => {
                growlEl
                // @ts-ignore
                  ? growlEl.current.show({ severity: severity, summary: summary, detail:detail, life:life })
                  : console.log(`summary: ${summary}, detail: ${detail}`)
              }
            }}
          >
            {children}
          </UserContext.Provider>

        </FetchCurrentUser>
      }
    </ApolloConsumer>
  )
}

export const UserContextConsumer = UserContext.Consumer
