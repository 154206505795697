import { UserContextConsumer } from '../../components/UserContext'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router'
import React, { useState } from 'react'
import { RegistrationContentWrapper } from '../registration/RegistrationPage'
import { Formik } from 'formik'
import { ProgressBar } from 'primereact/progressbar'
import TextEditor from '../../components/editors/TextEditor'
import { Button } from 'primereact/button'
import TextAreaEditor from '../../components/editors/TextAreaEditor'
import gql from 'graphql-tag'
import ApolloClient from 'apollo-client'
import { ApolloConsumer } from 'react-apollo'

export const SEND_EMAIL_US = gql`
    query SendEmailUs($name: String!, $email: String!, $message: String!) {
        sendEmailUs(name: $name, email: $email, message: $message)
    }
`

const ContactUsForm = ({ props, t }: {props:any, t:any}) => {
	let [sending, setSending] = useState(false)
	return <UserContextConsumer>
		{(userContext) => {
			let email = userContext.loggedInUser && userContext.loggedInUser !== null ? userContext.loggedInUser.user.email : ''
			let fullName = userContext.loggedInUser && userContext.loggedInUser !== null ? userContext.loggedInUser.user.fullName : ''
			return (<ApolloConsumer>
					{(client : ApolloClient<any>) => (
					<Formik
					initialValues={{ email: email, name: fullName, body:'' }}
					validate={values => {
						let errors = {}
						if (!values.email) {
							// @ts-ignore
							errors.email = t('Registration:login.errors.email.required')
						} else if (
							!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
						) {
							// @ts-ignore
							errors.email = t('Registration:login.errors.email.invalid')
						}
						if (!values.name) {
							// @ts-ignore
							errors.name = t('Common:message.required')
						}
						if (!values.body) {
							// @ts-ignore
							errors.body = t('Common:message.required')
						}
						return errors
					}}
					onSubmit={async (values, { setSubmitting }) => {
						setSending(true)
						let result : any = {}
						try {
							result = await client.query({ query: SEND_EMAIL_US,
								variables: {
									name: values.name,
									email: values.email,
									message: values.body
								}
							})
							if (!result) {
								userContext.growl({ severity: 'error', summary: t('Common:contactUs.sendEmailError') })
								return
							}  else {
								userContext.growl({ summary: t('Common:contactUs.sendEmailSuccess') })
								// setTimeout(()=>{},5000)
								props.history.push('/')
							};
							setSending(false)
						} catch (e) {
							console.log(JSON.stringify(e))
							setSending(false)
							// @ts-ignore
							userContext.growl({ severity: 'error', summary: e.graphQLErrors[0].message })
						}
					}}
				>
					{({
						  values,
						  errors,
						  touched,
						  handleChange,
						  handleBlur,
						  handleSubmit,
						  isSubmitting,
						  setFieldValue
						  /* and other goodies */
					  }) => (
						<React.Fragment>
							{sending &&<div className='mb-3'><ProgressBar mode='indeterminate' /></div>}
							<form className='form-contact-us'
								onSubmit={handleSubmit}
							>
								<div className='row'>
									<div className='col-12 col-md-12'>
										<TextEditor
											fieldName='name'
											placeholder={t('Common:contactUs.name')}
											disabled={sending}
											value={values.name}
											error={errors.name}
											handleChange={handleChange}
											handleBlur={handleBlur} />
									</div>
									<div className='col-12 col-md-12'>
										<TextEditor
											fieldName='email'
											disabled={sending}
											placeholder={t('Registration:login.e-mail')}
											value={values.email}
											error={errors.email}
											handleChange={handleChange}
											handleBlur={handleBlur} />
									</div>
									<div className='col-12 col-md-12'>
										<TextAreaEditor
											fieldName='body'
											disabled={sending}
											placeholder={t('Common:contactUs.body-message')}
											value={values.body}
											error={errors.body}
											handleChange={handleChange}
											handleBlur={handleBlur} />
									</div>
								</div>
								<div className='text-right'>
									<Button label={t('Common:contactUs.button-send')} className='' type='submit' disabled={sending} />
								</div>
							</form>
						</React.Fragment>
					)}
				</Formik>
				)}
			</ApolloConsumer>
			)}}
	</UserContextConsumer>
}


const ContactUsPage = (props) => {
	const { t } = useTranslation(['Registration', 'Common'])
	return <RegistrationContentWrapper t={t} title={t('Common:contactUs.title')}>
			<ContactUsForm props={props} t={t} />
		</RegistrationContentWrapper>
	}

export default withRouter(ContactUsPage)
