import React, { useEffect, useRef, useState } from 'react'
import { ApolloConsumer, Query } from 'react-apollo'
import { Message } from 'primereact/message'
import { Button } from 'primereact/button'
import { RadioButton } from 'primereact/radiobutton'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import FileDownload from '../../components/editors/FileDownload'
import EnumUtils from '../../utils/EnumUtils'
import gql from 'graphql-tag'
import { formatDateTime } from '../../utils/Localization'
import { useTranslation } from 'react-i18next'
import BatchInputPanel from './BatchInputPanel'
import { Dialog } from 'primereact/dialog'
import ApolloClient from 'apollo-client'
import Scroll from 'react-scroll'

export const LIST_BATCH_REQUESTS = gql`
    query ListBatchRequests($dayCount: Int, $serviceType: ServiceType, $requestState: RequestState, $resultState: ResultState){
        listBatchRequests(dayCount: $dayCount, serviceType: $serviceType, requestState: $requestState, resultState: $resultState) {
            id
            provisionedSet {
                setName
            }
            serviceType
            options
            provisionedInterface {
                interfaceType
            }
            requestFileName
            requestEncoding
            requestMimeType
            uploadDate

            state
            result {
                id
                resultFileName
                resultMimeType
                resultState
                processEnd
                resultMessage
            }
        }
    }
`

export const FILEUPLOAD_MUTATION = gql`
    mutation CreateBatchRequest($file: Upload!, $encoding: String!, $provisionedSetId: ID!, $serviceType: ServiceType!, $options: [ServiceOption!]!, $separator: String!) {
        createBatchRequest(file: $file, encoding: $encoding, provisionedSetId: $provisionedSetId, serviceType: $serviceType, options: $options, separator: $separator) {
            id
            provisionedSet {
                apiKey
                setName
            }
            serviceType
            options
            provisionedInterface {
                interfaceType
            }
            requestFileName
            requestEncoding
            requestMimeType
            state
            uploadDate
            result {
                resultFileName
                resultMimeType
                resultState
            }
        }
    }
`

export const ListBatchRequest = ({ t, userContext, serviceType }: { t: any, userContext: any, serviceType: any }) => {
	return <>
		<hr/>
		<div className='mt-3'>
			{/*
			// @ts-ignore */}
			<Query query={LIST_BATCH_REQUESTS} variables={{ dayCount: null, serviceType: serviceType, requestState: null, resultState: null }} fetchPolicy='network-only'>
				{(result) => {
					try {
						if (result.data && (result.data.loading || !result.data.listBatchRequests)) {
							return <div>{t('Provisioning:upload-form.result-table.list-processing')}</div>
						} else if (!result) {
							return <div>{t('Provisioning:upload-form.result-table.list-error')} {JSON.stringify(result)}</div>
						} else {
							return <>{result && result.data ?
								<UploadedFiles serviceType={serviceType} batchRequestValues={result.data.listBatchRequests} userContext={userContext}
									onReload={()=>result.refetch()}/> : ''}</>
						}
					} catch (e) {
						console.log(JSON.stringify(e))
						userContext.growl({ severity: 'error', summary: t('Provisioning:upload-form.error-message.error-message') })
					}
				}}
			</Query>
		</div>
	</>
}

const UploadedFiles = ({ batchRequestValues, resultMessageValue, serviceType, userContext, onReload }: { batchRequestValues: any[], serviceType?: any, resultMessageValue?: any, userContext: any, onReload: any }) => {
	let paginatorLeft = <Button icon='pi pi-refresh'/>
	let paginatorRight = <Button icon='pi pi-cloud-upload'/>
	let [requestStateFilter, setRequestStateFilter] = useState('new')
	// let [origBatchRequests, setOrigBatchRequests] = useState<any>(batchRequestValues)
	let [batchRequests, setBatchRequests] = useState<any>(batchRequestValues)

	/* a rádió gomb alapján a találati lista kliens oldali szűrését végzi */
	const radioFilters = {
		/*
		   A f.state: a BatchRequestState:  				Submitted, Processing, ProcessingQuotaReduced, Failed, Succeeded
		   Az f.result.resultState: a BatchResultState: 	InProgress, NotDownloaded, Downloaded, NotProvisioned, Error
		*/
		new: f =>
			f.state === 'Submitted' ||
			f.state === 'Processing' ||
			f.state === 'ProcessingQuotaReduced' ||
			(f.state === 'Succeeded' && f.result.resultState !== 'Downloaded'),
		failed: f => f.state === 'Failed',
		downloaded: f => f.state === 'Succeeded' && f.result.resultState === 'Downloaded',
		all: f => true
	}

	const onRadioChange = (e: any) => {
		if (e.checked) {
			setRequestStateFilter(e.value)
			setBatchRequests(batchRequestValues.filter(radioFilters[e.value]))
		}
	}

	useEffect(() => {
		setBatchRequests(batchRequestValues.filter(radioFilters['new']))
	}, [batchRequestValues])
	let [resultMessage, setResultMessage] = useState(resultMessageValue)
	let [dialogVisible, setDialogVisible] = useState(false)
	let [selectedRow, setSelectedRow] = useState(undefined)
	let [loading, setLoading] = useState(false)


	let { t } = useTranslation(['Provisioning', 'Common'])

	const optionsCellValue = (rowData: any, column: any) => {
		return rowData ? <EnumUtils enumClassName='ServiceOptions' enumName={rowData.options}/> : ''
	}

	const serviceTypeCellValue = (rowData: any, column: any) => {
		return rowData ? <EnumUtils enumClassName='ServiceType' enumName={rowData.serviceType}/> : ''
	}
	const stateCellValue = (rowData: any, column: any) => {
		return rowData ? <EnumUtils enumClassName='RequestState' enumName={rowData.state}/> : ''
	}

	const ScrDialog = () => (<Dialog header={t('Provisioning:upload-form.result-table.resultMessage')} visible={dialogVisible} modal={true} onHide={() => {
		setDialogVisible(false)
	}}>
		{/*{t('upload-form.result-table.row-click')} {(selectedRow||{ id:'' }).id}*/}
		{/*<div>{t('Provisioning:upload-form.result-table.resultDownload-desc')}</div>*/}
		{selectedRow &&
		//@ts-ignore
        <div>{selectedRow.result.resultMessage}</div>
		}
	</Dialog>)

	const resultFileNameCellValue = (rowData: any, column: any) => {
		const visible = rowData && rowData.result && (rowData.result.resultState === 'NotDownloaded')
		return visible && <>
            <div className='d-inline-block'>
              <FileDownload resultFileName={rowData.result.resultFileName.replace(/\.txt$/, '.xlsx')} requestId={rowData.id} format='xlsx'/>
			</div>
            <div className='d-inline-block'>
              <FileDownload resultFileName={rowData.result.resultFileName} requestId={rowData.id} format='txt'/>
			</div>
		  </>
	}

	const resultStateCellValue = (rowData: any, column: any) => {
		return rowData && rowData.result ?
			<>
				{/*<a className='cursor-pointer' onClick={e => {*/}
				{/*	setDialogVisible(true)*/}
				{/*	setSelectedRow(rowData)*/}
				{/*}}>*/}
					<EnumUtils enumClassName='ResultState' enumName={rowData.result.resultState}/>
				{/*</a>*/}
			</>
			: ''
	}

	const uploadDateCellValue = (rowData: any, column: any) => {
		return rowData ? (formatDateTime(rowData.uploadDate)) : ''
	}
	const processEndCellValue = (rowData: any, column: any) => {
		return rowData ? (formatDateTime(rowData.processEnd)) : ''
	}

	return (
		<>
			{resultMessage ? <div>{resultMessage}</div> :
				<div>
					<ScrDialog/>
					{loading === true ? t('Provisioning:message.loading') :
					  <React.Fragment>
						<div className='row'>
							<div className='col'>
								<div className='form-check form-check-inline'>
									<RadioButton
										inputId='requestStateFilter1'
										className='form-check-input'
										value='new'
										name='requestStateFilter'
										onChange={onRadioChange}
										checked={requestStateFilter === 'new'}/>
									<label
										key='requestStateFilterKey1'
										htmlFor='requestStateFilter'
										className='form-check-label'>
										{t('upload-form.result-table.filter-new')}
									</label>
								</div>
								<div className='form-check form-check-inline'>
									<RadioButton
										inputId='requestStateFilter2'
										className='form-check-input'
										value='failed'
										name='requestStateFilter'
										onChange={onRadioChange}
										checked={requestStateFilter === 'failed'}/>
									<label
										key='requestStateFilterKey1'
										htmlFor='requestStateFilter'
										className='form-check-label'>
										{t('upload-form.result-table.filter-failed')}
									</label>
								</div>
								<div className='form-check form-check-inline'>
									<RadioButton
										inputId='requestStateFilter3'
										className='form-check-input'
										value='downloaded'
										name='requestStateFilter'
										onChange={onRadioChange}
										checked={requestStateFilter === 'downloaded'}/>
									<label
										key='requestStateFilterKey4'
										htmlFor='requestStateFilter'
										className='form-check-label'>
										{t('upload-form.result-table.filter-downloaded')}
									</label>
								</div>
								<div className='form-check form-check-inline'>
									<RadioButton
										inputId='requestStateFilter2'
										className='form-check-input'
										value='all'
										name='requestStateFilter'
										onChange={onRadioChange}
										checked={requestStateFilter === 'all'}/>
									<label
										key='requestStateFilterKey2'
										htmlFor='requestStateFilter'
										className='form-check-label'>
										{t('upload-form.result-table.filter-all')}
									</label>
								</div>
							</div>
							<div className='col-auto'>
								<Button
									type='button'
									label={t('Provisioning:upload-form.result-table.refresh')}
									onClick={() =>
										onReload()}
								/>
							</div>
						</div>
						<Scroll.Element name='resultTable' className='element collapse-panel' id='batchResultTable'>
							{t('Common:message.result-count')} {batchRequests ? batchRequests.length : 0}
							<DataTable value={batchRequests} paginator={true} rows={10} rowsPerPageOptions={[5, 10, 20]} sortMode='multiple'
								emptyMessage='Nincs találat!'
								responsive={true}>
								<Column field='provisionedSet.setName' header={t('upload-form.result-table.setName')} sortable={true}/>
								<Column field='serviceType' header={t('upload-form.result-table.serviceType')} body={serviceTypeCellValue} sortable={true}/>
								<Column field='options' header={t('upload-form.result-table.options')} body={optionsCellValue} sortable={true}/>
								<Column className='break-all' field='requestFileName' header={t('upload-form.result-table.requestFileName')} sortable={true}/>
								<Column field='uploadDate' header={t('upload-form.result-table.uploadDate')} body={uploadDateCellValue} sortable={true}/>
								<Column field='state' header={t('upload-form.result-table.state')} body={stateCellValue} sortable={true}/>
								<Column field='result.resultFileName' className='text-md-center' header={t('upload-form.result-table.resultFileName')}
									body={resultFileNameCellValue}/>
								<Column field='result.resultState' header={t('upload-form.result-table.resultState')} body={resultStateCellValue}/>
								{/*<Column field='processEnd' header={t('upload-form.result-table.processEnd')} body={processEndCellValue} sortable={true}/>*/}
							</DataTable>
						</Scroll.Element>
					  </React.Fragment>
					}
				</div>
			}
		</>
	)
}


const BatchForm = () => {
	const currentServiceType = 'Tisztitas'
	let [fileInputRef, setFileInputRef] = useState(null)
	let [result, setResult] = useState<any>(undefined)
	let [quota, setQuota] = useState(undefined)
	let [days, setDays] = useState(undefined)
	let [hour, setHour] = useState(undefined)
	let [minute, setMinute] = useState(undefined)
	let [progress, setProgress] = useState(false)
	const encodingPossibleValues = [
		{ label: 'UTF-8', value: 'UTF-8' },
		{ label: 'ISO-8859-2', value: 'ISO-8859-2' }
	]
	let { t } = useTranslation(['Provisioning', 'Common'])
	const growlRef = useRef(null)
	return (
		<div className='list-processing-content'>
			<div>
				{result ? <Message severity={result.state} text={result.message}/> : ''}
				{/* @ts-ignore */}
				<BatchInputPanel serviceType={currentServiceType} t={t} />
			</div>
		</div>
	)
}

export default BatchForm
