import { Query } from 'react-apollo'
import ComboEditor from './editors/ComboEditor'
import React from 'react'
import gql from 'graphql-tag'

const LIST_PROVISIONED_SETS = gql`
    query FindByAccount($id: ID!) {
        findByAccountId(id: $id){
            id
            setName
            provisionedServices {
                id
                serviceType
            }
            provisionedInterfaces {
                id
                interfaceType
            }
        }
    }
`

const ProvisionedSetSelector = ({ accountId, t, value, error, onChange } : {accountId: any, t:any, value:any, error: any, onChange: any}) => (
  <Query query={LIST_PROVISIONED_SETS} variables={{ id: accountId }}>
    {(result) => {
      if (result.data && result.data.findByAccountId) {
        const sets = result.data.findByAccountId

        let res = [{ label: t('Provisioning:message.chose'), value:null }]

        sets.forEach((value : any, index: any) => (
          res = [...res, { label:value.setName, value: value.id }]
        ))

        if (sets.length > 1) {
          return <ComboEditor
            fieldName='provisionedSet'
            fieldLabel={t('Provisioning:felhasznalasokPage.filter.provisionedSet')}
            value={value}
            dataKey='label'
            possibleValues={res}
            error={error}
            onChange={onChange}
          />
        } else if (sets.length === 1) {
          if (value !== sets[0].id) {
            onChange(sets[0].id)
          }
          return <ComboEditor
            fieldName='provisionedSet'
            fieldLabel={t('Provisioning:felhasznalasokPage.filter.provisionedSet')}
            editorClassName='d-none'
            value={sets[0].id}
            dataKey='label'
            possibleValues={res}
            editable={false}
            disabled
            error={error}
            onChange={onChange}
          />
        }
      }
      return <div />
    }}
  </Query>
)

export default ProvisionedSetSelector
