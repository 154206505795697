import { Link } from 'react-router-dom'
import React, { useState } from 'react'
import tel from '../images/contact-48.png'
import mobile from '../images/mobile-48.png'
import mail from '../images/mail-48.png'
import mailText from '../images/email_text.png'
import { useTranslation } from 'react-i18next'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import { UserContextConsumer } from '../components/UserContext'

const GET_COMMON_DATA = gql`{
    getCommonData {
        distribType
        version
    }}`

const Footer = ({ children, className } : {children? : any, className?: any}) => {
  const { t } = useTranslation(['Registration', 'Common'])
    return (
      <UserContextConsumer>
          { ({ loggedInUser } : { loggedInUser:any }) => (
          <footer className={`footer pt-5 pb-4 ${className ? className : ''}`}>
              <div className='page-center-content'>
                  <div className='container-fluid'>
                      <div className='row px-3'>
                          <div className='col-6 col-md-4'>
                              <ul className='menu-list'>
                                  <li className='level-1 mb-4'><h5>{t('Common:documents.title')}</h5></li>
                                  <li className='level-2'><a href='/downloads/cimklinika_felhasznaloi_utmutato.pdf'
                                      download>{t('Common:documents.userGuide')}</a></li>
                                  <li className='level-2'><a href='/downloads/cimklinika_aszf.pdf' download>{t('Common:documents.aszf')}</a></li>
                                  <li className='level-2'><a href='/downloads/cimklinika_gdpr.pdf' download>{t('Common:documents.gdpr')}</a></li>
                              </ul>
                          </div>
                          { loggedInUser ?
                              <div className='col-6 col-md-4'>
                                  <ul className='menu-list'>
                                      <li className='level-1 mb-4'><h5>{t('Common:menu.account.title')}</h5></li>
                                      <li className='level-2'><Link to='/torzsadatok'>{t('Common:menu.account.masterData')}</Link></li>
                                      <li className='level-2'><Link to='/megrendelesek'>{t('Common:menu.account.orders')}</Link></li>
                                      <li className='level-2'><Link to='/felhasznalasok'>{t('Common:menu.account.usage')}</Link></li>
                                      <li className='level-2'><Link to='/egyenlegek'>{t('Common:menu.account.balance')}</Link></li>

                                  </ul>
                              </div> :
                              <div className='col-6 col-md-4'>
                                  <ul className='menu-list'>
                                      <li className='level-1 mb-4'><h5>{t('Common:menu.account.title')}</h5></li>
                                      <li className='level-2 text-secondary'>{t('Common:menu.account.masterData')}</li>
                                      <li className='level-2 text-secondary'>{t('Common:menu.account.orders')}</li>
                                      <li className='level-2 text-secondary'>{t('Common:menu.account.usage')}</li>
                                      <li className='level-2 text-secondary'>{t('Common:menu.account.balance')}</li>

                                  </ul>
                              </div>
                          }
                          <div className='col-12 col-md-4 mt-5 mt-md-0'>
                              <h5 className='mb-4'>{t('Common:menu.call-us')}</h5>
                              <div className='d-flex flex-nowrap align-items-center mb-3'>
                                  <img src={tel} className='' alt='tel'/>
                                  <span className='ml-2'>{t('Common:menu.scr-phone')}</span>
                              </div>
                              <div className='d-flex flex-nowrap align-items-center mb-3'>
                                  <img src={mobile} className='' alt='mobile'/>
                                  <span className='ml-2'>{t('Common:menu.scr-mobile')}</span>
                              </div>
                              <div className='d-flex flex-nowrap align-items-center'>
                                  <img src={mail} className='' alt='mail'/>
                                  <span className='ml-2'>
                                    <Link to='/irjonnekunk'>
                                      <img src={mailText} alt='mail-text'/>
                                    </Link>
                                 </span>
                              </div>
                          </div>
                          <div className=''/>
                      </div>
                  </div>
                  <div className='mt-5 container-fluid text-center'>
                      {t('Common:menu.copyright', { year: new Date().getFullYear() })}
                      {/*
			          // @ts-ignore */}
                      <Query query={GET_COMMON_DATA}>
                          {(result: any) => {
                              if (result.data && result.data.getCommonData) {
                                  return <span>, v{result.data.getCommonData.version}</span>
                              }
                              return ''
                          }}
                      </Query>
                      <span className='ml-3'>
                           <Link to='/impresszum'>
                               {t('Common:menu.impress')}
                            </Link>
                      </span>
                  </div>

              </div>
          </footer>
      )}
      </UserContextConsumer>
  )
}

export default Footer
