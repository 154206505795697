import React from 'react'
import imgInfo32 from '../../images/info-32.png'
import { ProgressBar } from 'primereact/progressbar'
import { CKLabelValue } from '../operator/resultPanel/AddressResultPanel'
import { CKProgressBar } from './RontgenResultPanel'
import { formatNumber } from '../../utils/Localization'

const LazmeroResultPanel = ({
  result,
  additionalServices,
  t,
  children,
  progress = false
}:{
									result:any,
									additionalServices?:string[],
									t:any,
									children?:any,
									progress?:boolean,
							}) => {
  return (
    <div className='h-100'>
      <div className='result card h-100'>
        {((!result || result === undefined) && !progress) &&
        <div className='m-4'>
          {children && <div className=' mb-3 text-center'>
            <img src={imgInfo32} />
          </div>}
          {children}
        </div>
        }
        {progress && <div className='m-3'>
          <div>{t('Provisioning:kezelo.process')}</div>
          <ProgressBar mode='indeterminate' />
        </div>}
        {result && !progress &&
        <div className='d-flex result-content'>
          <div className='col data-col'>
            <div className='result'>
              <div className={'row mt-3'}>
                <CKLabelValue label={t('Provisioning:rontgen-form.result.correctness')} value={t('Common:enumValues.CorrectnessGrade.'+result.correctnessGrade) + ' (' + formatNumber(result.correctness*10)  + '%)'}/>
              </div>
              <CKProgressBar percentage={result.correctness * 10}/>
            </div>
          </div>
        </div>
        }
      </div>
    </div>
  )
}

export default LazmeroResultPanel
