import { InputText } from 'primereact/inputtext'
import { Field } from 'formik'
import React from 'react'

const TextEditor = ({
  fieldName,
  fieldLabel,
  className,
  placeholder,
  value,
  error,
  handleChange,
  handleBlur,
  disabled = false,
  children
} :
						{
							fieldName: any,
							fieldLabel?: any,
							className? : any,
							placeholder?:any,
							value: any,
							error: any,
							handleChange: any,
							handleBlur: any,
							disabled?: boolean,
							children?:any
						}) => (
  <div className=''>
    <div className='form-group w-100'>
      {fieldLabel ? <label htmlFor={'input' + fieldName}>{fieldLabel}</label> : ''}
      <InputText
        // @ts-ignore
        className={`${className} form-control ${error ? 'is-invalid' : ''}`}
        id={'input' + fieldName}
        name={fieldName}
        placeholder={placeholder || fieldLabel}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={disabled}

      />
      <div className='error-message'>
        <Field
          name={fieldName}
          className='error-message'
          render={({ form } : {form : any}) => {
            return error || null
          }}
        />
      </div>
      {children}
    </div>
  </div>
)

export default TextEditor
