import React, { useState } from 'react'
import { Formik } from 'formik'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { useTranslation } from 'react-i18next'
import { Checkbox } from 'primereact/checkbox'

const FormikWithConfirmDialog = (props: any) => {
	const [showDialog, setShowDialog] = useState(false)
	const [submitArgs, setSubmitArgs] = useState(null)
	const [needConfirmState, setNeedConfirmState] = useState(false)
	const { onSubmit, confirmOptions, ...otherProps } = props

	let { t } = useTranslation('Common')

	return <>
		<Dialog header={confirmOptions.dialogTitle} visible={showDialog} style={{ width: '50vw' }}
			footer={    <div>
					<Button label={confirmOptions.yesButtonLabel} icon='pi pi-check' onClick={() => {
						setShowDialog(false)
						if (submitArgs != null) {
							// @ts-ignore
							onSubmit(...submitArgs)
						}
					}} />
					<Button label={confirmOptions.noButtonLabel} icon='pi pi-times' onClick={() => { setShowDialog(false) }} />
				</div>
			}
			onHide={() => setShowDialog(false)} maximizable={false}>
			<div>
			{
				confirmOptions.dialogBody
			}
			{
				confirmOptions.needConfirm ? <div>
					<Checkbox
						inputId='cb1'
						onChange={(e: any) => {
							confirmOptions.setNeedConfirm(!e.checked)
							setNeedConfirmState(!e.checked)
						}}
						checked={!needConfirmState}
					/>
					<label htmlFor='cb1' className='p-checkbox-label'>{t('message.no-more-warning')}</label>
				</div> : null
			}
			</div>
		</Dialog>
		<Formik {...otherProps} onSubmit={ (...args) => {
			if (confirmOptions.needConfirm && confirmOptions.needConfirm()) {
				// @ts-ignore
				setSubmitArgs(args)
				setShowDialog(true)
				setNeedConfirmState(true)
			} else {
				onSubmit(...args)
				setNeedConfirmState(false)
			}
		}}/>
	</>
}

export default FormikWithConfirmDialog
