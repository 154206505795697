import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog } from 'primereact/dialog'
import { ApolloConsumer } from 'react-apollo'
import { Formik } from 'formik'
import { ApolloClient } from 'apollo-client'
import gql from 'graphql-tag'
import TextEditor from '../../../components/editors/TextEditor'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { UserContextConsumer } from '../../../components/UserContext'
import { refreshAccountsAndUsers } from '../Profile'
import { getEnumValue } from '../../../utils/EnumUtils'
import { AccountState, Role } from './ProfileAccountData'

/*******************************************************************************
 * Ügyfél hozzáadása a folyószámlához csak e-mail címmel.
 * Az ügyfél státusza JELENTKEZO lesz.
 *
 * @param email     Ügyfél e-mail címe.
 * @param accountId Folyószámla azonosítója.
 */
export const addUserToAccount = (client: ApolloClient<any>, email: String, accountId: Number) => {
  // console.log('email="'+email+'" accountId='+accountId)
    return client.mutate({
      mutation: gql`
          mutation AddUserToAccountByEmail($email: String!, $accountID: ID!) {
              addUserToAccountByEmail(email: $email, accountID: $accountID) {
                  id
                  state
                  email
                  firstName
                  lastName
                  homeCity
                  birthday
                  workplace
              }
          }
	  `,
		variables: { 'email': email, 'accountID': accountId }
    }).then((result) => {
    	return result.data.addUserToAccountByEmail
	}).catch (error => {
		console.log(JSON.stringify(error))
		//TODO hibajelzés...
	})

}

/*******************************************************************************
 * Előfizetéshez felhasználó hozzáadása dialógus.
 *
 * @param accountId    Előfizetés ID-ja.
 * @param openDialog   Ha TRUE, akkor megjelenik a dialógus.
 * @param onHideFunc   Dialógus becsukásakor meghívandó rutin.
 */
const AddUserToAccountDialog = (props: {accountId: number, openDialog: boolean, onHideFunc: any, setAccounts:any, accounts:any,
										selectedAccount:any, setSelectedAccount:any, setPossibleUsers:any, setSelectedUser:any, userContext:any} ) => {
	const { t } = useTranslation('Registration')
	return (
		<ApolloConsumer>
			{client => (
				<Dialog
					className='scr-dialog'
					header={t('torzsadatok.adduserdialog.title')} modal={true}
					visible={props.openDialog}
					onHide={()=>{props.openDialog ? props.onHideFunc() : ''}}
				>
					<Formik
						initialValues={{
							email  : '',
							email2 : ''
						}}
						validate={values => {
							let errors: any = {}

							// E-mail cím: kötlező és jó formátumú
							if (!values.email) {
								errors.email = t('torzsadatok.adduserdialog.email.error.required')
							} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
								errors.email = t('torzsadatok.adduserdialog.email.error.invalid')
							}

							// E-mail cím újra: kötlező, jó formátumú, és egyezen az email címmel
							if (!values.email2) {
								errors.email2 = t('torzsadatok.adduserdialog.email2.error.required')
							} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email2)) {
								errors.email2 = t('torzsadatok.adduserdialog.email2.error.invalid')
							} else if (values.email != values.email2) {
								errors.email2 = t('torzsadatok.adduserdialog.email2.error.notEquals')
							}

							return errors
						}}
						onSubmit={(values, { setSubmitting }) => {
							//új felhasználó hozzáadása
							addUserToAccount(client, values.email, props.accountId).then(user => {
								//dialógus bezárása
								props.onHideFunc()
								//folyószámlák frissítése
								refreshAccountsAndUsers(client, props.setAccounts, props.selectedAccount,
									props.setSelectedAccount, user, props.setPossibleUsers, props.setSelectedUser)
							})
						}}
					>
						{({
							  values,
							  errors,
							  touched,
							  handleChange,
							  handleBlur,
							  handleSubmit,
							  isSubmitting,
							  setFieldValue
							  /* and other goodies */
						  }) => (
							<form className='form-torzsadat-adduserdialog' onSubmit={handleSubmit} >
								{/* E-mail */}
								<TextEditor
									placeholder={t('torzsadatok.adduserdialog.email.label')}
									fieldName='email' value={values.email}
									error={errors.email} handleChange={handleChange} handleBlur={handleBlur}
								/>
								{/* E-mail újra */}
								<TextEditor
									placeholder={t('torzsadatok.adduserdialog.email2.label')}
									fieldName='email2' value={values.email2}
									error={errors.email2} handleChange={handleChange} handleBlur={handleBlur}
								/>
								<div className='text-right'>
									<Button label={t('torzsadatok.adduserdialog.button.save')} type='submit' className='btn ml-2'/>
									<Button label={t('torzsadatok.adduserdialog.button.cancel')} type='button'
										onClick={()=>{props.onHideFunc()}} className='btn ml-2'
									/>
								</div>
							</form>
						)}
					</Formik>
				</Dialog>
			)}
		</ApolloConsumer>
	)
}

/*******************************************************************************
 * Egy előfizetésből felhasználó törlése (inaktiválása) gomb.
 *
 * @param accountId Előfizeés ID.
 * @param userId    Felhasználó ID.
 * @param disabled  Ha TRUE, akkor nem jelenik meg a gomb.
 */
const InactivateAccountUserButton = (props: { accountId: number, user, disabled: boolean,
	setAccounts, selectedAccount, setSelectedAccount, setPossibleUsers, setSelectedUser } ) => {
	const { t } = useTranslation('Registration')
  return (props.disabled ? null : <ApolloConsumer>
		  {client => (
			  <Button
				  type='button'
				  label={t('torzsadatok.button.inactivate')}
				  className='btn'
				  onClick={ async () => {
					  const { data } = await client.mutate({
						  mutation: gql`
							mutation InactivateAccountUser($accountId: ID!, $userId: ID!) {
								inactivateAccountUser(accountId: $accountId, userId: $userId) {
									status
								}
							}
						`,
						  variables: { accountId: props.accountId, userId: props.user.id }
					  })
					  refreshAccountsAndUsers(client, props.setAccounts, props.selectedAccount,
						  props.setSelectedAccount, props.user, props.setPossibleUsers, props.setSelectedUser)
				  }}
			  />
		  )}
	  </ApolloConsumer>
  )
}

// Folyószámlák listája
const AccountUserSelector = (props: {accounts: Array<Account>, setAccounts, currentUserId: Number, modifiable: boolean,
							 selectedAccount, setSelectedAccount, selectedUser, setSelectedUser, setOpenAddUserDialog,
							 setPossibleUsers, possibleUsers, t}) => {
	const possibleAccounts: any[] = []
	props.accounts.forEach((value:any, idx:any) => {
		possibleAccounts.push({ key: 'pa_'+idx, label:value.shortName ? value.shortName : 'ID:' + value.id, value:value })
	})

	return <form className='form-inline'>
				{/*folyószámlák*/}
				<Dropdown
					placeholder={props.t('Registration:torzsadatok.account')}
					className='form-control'
					id='selectedAccountDropDown'
					value={props.selectedAccount}
					onChange={e => {
						props.setSelectedAccount(e.value)
						let users: any = []
						e.value.accountUsers.forEach((value: any, idx: any) => {
							let state = value.status == null ? '' : getEnumValue('AccountState', value.status)
							state = state.length > 0 ? (' - ' + state) : ''
							users.push({ key: 'pu_' + idx, label: (value.user.email + state), value: value.user })
						})
						props.setPossibleUsers(users)
					}}
					options={possibleAccounts}
				/>
				{/*felhasználók*/}
				<Dropdown
					placeholder={props.t('Registration:torzsadatok.user')}
					className='form-control ml-1'
					value={props.selectedUser}
					onChange={e => {
						props.setSelectedUser(e.value)
					}}
					options={props.possibleUsers}
				/>
				{/*felhasználó hozzáadása*/}
				{props.selectedAccount &&
				<Button icon='pi pi-plus' label={props.t('torzsadatok.button.addUser')} type='button' className='btn mx-1'
				  onClick={(e) => {
					  props.setOpenAddUserDialog(true)
				  }}
				  disabled={!props.modifiable}
				/>
				}
				{/*felhasználó inaktiválása*/}
				{props.selectedAccount && props.selectedUser &&
				<InactivateAccountUserButton
					//@ts-ignore
				  accountId={props.selectedAccount.id}
					//@ts-ignore
				  user={props.selectedUser}
					//@ts-ignore
				  disabled={props.selectedUser.status == AccountState.INAKTIV || props.currentUserId == props.selectedUser.id}

                  setAccounts={props.setAccounts}
                  selectedAccount={props.selectedAccount}
                  setSelectedAccount={props.setSelectedAccount}
                  setPossibleUsers={props.setPossibleUsers}
                  setSelectedUser={props.setSelectedUser}
				/>
				}
				{/*<ul>*/}
				{/*	{props.accounts.map((account: Account, idx: number) => {*/}
				{/*		return (*/}
				{/*			<li key={account.id}>*/}
				{/*				<a onClick={(e) => {*/}
				{/*					setSelectedAccountIdx(idx)*/}
				{/*					setSelectedUserIdx(-1)*/}
				{/*				}}>*/}
				{/*					{account.shortName}*/}
				{/*				</a>*/}
				{/*				/!* Új felhasználó hozzáadása *!/*/}
				{/*				<UserList acountId={account.id} currentUserId={props.currentUserId} accUsers={account.accountUsers} />*/}
				{/*			</li>*/}
				{/*		)*/}
				{/*	})}*/}
				{/*</ul>*/}
		</form>
}
// Egy folyószámlához tartozó ügyfelek listája
// const UserList = (props: { acountId: number, currentUserId: Number, accUsers: Array<AccountUser>}) => (
// 	<ul>
// 		{props.accUsers.map((accUser: AccountUser, idx: number) => {
// 			return (
// 				<li key={accUser.user.id}>
// 					<a onClick={(e) => {
// 						setSelectedUserIdx(idx)
// 					}}>
// 						{accUser.user.email}
// 					</a>
// 					&nbsp;
// 					<label>{accUser.status==null ? '' : getEnumValue('AccountState', accUser.status)}</label>
// 					&nbsp;
// 					<InactivateAccountUserButton
// 						accountId={props.acountId}
// 						userId={accUser.user.id}
// 						disabled={accUser.status==AccountState.INAKTIV || props.currentUserId==accUser.user.id}
// 					/>
// 				</li>
// 			)
// 		})}
// 	</ul>
// )

const ProfileAccountUserSelector = (props: {accounts, setAccounts, selectedAccount, setSelectedAccount, selectedUser, setSelectedUser, possibleUsers, setPossibleUsers}) => {
	const [openAddUserDialog, setOpenAddUserDialog] = useState(false) //felhasználó hozzáadása dialógus megjelenjen-e
	const { t } = useTranslation('Registration')
	return (
		<UserContextConsumer>
			{ (userContext: any) => (
				<React.Fragment>
					{props.selectedAccount &&
						<AddUserToAccountDialog
						  accountId={props.selectedAccount.id}
						  openDialog={openAddUserDialog}
						  onHideFunc={() => {
							  setOpenAddUserDialog(false)
						  }}
                          setAccounts={props.setAccounts}
                          accounts={props.accounts}
                          setPossibleUsers={props.setPossibleUsers}
                          setSelectedAccount={props.setSelectedAccount}
                          selectedAccount={props.selectedAccount}
						  setSelectedUser={props.setSelectedUser}
						  userContext={userContext}
						/>
					}
					<AccountUserSelector
						accounts={props.accounts}
						setAccounts={props.setAccounts}
						currentUserId={userContext.loggedInUser.user.id}
						selectedAccount={props.selectedAccount}
						setSelectedAccount={props.setSelectedAccount}
						selectedUser={props.selectedUser}
						setSelectedUser={props.setSelectedUser}
						modifiable={userContext.loggedInUser.roles.includes(Role.ADMIN)}
						setOpenAddUserDialog={setOpenAddUserDialog}
						setPossibleUsers={props.setPossibleUsers}
						possibleUsers={props.possibleUsers}
						t={t}
					/>
				</React.Fragment>
			)}
		</UserContextConsumer>
	)
}
export default ProfileAccountUserSelector

