import React from 'react'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import { useTranslation } from 'react-i18next'

const BatchPageLayout = ({ children, ...rest } : {children?: any, rest?: any}) => {
	const { t } = useTranslation('Registration')
	return (
        <div className='ck-root batch-layout'>
          <Header />
          <div className=''>
            <main className='ck-main'>
                {children}
            </main>
          </div>
          <Footer />
        </div>
	)
}

export default BatchPageLayout
