import React from 'react'
import { useTranslation } from 'react-i18next'
import { Card } from 'primereact/card'
import { Logo } from '../../layout/Menu'
import { Link } from 'react-router-dom'

const RegistrationFinish = ({ routes, match }:{routes:any, match:any}) => {
  const { t } = useTranslation('Registration')
  return <div className='registration-content'>
        <div className='row'>
          <div className='col-10 col-sm-8 col-md-6 col-lg-4 offset-1 offset-sm-2 offset-md-3 offset-lg-4'>
            <div className='dialog-bg' />
            <Card>
              <div className='ck-header p-3'>
                <div className='row'>
                  <div className='col'>
                    <div className='text-left'>
                      <Logo t={t} />
                    </div>
                  </div>
                  <div className='col-auto text-right'>
                    <Link className='icon-label-close-24' to='/' />
                  </div>
                </div>
              </div>
              <div className='p-3'>
                <div className=''>
                  <h5 className='card-title text-center'>{t('registration.title')}</h5>
                  <div className='text-center'>
                    {match.params && match.params.sikeres === 'sikeres' && t('registration.registration-success-message')}
                    {match.params && match.params.sikeres === 'sikertelen' && t('registration.registration-failed-message')}
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
}

export default RegistrationFinish
