import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { ApolloConsumer } from 'react-apollo'
import { Formik } from 'formik'
import ApolloClient from 'apollo-client'
import { refreshAccountsAndUsers } from '../Profile'
import gql from 'graphql-tag'
import { Growl } from 'primereact/growl'
import ComboEnumEditor from '../../../components/editors/ComboEnumEditor'
import TextEditor from '../../../components/editors/TextEditor'
import { Button } from 'primereact/button'
import { Address } from './ProfileAddress'
import { Phone } from './ProfilePhone'

// Ügyfél (felhasználó)
export interface User {
	id: number
	state: AccountState
	email: String
	firstName: String
	lastName: String
	homeCity: String
	birthday: String // Date
	workplace: String
}

// Jogosultságok (folyószámla+user -hez)
export enum Role {
	BALANCE  ='BALANCE',   // Egyenleg lekérdezése.
	ORDER    ='ORDER',     // Egyenleg feltöltése.
	ADMIN    ='ADMIN',     // Adminisztrátor.
	SZERVIZ  ='SZERVIZ',   // Szerviz támogatás kérése.
	APOLO    ='APOLO',     // Hibalista kezelő (ápoló szolgáltatást használhatja).
	KONZILIUM='KONZILIUM', // Szakértő (konzíliumot kérhet címmel kapcsolatban).
	API      ='API',       // API kulcsot elkérheti a web felületen.
	KEZELO   ='KEZELO'     // Használhatja a web felületen a Kezelő menüt.
}


// Folyószámla+Ügyfél kapcsolat tulajdonságai
export interface AccountUser {
	user: User
	roles: Array<Role>
	notifications: Array<Notification>
	status: AccountState
}

// Folyószámla
export interface Account {
	id: number
	segment: SegmentType       // Vállalkozás mérete.
	businessRole: BusinessRole //
	vegfelhasznalo: boolean    // Aki közvetlenül felhasználja a CK-t. A számlafizető és alárendeltjei.
	viszontelado: boolean      // Aki a CK szolgáltatásait továbbszolgáltatja végfelhasználóknak.
	promoter: boolean          // Aki jutalékot kap a CK értékesítéséért.
	state: AccountState        // Folyószámla státusza.
	shortName: String          // Rövid név.
	longName: String           // Hosszú név.
	activity: String           // Fő tevékenység.
	capital: Number            // Jegyzett tőke.
	registrationNumber: String // Cégjegyzékszám.
	taxNumber: String          // Adószám.
	addresses: Array<Address>  // Címek.
	riskOfFailure: String      // Meghibásodás veszélye.
	phones: Array<Phone>       // Telefonszámok.
	pincode: String            // Pinkód.

	accountUsers: Array<AccountUser>
}

// vállalkozás mérete
enum SegmentType {
	MAGAN            = 'MAGAN',
	MIKROVALLALKOZAS = 'MIKROVALLALKOZAS',
	KISVALLALKOZAS   = 'KISVALLALKOZAS',
	KOZEPVALLALKOZAS = 'KOZEPVALLALKOZAS',
	NAGYVALLALKOZAS  = 'NAGYVALLALKOZAS'
}
//
enum BusinessRole {
	POTENCIALIS = 'POTENCIALIS', // Nincs kizáró ok, hogy később prospekt lehessen.
	PROSPEKT    = 'PROSPEKT',    // Tevékenysége alapján érdeklődővé válhat.
	LEAD        = 'LEAD',        // Érdeklődő aki már regisztrált valamilyen formában, de még nem rendelt. Később válhat egy számlafizető kapcsolattartójává.
	RENDELT     = 'RENDELT'      //
}

// Ügyfél/folyószámla státusz
export enum AccountState {
	JELENTKEZO='JELENTKEZO',
	AKTIV     ='AKTIV',
	INAKTIV   ='INAKTIV',
	ARCHIVED  ='ARCHIVED'
}

/*******************************************************************************
 * Folyószámla módosítása
 *
 * @param client             Apollo kliens.
 * @param accountId          Módosítandó folyószámla ID.
 * @param segment            Vállalkozás mérete.
 * @param businessRole       ??
 * @param vegfelhasznalo     Aki közvetlenül felhasználja a CK-t. A számlafizető és alárendeltjei.
 * @param viszontelado       Aki a CK szolgáltatásait továbbszolgáltatja végfelhasználóknak.
 * @param promoter           Aki jutalékot kap a CK értékesítéséért.
 * @param state              Folyószámla státusza.
 * @param shortName          Rövid név.
 * @param longName           Hosszú név.
 * @param activity           Fő tevékenység.
 * @param capital            Jegyzett tőke.
 * @param registrationNumber Cégjegyzékszám.
 * @param taxNumber          Adószám.
 * @param riskOfFailure      Meghibásodás veszélye.
 * @param pincode            Pinkód.
 */
export const modifyAccount = async (
	client: ApolloClient<any>,
	accountId: Number, segment: SegmentType, businessRole: BusinessRole, vegfelhasznalo: Boolean, viszontelado: Boolean, promoter: Boolean,
	state: AccountState, shortName: String, longName: String, activity: String, capital: Number, registrationNumber: String,
	taxNumber: String, riskOfFailure: String, pincode: String
) =>
client.mutate({
  mutation: gql`
      mutation ModifyAccount(
          $accountId: ID!, $segment: SegmentType, $businessRole: BusinessRole, $vegfelhasznalo: Boolean, $viszontelado: Boolean, $promoter: Boolean,
          $state: AccountState, $shortName: String, $longName: String, $activity: String, $capital: Long, $registrationNumber: String,
          $taxNumber: String, $riskOfFailure: String, $pincode: String
      ) {
          modifyAccount(
              accountId: $accountId, segment: $segment, businessRole: $businessRole, vegfelhasznalo: $vegfelhasznalo, viszontelado: $viszontelado, promoter: $promoter,
              state: $state, shortName: $shortName, longName: $longName, activity: $activity, capital: $capital, registrationNumber: $registrationNumber,
              taxNumber: $taxNumber, riskOfFailure: $riskOfFailure, pincode: $pincode
          ) {
              id
          }
      }
  `,
	variables: {
		'accountId': accountId, 'segment': segment, 'businessRole': businessRole, 'vegfelhasznalo': vegfelhasznalo, 'viszontelado': viszontelado, 'promoter': promoter,
		'state': state, 'shortName': shortName, 'longName': longName, 'activity': activity, 'capital': capital, 'registrationNumber': registrationNumber,
		'taxNumber': taxNumber, 'riskOfFailure': riskOfFailure, 'pincode': pincode
	}
})


/*******************************************************************************
 * Egy előfizetés adatai
 *
 * @param account    Előfizetés adatai.
 * @param modifiable Módosítható-e.
 */
const ProfileAccountData = (props: {account: Account, setAccounts, selectedAccount, setSelectedAccount,setPossibleUsers, setSelectedUser, user, modifiable: boolean}) => {
	const { t } = useTranslation('Registration')
	const growlRef = useRef(null)
	//a string-es null értékeket cserélni kell '', hogy a Formik tudja frissíteni az üres értékekre.
	props.account.shortName = props.account.shortName === null ? '' : props.account.shortName
	props.account.longName = props.account.longName === null ? '' : props.account.longName
	props.account.activity = props.account.activity === null ? '' : props.account.activity
	props.account.registrationNumber = props.account.registrationNumber === null ? '' : props.account.registrationNumber
	props.account.taxNumber = props.account.taxNumber === null ? '' : props.account.taxNumber
	props.account.riskOfFailure = props.account.riskOfFailure === null ? '' : props.account.riskOfFailure
	props.account.pincode = props.account.pincode === null ? '' : props.account.pincode
	return (
		<div>
			{/* Előfizetés adatai*/}
			<ApolloConsumer>
				{client => (
					<Formik
						initialValues={{
							segment:            props.account.segment,
							businessRole:       props.account.businessRole,
							vegfelhasznalo:     props.account.vegfelhasznalo,
							viszontelado:       props.account.viszontelado,
							promoter:           props.account.promoter,
							state:              props.account.state,
							shortName:          props.account.shortName,
							longName:           props.account.longName,
							activity:           props.account.activity,
							capital:            props.account.capital,
							registrationNumber: props.account.registrationNumber,
							taxNumber:          props.account.taxNumber,
							addresses:          props.account.addresses,
							riskOfFailure:      props.account.riskOfFailure,
							phones:             props.account.phones,
							pincode:            props.account.pincode,

							disabled : !props.modifiable
						}}
						validate={values => {
							let errors: any = {}

							//Rövid név kötelező
							if( !values.shortName ) {
								errors.shortName = t('torzsadatok.accountdata.shortName.error.required')
							}

							return errors
						}}
						onSubmit={(values, { setSubmitting }) => {
							//módosítások mentése
							modifyAccount(
								client,
								props.account.id, values.segment, values.businessRole, values.vegfelhasznalo, values.viszontelado, values.promoter,
								values.state, values.shortName, values.longName, values.activity, values.capital, values.registrationNumber,
								values.taxNumber, values.riskOfFailure, values.pincode
							).then((user) => {
								// @ts-ignore
								growlRef && growlRef.current.show({ severity: 'success', summary: t('torzsadatok.button.success') })
								refreshAccountsAndUsers(client, props.setAccounts, props.selectedAccount,
									props.setSelectedAccount, props.user, props.setPossibleUsers, props.setSelectedUser)
							}).catch((error) => {
								// @ts-ignore
								growlRef && growlRef.current.show({ severity: 'error', summary: t('torzsadatok.button.fail') })
							})
						}}
					>
						{({
							  values,
							  errors,
							  touched,
							  handleChange,
							  handleBlur,
							  handleSubmit,
							  isSubmitting,
							  setFieldValue
							  /* and other goodies */
						  }) => (
							<form className='form-torzsadat-accountdata' onSubmit={handleSubmit} >
								<Growl ref={growlRef}/>
								<div className='row'>
									<div className='col-12 col-md-6'>


										{/* Vállalkozás mérete */}
										<ComboEnumEditor
											fieldName='segment'
											fieldLabel={t('torzsadatok.accountdata.segment.label')}
											value={values.segment}
											enumClass='SegmentType'
											error={errors.segment}
											onChange={(value: any) => setFieldValue('segment', value, true)}
											disabled={values.disabled}
										/>
									</div>

									{/* BusinessRole */}
									{/*								<ComboEnumEditor
											fieldName='businessRole'
											fieldLabel={t('torzsadatok.accountdata.businessRole.label')}
											value={values.businessRole}
											enumClass='BusinessRole'
											error={errors.businessRole}
											onChange={(value: any) => setFieldValue('businessRole', value, true)}
											disabled={values.disabled}
										/>
		*/}

									{/* Végfelhasználó */}
									{/*
										<div>
											<InputSwitch
												checked={values.vegfelhasznalo}
												onChange={(e: any) => setFieldValue('vegfelhasznalo', e.value, true)}
												disabled={values.disabled}
											/>
											<label>{t('torzsadatok.accountdata.vegfelhasznalo.label')}</label>
										</div>
		*/}

									{/* Viszonteladó */}
									{/*
										<div>
											<InputSwitch
												checked={values.viszontelado}
												onChange={(e: any) => setFieldValue('viszontelado', e.value, true)}
												disabled={values.disabled}
											/>
											<label>{t('torzsadatok.accountdata.viszontelado.label')}</label>
										</div>
		*/}

									{/* Promoter */}
									{/*
										<div>
											<InputSwitch
												checked={values.promoter}
												onChange={(e: any) => setFieldValue('promoter', e.value, true)}
												disabled={values.disabled}
											/>
											<label>{t('torzsadatok.accountdata.promoter.label')}</label>
										</div>
		*/}

									{/* Folyószámla státusza */}
									{/*
										<ComboEnumEditor
											fieldName='businessRole'
											fieldLabel={t('torzsadatok.accountdata.state.label')}
											value={values.state}
											enumClass='AccountState'
											error={errors.state}
											onChange={(value: any) => setFieldValue('state', value, true)}
											disabled={values.disabled}
										/>
		*/}

									{/* Rövid név */}
									<div className='col-12 col-md-6'>
										<TextEditor
											fieldName='shortName'
											fieldLabel={t('torzsadatok.accountdata.shortName.label')}
											value={values.shortName}
											error={errors.shortName}
											handleChange={handleChange} handleBlur={handleBlur}
											disabled={values.disabled}
										/>
									</div>

									{/* Hosszú név */}
									<div className='col-12 col-md-6'>
										<TextEditor
											fieldName='longName'
											fieldLabel={t('torzsadatok.accountdata.longName.label')}
											value={values.longName}
											error={errors.longName}
											handleChange={handleChange} handleBlur={handleBlur}
											disabled={values.disabled}
										/>
									</div>

									{/* Fő tevékenység */}
									<div className='col-12 col-md-6'>
										<TextEditor
											fieldName='activity'
											fieldLabel={t('torzsadatok.accountdata.activity.label')}
											value={values.activity}
											error={errors.activity}
											handleChange={handleChange} handleBlur={handleBlur}
											disabled={values.disabled}
										/>
									</div>

									{/* Jegyzett tőke */}
									{/*
										<PriceEditor
											fieldName='capital'
											fieldLabel={t('torzsadatok.accountdata.capital.label')}
											value={values.capital}
											error={errors.capital}
											handleChange={handleChange} handleBlur={handleBlur}
											disabled={values.disabled}
										/>
		*/}

									{/* Cégjegyzékszám */}
									<div className='col-12 col-md-6'>
										<TextEditor
											fieldName='registrationNumber'
											fieldLabel={t('torzsadatok.accountdata.registrationNumber.label')}
											value={values.registrationNumber}
											error={errors.registrationNumber}
											handleChange={handleChange} handleBlur={handleBlur}
											disabled={values.disabled}
										/>
									</div>

									{/* Adószám */}
									<div className='col-12 col-md-6'>
										<TextEditor
											fieldName='taxNumber'
											fieldLabel={t('torzsadatok.accountdata.taxNumber.label')}
											value={values.taxNumber}
											error={errors.taxNumber}
											handleChange={handleChange} handleBlur={handleBlur}
											disabled={values.disabled}
										/>
									</div>

									{/* Meghibásodás veszélye */}
									{/*
										<TextEditor
											fieldName='riskOfFailure'
											fieldLabel={t('torzsadatok.accountdata.riskOfFailure.label')}
											value={values.riskOfFailure}
											error={errors.riskOfFailure}
											handleChange={handleChange} handleBlur={handleBlur}
											disabled={values.disabled}
										/>
		*/}

									{/* Pinkód */}
									{/*
										<TextEditor
											fieldName='pincode'
											fieldLabel={t('torzsadatok.accountdata.pincode.label')}
											value={values.pincode}
											error={errors.pincode}
											handleChange={handleChange} handleBlur={handleBlur}
											disabled={values.disabled}
										/>
	*/}
								</div>
								<div className='text-right'>
									<Button label={t('torzsadatok.accountdata.button.submit')} type='submit' className='btn'
										disabled={values.disabled}
									/>
								</div>
							</form>
						)}
					</Formik>
				)}
			</ApolloConsumer>
		</div>
	)
}


export default ProfileAccountData
