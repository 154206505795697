import React from 'react'
import TextEditor from '../../../components/editors/TextEditor'
import { useTranslation } from 'react-i18next'

const MultiFieldAddressInputForm = ({
  values,
  errors,
  handleBlur,
  handleChange,
  onAddressChanged
}:
										{
											values:any,
											errors: any,
											handleBlur: any,
											handleChange: any,
											onAddressChanged: any
										}) => {
  const handleAddressChanged = (e : any) => {
    onAddressChanged(e.target.value)
    handleChange(e)
  }
  let { t } = useTranslation(['Provisioning'])
  return (
    <React.Fragment>
      <TextEditor
        fieldName='postalCode'
        fieldLabel={t('kezelo.postalCode')}
        value={values.postalCode}
        error={errors.postalCode}
        handleChange={handleAddressChanged}
        handleBlur={handleBlur} />

      <TextEditor
        fieldName='city'
        fieldLabel={t('kezelo.city')}
        value={values.city}
        error={errors.city}
        handleChange={handleAddressChanged}
        handleBlur={handleBlur} />

      <TextEditor
        fieldName='streetName'
        fieldLabel={t('kezelo.streetName-and-type')}
        value={values.streetName}
        error={errors.streetName}
        handleChange={handleAddressChanged}
        handleBlur={handleBlur} />

      <TextEditor
        fieldName='houseNumber'
        fieldLabel={t('kezelo.houseNumberAndPartsAfter')}
        value={values.houseNumber}
        error={errors.houseNumber}
        handleChange={handleAddressChanged}
        handleBlur={handleBlur} />
    </React.Fragment>)
}

export default MultiFieldAddressInputForm
