import React, { useEffect } from 'react'
import { withRouter } from 'react-router'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import { create } from 'domain'

const CREATE_LOG = gql`
	mutation CreateLog($accountId: ID, $userId: ID, $logType: LogType, $comment: String, $ipAddress: String) {
		createLog(accountId: $accountId, userId: $userId, logType: $logType, comment: $comment, ipAddress: $ipAddress)
	}
`

const AdvertPage = ({ history, match }) => {

	const [createLog, { data }] = useMutation(CREATE_LOG)

	useEffect(() => {
		history.push('/')
		createLog({ variables: { accountId: null, userId: null, logType: 'AdvertVisit', comment: match.params.id, ipAddress: '' } })

		return () => {}
	})

	return <div></div>
}

// @ts-ignore
export default withRouter(AdvertPage)
