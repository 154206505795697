import React, { useEffect, useState } from 'react'
import { ApolloConsumer } from 'react-apollo'
import { Column } from 'primereact/column'
import ApolloClient from 'apollo-client'
import gql from 'graphql-tag'
import { TreeTable } from 'primereact/treetable'
import { Button } from 'primereact/button'
import { Formik } from 'formik'
import { Panel } from 'primereact/panel'
import DateTimeEditor from '../../components/editors/DateTimeEditor'
import moment from 'moment-with-locales-es6'
import TreeNode from 'primereact/components/treenode/TreeNode'
import { formatDateTime } from '../../utils/Localization'
import { getEnumValue } from '../../utils/EnumUtils'
import { UserContextConsumer } from '../../components/UserContext'
import PageNotAvailable from '../../components/PageNotAvailable'
import ComboEnumEditor from '../../components/editors/ComboEnumEditor'
import { useTranslation } from 'react-i18next'
import ProvisionedSetSelector from '../../components/ProvisionedSetSelector'

const LIST_RESOURCE_USES = gql`
    query GetAccountAfter($id: ID!, $from: DateTime, $to: DateTime, $provisionedSetId: ID, $serviceType: ServiceType, $interfaceType: InterfaceType) {
        getAccount(id: $id){
            id
            resourceUses(from: $from, to: $to, provisionedSetId: $provisionedSetId, serviceType: $serviceType, interfaceType: $interfaceType) {
                id
                remainedAmount
                decrementAmount
                timestamp
                provisionedInterface {
                    id
                    interfaceType
                }
                provisionedService {
                    id
                    serviceType
                }
                usedOptions
                provisionedSet {
                    id
                    setName
                }
            }
        }
    }
`

const ResourceUseList = ({ resourceUseValues } : {resourceUseValues : any[]}) => {
  const { t } = useTranslation('Provisioning')
  let treeNodes : TreeNode[] = []
  useEffect(() => {
    let savedYear : Date
    let savedMonth = Date
    let savedDay = Date
    resourceUseValues.forEach((value :any, index :any) => {
      let currentYear = moment(value.timestamp).format('YYYY')
      let currentMonth = moment(value.timestamp).format('YYYY.MM.')
      let currentDay = moment(value.timestamp).format('YYYY.MM.DD')

      if (savedYear !== currentYear) {
        let yearItem = ({
          key: index + '-0',
          data: {
            level: '0',
            name: value.provisionedSet.setName,
            serviceType: value.provisionedService.serviceType,
            interfaceType: '',
            serviceOptions: value.usedOptions,
            date: currentYear,
            decrementAmount: '',
            remainedAmount: ''
          },
          children:[]
        })
        // push item
        /* eslint react-hooks/exhaustive-deps: "off" */
        treeNodes = [...treeNodes, yearItem]
        savedYear = currentYear
      }
      if (savedMonth !== currentMonth) {
        let monthItem = ({
          key: index + '-1',
          data: {
            level: '1',
            name: value.provisionedSet.setName,
            serviceType: value.provisionedService.serviceType,
            interfaceType: '',
            serviceOptions: value.usedOptions,
            date: currentMonth,
            decrementAmount: '',
            remainedAmount: ''
          },
          children:[]
        })
        // az utolsó év elem gyerekeihez hozzáfűzi
        treeNodes.slice(-1)[0].children = [...treeNodes.slice(-1)[0].children, monthItem]
        savedMonth = currentMonth
      }
      if (savedDay !== currentDay) {
        let dayItem = ({
          key: index + '-2',
          data: {
            level: '2',
            name: value.provisionedSet.setName,
            serviceType: value.provisionedService.serviceType,
            interfaceType: '',
            serviceOptions: value.usedOptions,
            date: currentDay,
            decrementAmount: '',
            remainedAmount: ''
          },
          children:[]
        })
        // az utolsó hónap elem gyerekeihez hozzáfűzi
        treeNodes.slice(-1)[0].children.slice(-1)[0].children = [...treeNodes.slice(-1)[0].children.slice(-1)[0].children, dayItem]
        savedDay = currentDay
      }
      {
        let hourItem = ({
          key: index + '-3',
          data: {
            level: '3',
            name: value.provisionedSet.setName,
            serviceType: value.provisionedService.serviceType,
            interfaceType: value.provisionedInterface.interfaceType,
            serviceOptions: value.usedOptions,
            date: formatDateTime(value.timestamp),
            decrementAmount: value.decrementAmount,
            remainedAmount: value.remainedAmount
          },
          children:[]
        })
        // az utolsó nap elem gyerekeihez hozzáfűzi
        treeNodes.slice(-1)[0].children.slice(-1)[0].children.slice(-1)[0].children =
					[...treeNodes.slice(-1)[0].children.slice(-1)[0].children.slice(-1)[0].children, hourItem]
      }
    })
    setTreeItems(treeNodes)
  })
  const [treeItems, setTreeItems] = useState<TreeNode[]>(treeNodes)

  const serviceTemplate = (node : any, column : any) => {
    let typeValue = ''
    let optionsValue = ''

    typeValue = getEnumValue('ServiceType', node.data.serviceType)
    if (node.data.serviceOptions && node.data.serviceOptions.length > 0) {
      optionsValue = getEnumValue('ServiceOptions', node.data.serviceOptions)
    }
    return <div>{typeValue}{optionsValue ? <div className='ml-2'>({optionsValue})</div> : ''}</div>
  }

  const interfaceTemplate = (node : any, column : any) => {
    let typeValue = getEnumValue('InterfaceType', node.data.interfaceType)
    return <div>{typeValue}</div>
  }

  const decrementTemplate = (node : any, column : any) => {
    let sumAmount = 0
    sumAmount = decrementCounter(node, sumAmount)
    return <div>{sumAmount}</div>
  }

  function decrementCounter (node : TreeNode, sumAmount: number) {
    if (node) {
      // @ts-ignore
      if (node.children && node.children.length > 0) {
        node.children.forEach((value: TreeNode) => {
          // @ts-ignore
          sumAmount = decrementCounter(value, sumAmount)
        })
      } else {
        return sumAmount + parseInt(node.data.decrementAmount)
      }
    }
    return sumAmount
  }

  const remainedTemplate = (node : any, column : any) => {
    let minAmountNode : TreeNode = node
    let lastLevelTreeNodes : TreeNode[] = []

    if (node.children && node.children.length > 0) {
      lastLevelTreeNodes = getLastLevel(node)
      lastLevelTreeNodes.forEach(value => {
        if (minAmountNode.data) {
          minAmountNode = moment(value.data.date).isAfter(moment(minAmountNode.data.date)) ? value : minAmountNode
        } else {
          minAmountNode = value
        }
      })
    }

    return <div>{minAmountNode.data && minAmountNode.data.remainedAmount}</div>
  }
  const dateTemplate = (node : any, column : any) => {
    return <div className='text-wrap d-inline-flex'>{node.data.date}</div>
  }

  function getLastLevel (node : TreeNode) {
    let children = node.children
    while (children && children.length > 0 && children[children.length - 1].children && children[children.length - 1].children.length > 0) {
      children = children[children.length - 1].children
    }
    return children
  }

  return (
    <div>
      {t('felhasznalasokPage.table.count', { darab: resourceUseValues ? resourceUseValues.length : 0 })}
      <TreeTable value={treeItems} paginator rows={10} emptyMessage={t('felhasznalasokPage.table.notFound')}>
        <Column field='name' header={t('felhasznalasokPage.table.header.name')} />
        <Column field='serviceType' header={t('felhasznalasokPage.table.header.serviceType')} body={serviceTemplate} />
        <Column field='interfaceType' header={t('felhasznalasokPage.table.header.interfaceType')} body={interfaceTemplate} />
        <Column style={{ width: '30%' }} className='text-nowrap' field='date' header={t('felhasznalasokPage.table.header.date')} expander body={dateTemplate} />
        <Column style={{ width: '10%' }} className='text-right' headerClassName='text-center' field='decrementAmount' header={t('felhasznalasokPage.table.header.decrementAmount')} body={decrementTemplate} />
        <Column style={{ width: '10%' }} className='text-right' field='remainedAmount' header={t('felhasznalasokPage.table.header.remainedAmount')} body={remainedTemplate} />
      </TreeTable>
    </div>
  )
}

const ResourceUse = () => {
  const { t } = useTranslation('Provisioning')
  let [result, setResult] = useState<any>(undefined)
  let [resourceUses, setResourceUses] = useState<any>([])
  let [possibleProvisionedSet, setPossibleProvisionedSet] = useState<any>([])
  return (
    <UserContextConsumer>
      { userContext => (
        <React.Fragment>
          {userContext && userContext.loggedInUser && userContext.loggedInUser !== null
            ? <React.Fragment>
              <div className='kezelo-container'>
                <h1 className='mt-5'>{t('felhasznalasokPage.title')}</h1>
                <div className='kezelo-content mt-4'>
                  <Panel className='mb-3'>
                    <ApolloConsumer>
                      {(client : ApolloClient<any>) => (
                        <Formik
                          initialValues={{
                            from: moment().subtract(1, 'months').toDate(),
                            to: moment().toDate(),
                            provisionedSetId: null,
                            serviceType: null,
                            interfaceType: null
                          }}
                          validate={values => {
                            let errors = {}
                            return errors
                          }}
                          onSubmit={async (values, { setSubmitting }) => {
                            const { data } = await client.query({ query: LIST_RESOURCE_USES,
                              variables: {
                                id: userContext.loggedInUser.account.id,
                                from: moment(values.from).format(),
                                to: moment(values.to).format(),
                                provisionedSetId: values.provisionedSetId,
                                interfaceType: values.interfaceType,
                                serviceType: values.serviceType
                              }
                            })
                            data.getAccount && setResourceUses(data.getAccount.resourceUses)
                          }}
                        >
                          {
                            ({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                              setFieldValue
                              /* and other goodies */
                            }) => (
                              <form className='form-signin'
                                onSubmit={handleSubmit}
                              >
                                <div className=''>
                                  <div className='row'>
                                    {/* Dátum */}
                                    <div className='col-12 col-md-6'>
                                      <div className='row flex-nowrap'>
                                        <DateTimeEditor
                                          fieldName='fromDate'
                                          fieldLabel={t('felhasznalasokPage.filter.date')}
                                          styleClass='col-6'
                                          placeholder={t('felhasznalasokPage.filter.dateFrom')}
                                          value={values.from}
                                          error={errors.from}
                                          onChange={(value: any) => setFieldValue('from', value, true)}
                                          handleBlur={handleBlur}
                                        />
                                        <div className='sep'>-</div>
                                        <DateTimeEditor
                                          fieldName='toDate'
                                          styleClass='col-6 align-self-end sep-before'
                                          placeholder={t('felhasznalasokPage.filter.dateTo')}
                                          value={values.to}
                                          error={errors.to}
                                          onChange={(value: any) => setFieldValue('to', value, true)}
                                          handleBlur={handleBlur}
                                        />
                                      </div>
                                    </div>
                                    {/* Készlet */}
                                    <div className='col-12 col-md-6'>
                                      <ProvisionedSetSelector
                                        t={t}
                                        accountId={userContext.loggedInUser.account.id}
                                        value={values.provisionedSetId}
                                        error={errors.provisionedSetId}
                                        onChange={(value: any) => setFieldValue('provisionedSetId', value, true)}
                                      />
                                    </div>
                                    {/* Szolgáltatás */}
                                    <div className='col-12 col-md-6'>
                                      <ComboEnumEditor
                                        fieldName='serviceType'
                                        fieldLabel={t('felhasznalasokPage.filter.serviceType')}
                                        value={values.serviceType}
                                        enumClass='ServiceType'
                                        error={errors.serviceType}
                                        onChange={(value: any) => setFieldValue('serviceType', value, true)}
                                        emptyValue
                                      />
                                    </div>
                                    {/* Kapu */}
                                    <div className='col-12 col-md-6'>
                                      <ComboEnumEditor
                                        fieldName='interfaceType'
                                        fieldLabel={t('felhasznalasokPage.filter.interfaceType')}
                                        value={values.interfaceType}
                                        enumClass='InterfaceType'
                                        error={errors.interfaceType}
                                        onChange={(value: any) => setFieldValue('interfaceType', value, true)}
                                        emptyValue />
                                    </div>
                                  </div>
                                </div>
                                <Button
                                  className='btn btn-outline-success my-2 my-sm-0'
                                  label={t('felhasznalasokPage.filter.buttonSearch')}
                                  type='submit' />
                              </form>
                            )
                          }
                        </Formik>
                      )}
                    </ApolloConsumer>
                  </Panel>
                  <ResourceUseList resourceUseValues={resourceUses} />
                </div>
              </div>
            </React.Fragment>
            :						<PageNotAvailable />
          }
        </React.Fragment>
      )}
    </UserContextConsumer>
  )
}

export default ResourceUse
