import { AutoComplete } from 'primereact/autocomplete'
import { Field } from 'formik'
import React from 'react'

const AutoCompleteEditor = ({ fieldName, fieldLabel, value, error, handleChange, handleBlur, disabled = false, suggestions, completeMethod, size, onFocus } :
                                {fieldName: any, fieldLabel: any, value: any, error: any, handleChange: any, handleBlur: any, disabled?: boolean, suggestions: any, completeMethod: any, size:any, onFocus?:any}) => {
    return <div className=''>
        <div className='form-group w-100' onFocus={onFocus}>
            <label htmlFor={'input' + fieldName}>{fieldLabel}</label>
            <AutoComplete
                // @ts-ignore
                className={`form-control d-block p-0 border-0 ${error ? 'is-invalid' : ''}`}
                id={'input' + fieldName}
                name={fieldName}
                placeholder={fieldLabel}
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={disabled}
                suggestions={suggestions}
                completeMethod={completeMethod}
                size={size}
            />
            <div className='error-message'>
                <Field
                    name={fieldName}
                    className='error-message'
                    render={({ form } : {form : any}) => {
                        return error || null
                    }}
                />
            </div>
        </div>
    </div>
}



export default AutoCompleteEditor
