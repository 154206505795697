import React from 'react'
import { Route } from 'react-router-dom'
import DefaultPageLayout from '../../layout/DefaultPageLayout'
const DefaultPageRoute = ({ path, exact, component: Component, render, ...rest } : {path: string, exact?: boolean, component?: any, render?: any, rest? : any}) => {
  return (
    <Route path={path} {...rest} render={render != null ? render : matchProps =>
      <DefaultPageLayout>
        <Component {...matchProps} />
      </DefaultPageLayout>}
    />
  )
}

export default DefaultPageRoute
