import React from 'react'

import { useTranslation } from 'react-i18next'
import BatchInputPanel from './BatchInputPanel'
import { UserContextConsumer } from '../../components/UserContext'
import { OperatorNotAvailableWrapper } from '../main/MainPageOperator'

const BatchPage = ({ routes, match }:{routes:any, match:any}) => {
	window.scrollTo(0,0)
	const { t } = useTranslation()
	const serviceType = match.params && match.params.szolgaltatas
	return <div className='batch-page-content'>
		<UserContextConsumer>
			{({ loggedInUser }: { loggedInUser: any }) => {
				return (
					<OperatorNotAvailableWrapper loggedInUser={loggedInUser} t={t}>
						{/* @ts-ignore */}
						<BatchInputPanel serviceType={serviceType} t={t} />
					</OperatorNotAvailableWrapper>
				)
			}}
		  </UserContextConsumer>
	</div>
}

export default BatchPage
