import { useTranslation } from 'react-i18next'
import React from 'react'
import EnumUtils from '../utils/EnumUtils'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { ApolloClient } from 'apollo-client'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

/**
 * Megjelenít egy megrendelés tételeket tartalmazó táblázatot
 * @param data
 * 			provisionedSet {
 *				id
 *				setName
 *			}
 *			orderType
 *			interfaceType
 *			serviceType
 *			serviceOptions
 *			quotaAmount
 *			quotaDays
 *			rechargeCardQuantity
 * @constructor
 */
const OrderItemsTable = ({ data }) =>
{
	const { t } = useTranslation('Provisioning')
	const interfaceServiceWithOptionsCellValue = (rowData: any, column: any) => {
		if (rowData) {
			return <React.Fragment>
				{rowData.orderType === 'Interface'
					? <><EnumUtils enumClassName='InterfaceType' enumName={rowData.interfaceType}/></>
					: <><EnumUtils enumClassName='ServiceType' enumName={rowData.serviceType}/></>
				}
				{rowData.orderType === 'Service' && rowData.serviceOptions && rowData.serviceOptions.length > 0
					? <div className='ml-2'>(<EnumUtils enumClassName='ServiceOptions' enumName={rowData.serviceOptions}/>)</div>
					: ''
				}
			</React.Fragment>
		}
		return ''
	}
	const interfaceOrServiceCellValue = (rowData: any, column: any) => {
		if (rowData) {
			return <React.Fragment>
				{rowData.orderType === 'Interface'
					? <>{t('Provisioning:megrendelesekPage.table.rowExpansion.value.interface')}</>
					: <>{t('Provisioning:megrendelesekPage.table.rowExpansion.value.service')}</>
				}
			</React.Fragment>
		}
		return ''
	}
	const quotaAmountCellValue = (rowData: any, column: any) => {
		if (rowData) {
			return <React.Fragment>
				{rowData.orderType === 'Interface'
					? <>{rowData.quotaDays} {t('Provisioning:megrendelesekPage.table.rowExpansion.value.day')}</>
					: <>{rowData.quotaAmount} {t('Provisioning:megrendelesekPage.table.rowExpansion.value.piece')}</>
				}
			</React.Fragment>
		}
		return ''
	}
	return (
		<div>
			<DataTable
				value={data}
				sortMode='multiple'
				rowClassName={(rowData: any) => ({ 'interface-row': rowData.orderType === 'Interface', 'service-row': rowData.orderType === 'Service' })}
			>
				<Column field='provisionedSet.setName' header={t('megrendelesekPage.table.rowExpansion.header.setName')} sortable/>
				<Column field='provisionedSet.orderType' header={t('megrendelesekPage.table.rowExpansion.header.interfaceOrService')}
					body={interfaceOrServiceCellValue}/>
				<Column field='orderDate' header={t('megrendelesekPage.table.rowExpansion.header.orderDate')} body={interfaceServiceWithOptionsCellValue}/>
				<Column field='quotaAmount' header={t('megrendelesekPage.table.rowExpansion.header.quotaAmount')} sortable body={quotaAmountCellValue}/>
				<Column field='rechargeCardQuantity' header={t('megrendelesekPage.table.rowExpansion.header.rechargeCardQuantity')} sortable/>
			</DataTable>
		</div>
	)
}

export default OrderItemsTable

const LIST_ORDER_ITEMS = gql`
	query GetOrderItemsByOrderHeaderId($id : ID!) {
		getOrderItemsByOrderHeaderId(id: $id){
			provisionedSet {
				id
				setName
			}
			orderType
			interfaceType
			serviceType
			serviceOptions
			quotaAmount
			quotaDays
			rechargeCardQuantity
		}
	}
`
export const getOrderItems = async (client: ApolloClient<any>, orderHeaderId: any) => {
	const { data } = await client.query({ query: LIST_ORDER_ITEMS,
		variables: { id: orderHeaderId }
	})
	return data
}

export const OrderItemsTableByOrderId = ( { orderHeaderId }) => {
	const queryListFelhasznalok = useQuery(LIST_ORDER_ITEMS, { variables: { id: orderHeaderId } })
	if ( !queryListFelhasznalok.loading && !queryListFelhasznalok.error )
		return <OrderItemsTable data={queryListFelhasznalok.data.getOrderItemsByOrderHeaderId} />
	else if (queryListFelhasznalok.error)
		return <div>Hiba: {queryListFelhasznalok.error}</div>
	else
		return <div>Betöltés...</div>

}
