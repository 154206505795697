import React from 'react'

const OperatorPanelWrapper = (props) => {
	return (
  <div className='row'>
    <div className='col-12 col-md-6 left-column'>
      <div className='input-panel'>
        <div className='input-panel-content'>
          {props.remainedAmountQuery}
            {/*{props.remainedAmountQuery &&*/}
            {/*<hr className='blue-hr'/>*/}
            {/*}*/}
          {props.leftColumnContent}
        </div>
      </div>
    </div>
    <div className='col-12 col-md-6 mt-4 mt-md-0'>
      <div className='right-column'>
        {props.rightColumnContent}
      </div>
    </div>
  </div>
	)
}

export default OperatorPanelWrapper
