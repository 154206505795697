import React, { useRef, useState } from 'react'
import { Formik } from 'formik'
import TextEditor from '../components/editors/TextEditor'
import PasswordEditor from '../components/editors/PasswordEditor'
import { Button } from 'primereact/button'
import { UserContextConsumer } from '../components/UserContext'
import { useTranslation } from 'react-i18next'
import { Link, withRouter } from 'react-router-dom'

const FormComponent = ({ props }: {props:any}) => {
  let [email, setEmail] = useState('')
  const { t } = useTranslation(['Registration', 'Common'])

  const LoginFormContent = () => {
    const { t } = useTranslation(['Registration', 'Common'])
    return (
      <UserContextConsumer>
        {(userContext) => (
          <Formik
            initialValues={{ email: '', password: '', stayLogged: false }}
            validate={values => {
              let errors = {}
              if (!values.email) {
                // @ts-ignore
                errors.email = t('login.errors.email.required')
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                // @ts-ignore
                errors.email = t('login.errors.email.invalid')
              }
              return errors
            }}
            onSubmit={(values, { setSubmitting }) => {
              userContext.loginUser(values.email, values.password).then((res: any) => {
                if (res.data.login.accounts.length > 1) {
                  userContext.growl({ summary: t('login.info.moreThanOneAccounts'), life:10000 })
                }
                // @ts-ignore
                props.history.push('/')
              }).catch((error: any) => {
                // console.log('login err: ', error)
                let errorMessage = t('login.errors.unknown')
                if (error.graphQLErrors && error.graphQLErrors.length > 0) {
                  errorMessage = error.graphQLErrors[0].message
                }
                userContext.growl({ severity: 'error', summary: t('login.errors.unsuccessful-login'), detail: errorMessage })
              })
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue
              /* and other goodies */
            }) => (
              <form className='form-signin'
                onSubmit={handleSubmit}
              >
                <div className='row'>
                  <div className='col-12 col-md-12'>
                    <TextEditor fieldName='email' placeholder={t('login.e-mail')} value={values.email} error={errors.email}
                      handleChange={handleChange} handleBlur={handleBlur} />
                  </div>
                </div>

                <div className='row mb-2'>
                  <div className='col-12 col-md-12'>
                    <PasswordEditor fieldName='password' placeholder={t('login.password')} value={values.password} error={errors.password}
                      handleChange={handleChange} handleBlur={handleBlur} />
                  </div>
                </div>
                <div className='row top10'>
                  <div className='col-12 col-md-12'>
                    <Button
                      className='btn btn-lg btn-primary btn-block'
                      label={t('login.login')}
                      type='submit' />
                  </div>
                </div>
                <div className='row mt-3 mb-2'>
                  <div className='col-12 col-md-12 cursor-pointer text-center'>
                    <Link className='highlight-blue' to={`/jelszoemlekezteto${values.email ? ('/' + values.email) : ''}`}>{t('login.password-reminder-dialog.title')}</Link>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        )}
      </UserContextConsumer>
    )
  }

  return <LoginFormContent />
}
const Login = (props) => {
  let panelRef = useRef(null)
  let buttonRef = useRef(null)

  return <div className='row'>
        <div className='col'>
          <div className='dialog-bg' />
          <FormComponent props={props}/>
        </div>
      </div>
}

export default withRouter(Login)
