import React, { useState } from 'react'
import { ApolloConsumer, Mutation, withApollo } from 'react-apollo'
import { Link, withRouter } from 'react-router-dom'
import { Field, Formik } from 'formik'
import ApolloClient from 'apollo-client'
import gql from 'graphql-tag'

import { Button } from 'primereact/button'

import TextEditor from '../../components/editors/TextEditor'
import RadioManyEnumEditor from '../../components/editors/RadioManyEnumEditor'
import DateEditor from '../../components/editors/DateEditor'
import PasswordEditor from '../../components/editors/PasswordEditor'
import moment from 'moment-with-locales-es6'
import { useTranslation } from 'react-i18next'
import { Captcha } from 'primereact/captcha'
import { Card } from 'primereact/card'
import { Logo } from '../../layout/Menu'
import CheckboxEditor from '../../components/editors/CheckboxEditor'
import { ProgressBar } from 'primereact/progressbar'

enum RegistrationType {
	PRIVATE='PRIVATE',
	COMPANY='COMPANY'
}
enum AuthenticationType {
	EMAIL='EMAIL',
	FACEBOOK='FACEBOOK',
	GOOGLE='GOOGLE',
	WEBAUTHN='WEBAUTHN'
}

interface RegistrationData {
	// Regisztráció típusa
	registrationType: RegistrationType,
	// Hitelesítés típusa
	authenticationType: AuthenticationType,
	// E-mail cím
	email: String,
	// Első név
	firstName: String,
	// További név
	lastName: String,
	// Település (tartózkodási hely)
	homeCity: String,
	// Születési idő
	birthday: String, // Date
	// Munkahely
	workplace: String
}

/**
 * Email cím ellenőrzése gomb.
 *
 * @param emailAddr Ellenőrizendő e-mail cím.
 * @param variable  A változó, amibe beleteszi, hogy az email cím megfelelő-e.
 * @param disabled  Ha true, akkor letiltja a gombot, különben engedélyezi.
 */
const TextVerifyEditor = ({ value,
							   emailIsOk,
							   authenticationType,
								   onResult,
							       errors,
								   disabled,
								   handleChange,
								   handleBlur }
								   : {
										value:any,
										emailIsOk:any,
										authenticationType:any,
										onResult: (b: any) => void,
										errors:any,
										disabled?: boolean,
										handleChange:any,
										handleBlur:any}) => {
  const { t } = useTranslation('Registration')
  let [typingTimeout, setTypingTimeout] = useState<any>(null)
  const onTextChange = (e:any, client:any) => {
    handleChange(e)
    if (typingTimeout) {
      clearTimeout(typingTimeout)
      onResult(undefined)
    }
    setTypingTimeout((setTimeout(async () => {
      // console.log(value)
      if (!validateEmail(e.target.value, authenticationType)) {
				  const { data } = await client.query({
          query: gql`query VerifyEmail($email: String!){verifyEmail(email: $email)}`,
					  variables: { email: e.target.value }
				  })
        onResult(data.verifyEmail)
      }
    }, 500)))
  }
  return <ApolloConsumer>
    {client => (
      <TextEditor
        placeholder={t('registration.email.label')} fieldName='email' value={value}
        error={errors} handleChange={(e:any) => onTextChange(e, client)} handleBlur={handleBlur}
        disabled={disabled}
      >
        { emailIsOk === undefined ? '' : (
          <span className={`error-message ${emailIsOk ? 'highlight-green' : 'highlight-red'}`}>{emailIsOk ? t('registration.emailIsOk.label.true') : t('registration.emailIsOk.label.false')}</span>
        )}
      </TextEditor>
    )}
  </ApolloConsumer>
}

// regisztráció meghívása
export const REGISTERUSER_MUTATION = gql`
	mutation RegisterUser($registrationData: RegistrationData!) {
		registerUser(registrationData: $registrationData)
	}
`

export const doRegisterUser = (registerUser: any, setProcessing, values: RegistrationData, client: ApolloClient<any>, history: History) => {
  registerUser({ variables: { registrationData: { ...values } } })
    .then((response: any) => {
	  setProcessing(false)
      // @ts-ignore
      history.push('/regisztracio_vege/' + (response.data.registerUser == true ? 'sikeres' : 'sikertelen'))
    })
    .catch(() => {
	  setProcessing(false)
      // @ts-ignore
      history.push('/regisztracio_vege/sikertelen')
    })
}

const validateEmail = (emailValue:any, authenticationTypeValue:any) => {
  if (!emailValue) {
    if (authenticationTypeValue == AuthenticationType.EMAIL) {
      return 'registration.email.error.required'
    }
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(emailValue)) {
    return 'registration.email.error.invalid'
  }
}

export const RegistrationContentWrapper = ({ children, title, t, className }:{children:any, title?:string, t:any, className?:any}) => {
	return (
		<div className='registration-content'>
			<div className='row'>
				<div className={!className ? 'col-12 col-md-10 col-xl-8 offset-md-1 offset-xl-2' : className }>
					<div className='dialog-bg' />
					<Card>
						<div className='ck-header p-3'>
							<div className='row'>
								<div className='col'>
									<div className='text-left'>
										<Logo t={t} />
									</div>
								</div>
								<div className='col-auto text-right'>
									<Link className='icon-label-close-24' to='/' />
								</div>
							</div>
							{title &&
							<h1 className='text-left mt-2 mb-0'>{title}</h1> }
						</div>
						<div className='p-3'>
							<div className=''>
								<div className=''>
									{children}
								</div>
							</div>
						</div>
					</Card>
				</div>
			</div>
		</div>
	)
}

const RegistrationPage = ({ history } : { history: History }) => {
  const { t } = useTranslation(['Registration', 'Provisioning'])
  let [processing, setProcessing] = useState(false)

  const RegisztracioForm = ({ registerUser, client, history } : { registerUser: any, client: ApolloClient<any>, history: History}) => <Formik
    initialValues={{
      registrationType: RegistrationType.PRIVATE,
      authenticationType: AuthenticationType.EMAIL,
      email: '',
      email2: '',
      password: '',
      password2: '',
      firstName: '',
      lastName: '',
      homeCity: '',
      // @ts-ignore
      birthday: undefined as Date,
      workplace: '',
      emailIsOk: undefined,
	  generalTermsAndConditions: undefined,
	  privacyPolicy: undefined,
      reCaptcha:''
    }}
    validate={values => {
      let errors: any = {}

      // regisztáció típusa: kötelező
      if (values.registrationType == null) {
        errors.registrationType = t('registration.registrationType.error.required')
      }

      if ((values.workplace == null || values.workplace === '') && values.registrationType === RegistrationType.COMPANY) {
        errors.workplace = t('registration.workplace.error.required')
      }

      // Hitelesítés típusa: kötelező
      if (values.authenticationType == null) {
        errors.authenticationType = t('registration.authenticationType.error.required')
      }
      // E-mail cím: kötlező és jó formátumú
      let emailErrors = validateEmail(values.email, values.authenticationType)
      if (emailErrors) {
        errors.email = t(emailErrors)
      }

      // E-mail cím újra: kötlező, jó formátumú, és egyezen az email címmel
      if (!values.email2) {
        if (values.authenticationType == AuthenticationType.EMAIL) {
          errors.email2 = t('registration.email2.error.required')
        }
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email2)) {
        errors.email2 = t('registration.email2.error.invalid')
      } else if (values.email != values.email2) {
        errors.email2 = t('registration.email2.error.notEquals')
      }

      // Vezetéknév: kötelező
      if (!values.firstName) {
        errors.firstName = t('registration.firstName.error.required')
      }

      // Keresztnév: kötelező
      if (!values.lastName) {
        errors.lastName = t('registration.lastName.error.required')
      }

      // TODO: Születési dátum: nem lehet 100 évnél régebbi, vagy 14 évnél korábbi

      // Munkahely neve: Céges regisztrációnál kötelező
      if (values.registrationType == RegistrationType.COMPANY && !values.workplace) {
        errors.workplace = t('registration.workplace.error.required')
      }

      // jelszó
      if (!values.password || values.password.length < 6) {
        errors.password = t('registration.password.error.invalid')
      }

      if (values.password !== values.password2) {
        errors.password = t('registration.password.error.notEquals')
        errors.password2 = t('registration.password2.error.notEquals')
      }

      if (!values.reCaptcha) {
        errors.reCaptcha = t('registration.reCaptcha.error.required')
      }

	  // Általános szerződési feltétel: kötelező
	  if (values.generalTermsAndConditions != true) {
		errors.generalTermsAndConditions = t('registration.generalTermsAndConditions.error.required')
	  }
	  // Adatvédelmi tájékoztatót: kötelező
	  if (values.privacyPolicy != true) {
		errors.privacyPolicy = t('registration.privacyPolicy.error.required')
	  }

		// Születésnap formátum
		if (typeof(values.birthday) === 'string' && !(/([12]\d{3}\.(0[1-9]|1[0-2])\.(0[1-9]|[12]\d|3[01])\.)/.test(values.birthday)) ) {
			errors.birthday = t('registration.birthday.error.format')
		}

      return errors
    }}
    onSubmit={(values, { setSubmitting }) => {
      setProcessing(true)
      let regData = {
        registrationType: values.registrationType,
        authenticationType: values.authenticationType,
        email: values.email,
        password: values.password,
        firstName: values.firstName,
        lastName: values.lastName,
        homeCity: values.homeCity,
        birthday: values.birthday ?  moment(values.birthday).format('YYYY-MM-DD') : '',
        workplace: values.workplace
      }
      doRegisterUser(registerUser, setProcessing, regData, client, history)
    }}
  >
    {({
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      setFieldValue
      /* and other goodies */
		  }) => (
  <form className='form-signin'
    onSubmit={handleSubmit}
      >
    {/* <script src="https://www.google.com/recaptcha/api.js?render=explicit" async defer></script> */}
    {/* 1. Regisztráció típusa */}
	  {processing && <div className='mb-3'><ProgressBar mode='indeterminate' /></div>}
    {/* <label>{t('registration.tab.1')}</label> */}
	  {!processing &&
	  <React.Fragment>
		  <div className='row'>
		  <div className='col-12 col-md-6'>
			{/* 2. Hitelesítés típusa */}
			{/* {t('registration.tab.2')} */}
			{/* <RadioManyEnumEditor */}
			{/* fieldName='authenticationType' */}
			{/* value={values.authenticationType} */}
			{/* enumClass='AuthenticationType' */}
			{/* error={errors.authenticationType} */}
			{/* disabled={values.registrationType==RegistrationType.COMPANY} */}
			{/* onChange={(value: any) => setFieldValue('authenticationType', value, true)} */}
			{/* /> */}
			<TextVerifyEditor
			  value={values.email} authenticationType={values.authenticationType}
			  emailIsOk={values.emailIsOk}
			  errors={errors.email} handleChange={handleChange} handleBlur={handleBlur}
			  onResult={(res: any) => { setFieldValue('emailIsOk', res, true) }}
				/>
			<TextEditor
			  placeholder={t('registration.email2.label')} fieldName='email2' value={values.email2}
			  error={errors.email2} handleChange={handleChange} handleBlur={handleBlur}
			  disabled={values.authenticationType != AuthenticationType.EMAIL}
				/>

			<PasswordEditor
			  placeholder={t('registration.password.label')} fieldName='password' value={values.password}
			  error={errors.password} handleChange={handleChange} handleBlur={handleBlur}
			  disabled={values.authenticationType != AuthenticationType.EMAIL}
			  passwordMeter
				/>
			<PasswordEditor
			  placeholder={t('registration.password2.label')} fieldName='password2' value={values.password2}
			  error={errors.password2} handleChange={handleChange} handleBlur={handleBlur}
			  disabled={values.authenticationType != AuthenticationType.EMAIL}
			  passwordMeter
				/>
			<TextEditor
			  placeholder={t('registration.firstName.label')} fieldName='firstName' value={values.firstName}
			  error={errors.firstName} handleChange={handleChange} handleBlur={handleBlur}
				/>

			<TextEditor
			  placeholder={t('registration.lastName.label')} fieldName='lastName' value={values.lastName}
			  error={errors.lastName} handleChange={handleChange} handleBlur={handleBlur}
				/>
		  </div>
		  <div className='col-12 col-md-6'>
			<DateEditor
			  placeholder={t('registration.birthday.label')} fieldName='birthday' value={values.birthday}
			  monthNavigator yearNavigator
			  yearRange={moment().subtract(100, 'years').format('YYYY') + ':' +	moment().format('YYYY')}
			  minDate={moment().subtract(100, 'years').set('month', 0).set('day', '1').toDate()}
			  maxDate={moment().toDate()}
			  error={errors.birthday} onChange={(value: any) => setFieldValue('birthday', value, true)} handleBlur={handleBlur}
				/>

			<TextEditor
			  placeholder={t('registration.homeCity.label')} fieldName='homeCity' value={values.homeCity}
			  error='' handleChange={handleChange} handleBlur={handleBlur}
				/>

			<TextEditor
			  placeholder={t('registration.workplace.label')} fieldName='workplace' value={values.workplace}
			  error={errors.workplace} handleChange={handleChange} handleBlur={handleBlur}
				/>
			<RadioManyEnumEditor
			  fieldName='registrationType'
			  value={values.registrationType}
			  enumClass='RegistrationType'
			  error={errors.registrationType}
			  pageLayout='horizontal'
			  onChange={(value: any) => {
					setFieldValue('registrationType', value, true)
					if (value == RegistrationType.COMPANY) {
					  values.authenticationType = AuthenticationType.EMAIL
					}
				  }}
				/>
			<CheckboxEditor fieldName='generalTermsAndConditions'
				value={values.generalTermsAndConditions} error={errors.generalTermsAndConditions} onChange={value => setFieldValue('generalTermsAndConditions', value)}>
				{t('Registration:registration.generalTermsAndConditions.label1')}
				<a className='mx-1' href='/downloads/cimklinika_aszf.pdf' download>{t('Registration:registration.generalTermsAndConditions.label2')}</a>
				{t('Registration:registration.generalTermsAndConditions.label3')}
			</CheckboxEditor>
			  <CheckboxEditor fieldName='privacyPolicy'
				  value={values.privacyPolicy} error={errors.privacyPolicy} onChange={value => setFieldValue('privacyPolicy', value)}>
				  {t('Registration:registration.privacyPolicy.label1')}
				  <a className='mx-1'  href='/downloads/cimklinika_gdpr.pdf' download>{t('Registration:registration.privacyPolicy.label2')}</a>
				  {t('Registration:registration.privacyPolicy.label3')}
			  </CheckboxEditor>
			<Captcha siteKey='6LdfHa4UAAAAAH83W6SP-f8d_eLrd_7potHKYs3a' onResponse={(response => setFieldValue('reCaptcha', response))} language='hu' />
			<div className={`error-message  ${errors.reCaptcha ? 'mb-2' : ''}`}>
			  <Field
				name='reCaptcha'
				className='error-message'
				render={({ form } : {form : any}) => {
					  return errors.reCaptcha ? errors.reCaptcha : null
					}}
				  />
			</div>
		  </div>
		</div>
		<div className='row mt-3'>
			<div className='col'>
				<div className='error-message'>
					{(Object.keys(errors).length > 0 || !values.emailIsOk) ? t('Provisioning:kezelo.message.check-data') : ''}
				</div>
			</div>
		  <div className='col-auto align-self-end'>
			<div className='text-right'>
			  <Button
				className='btn btn-lg btn-primary'
				disabled={(Object.keys(errors).length > 0 || !values.emailIsOk || processing)}
				label={t('registration.button.submit')}
				type='submit' />
			</div>
		  </div>
		</div>
    </React.Fragment>
  }

  </form>
    )}
  </Formik>

  // @ts-ignore
  const FormComponent = withRouter(withApollo(({ history, client } : {history: History, client: ApolloClient<any>}) => (
    <Mutation mutation={REGISTERUSER_MUTATION}>
      {(registerUser) => (
        <RegistrationContentWrapper t={t} title={t('registration.title')}>
			<RegisztracioForm registerUser={registerUser} history={history} client={client} />
		</RegistrationContentWrapper>

      )}
    </Mutation>
  )
  )
  )
  return <FormComponent />
}

export default RegistrationPage
