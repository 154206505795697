import 'react'
import React, { useRef, useState } from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import { Column } from 'primereact/column'
import { TreeTable } from 'primereact/treetable'
import { getEnumValue } from '../../utils/EnumUtils'
import { getDayFromCurrentDay, formatDate } from '../../utils/Localization'
import TreeNode from 'primereact/components/treenode/TreeNode'
import { copyToClipboard } from '../../utils/CommonFunction'
import ReactTooltip from 'react-tooltip'
import { Growl } from 'primereact/growl'
import { useTranslation } from 'react-i18next'
import { UserContextConsumer } from '../../components/UserContext'
import PageNotAvailable from './ResourceUse'
import imgKey24 from '../../images/key-24.png'


const LIST_PROVISIONED_SETS = gql`
	query getCurrentAccount {
		getCurrentAccount{
			provisionedSets {
				setName
				apiKey
				provisionedInterfaces {
					interfaceType
					endDate
				}
				provisionedServices {
					serviceType
					serviceOptions
					quota
	
				}
			}
		}
	}
`

const ProvisionedSetList = ({ provisionedSetValues } : {provisionedSetValues : any[]}) => {
  const { t } = useTranslation('Provisioning')
  let treeNodes : TreeNode[] = []
  let growlRef = useRef(null)
  provisionedSetValues.forEach((value :any, index :any) => {
    let provisionedSet = value
    let provisionedInterfaces = value.provisionedInterfaces
    let provisionedServices = value.provisionedServices
    let rootItem : TreeNode = ({
      key: index,
      data: {
        name: provisionedSet.setName,
        apiKey: provisionedSet.apiKey
      },
      children:[]
    })

    if (provisionedInterfaces && provisionedInterfaces.length > 0) {
      let treeInterfaceItem : TreeNode =
				{
				  key: t('egyenlegekPage.table.tree.interfaceKey', { index: 'name' }),
				  data : {
				    name: t('egyenlegekPage.table.header.interface'),
				    enumClass: 'InterfaceType',
				    quota:''
				  },
				  children:[]
				}
      let minDays = -1
      let maxDays = -1
      provisionedInterfaces.forEach((interfaceValue: any, iIdx: any) => {
        let days = getDayFromCurrentDay(interfaceValue.endDate)
        days = days < 0 ? 0 : days
        if (days < minDays || minDays === -1) {
          minDays = days
        }
        if (days > maxDays) {
          maxDays = days
        }
        let treeItem : TreeNode =
				{
				  key: t('egyenlegekPage.table.tree.interfaceKey', { index: iIdx }),
				  data: {
				    enumClass: 'InterfaceType',
				    enumValue: interfaceValue.interfaceType,
				    quota: t('egyenlegekPage.table.tree.interfaceQuota', { day: days, date:formatDate(interfaceValue.endDate) }),
				    stat1: t('message.noData'),
				    stat2: t('message.noData')
				  },
				  children:[]
				}
        treeInterfaceItem.children.push(treeItem)
      })
      if (minDays === maxDays) {
        treeInterfaceItem.data.quota = t('egyenlegekPage.table.tree.interfaceSum1', { day1: minDays })
      } else {
        treeInterfaceItem.data.quota = t('egyenlegekPage.table.tree.interfaceSum2', { day1: minDays, day2: maxDays })
      }
      rootItem.children.push(treeInterfaceItem)
    };
    if (provisionedServices && provisionedServices.length > 0) {
      let treeServiceItem : TreeNode =
				{
				  key: t('egyenlegekPage.table.tree.serviceKey', { index: 'name' }),
				  data : {
				    name: t('egyenlegekPage.table.header.service'),
				    enumClass: 'ServiceType'
				  },
				  children:[]
				}
      provisionedServices.forEach((serviceValue: any, sIdx: any) => {
        let treeItem : TreeNode =
					{
					  key:  t('egyenlegekPage.table.tree.serviceKey', { index: sIdx }),
					  data: {
					    enumClass: 'ServiceType',
					    enumValue: serviceValue.serviceType,
					    serviceOptions: serviceValue.serviceOptions,
					    quota: serviceValue.quota,
					    stat1: t('message.noData'),
					    stat2: t('message.noData')
					  },
					  children:[]
					}
        treeServiceItem.children.push(treeItem)
      })
      rootItem.children.push(treeServiceItem)
    };
    treeNodes.push(rootItem)
  })
  const [treeItems, setTreeItems] = useState<TreeNode[]>(treeNodes)
  const nameTemplate = (node : any, column : any) => {
    let typeValue = node.data.name
    let optionsValue = ''

    if (!typeValue && node.data.enumClass === 'InterfaceType') {
      typeValue = t('egyenlegekPage.table.tree.interfaceType', { name: getEnumValue('InterfaceType', node.data.enumValue) })
    }
    if (!typeValue && node.data.enumClass === 'ServiceType') {
      typeValue = t('egyenlegekPage.table.tree.serviceType', { name: getEnumValue('ServiceType', node.data.enumValue) })
    }
    if (node.data.serviceOptions && node.data.serviceOptions.length > 0) {
      optionsValue = getEnumValue('ServiceOptions', node.data.serviceOptions)
    }

    return <div>
      <Growl ref={growlRef} />
      {typeValue}
      {node.data.apiKey
        ? <div className='ml-2 break-all'>
          <ReactTooltip />
					 <img src={imgKey24}/> API-KEY:<span className='api-key mr-2'>{node.data.apiKey}</span>
			  <a className='icon-label-copy-16 mr-1 cursor-pointer'
				  onClick={(e) => {
					  copyToClipboard(node.data.apiKey)
					  // @ts-ignore
					  growlRef.current.show({ severity: 'info', summary: t('egyenlegekPage.copyGrowl.message') })
				  }}
				  data-tip={t('egyenlegekPage.copyGrowl.dataTip')}
			  />
        </div>
        : ''}
      {optionsValue
        ? <div className='ml-2'>
						({optionsValue})
        </div>
        : ''}
    </div>
  }

  return (
    <div>
      {/* <div className='text-right mb-3'> */}
      {/* <Button className='p-button-info' label='Készlet törlése' onClick={(e : any) => {alert('A funkció fejlesztés alatt')}}/> */}
      {/* </div> */}
      <TreeTable value={treeItems} paginator rows={10} emptyMessage={t('message.notFound')}
        rowClassName={(node:any) => ({ 'root-row': node.data.name, 'interface-row': node.data.enumClass === 'InterfaceType', 'service-row': node.data.enumClass === 'ServiceType' })}
      >
        <Column field='name' header={t('egyenlegekPage.table.header.name')} expander body={nameTemplate} sortable />
        <Column field='quota' header={t('egyenlegekPage.table.header.quota')} sortable />
        {/* <Column field="stat1" header={t('egyenlegekPage.table.header.stat1')}/> */}
        {/* <Column field="stat2" header={t('egyenlegekPage.table.header.stat2')}/> */}
      </TreeTable>
    </div>
  )
}

const Balances = () => {
  const { t } = useTranslation('Provisioning')
  return (
    <UserContextConsumer>
      { userContext => (
        <React.Fragment>
          {userContext && userContext.loggedInUser && userContext.loggedInUser !== null
            ? <React.Fragment>
              <div className='kezelo-container'>
                <h1 className='mt-5'>{t('egyenlegekPage.title')}</h1>
                <div className='balances-content mt-4'>
                  <Query query={LIST_PROVISIONED_SETS}>
                    {(result) => {
                      if (result.data && (result.data.loading || !result.data.getCurrentAccount)) {
                        return <div>{t('message.loading')}</div>
                      } else if (!result) {
                        return <div>{t('message.error', { message: JSON.stringify(result) })}</div>
                      } else {
                        return (
                          <>{result && result.data && result.data.getCurrentAccount && result.data.getCurrentAccount.provisionedSets
                            ? <ProvisionedSetList provisionedSetValues={result.data.getCurrentAccount.provisionedSets} />
                            : ''}
                          </>
                        )
                      }
                    }}
                  </Query>
                </div>
              </div>
            </React.Fragment>
            :						<PageNotAvailable />
          }
        </React.Fragment>
      )}
    </UserContextConsumer>
  )
}

export default Balances
