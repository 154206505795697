import React from 'react'
import ReactTooltip from 'react-tooltip'
import imgText from '../../images/text-24.png'
import imgExcel from '../../images/excel-24.png'

const FileDownload = ({ requestId, resultFileName, format } : {requestId: any, resultFileName: any, format: any}) => {
  let url = ''
  if (process.env.NODE_ENV !== 'production') {
    url = 'http://localhost:8080'
  }
  const authToken = localStorage.getItem('authToken')
  return (
    <div>
      <ReactTooltip />
      <a className='break-word' data-tip={resultFileName} href={url + '/batchResultDownload/' + requestId + '/' + authToken + '/' + format} target='_blank'>
          {/*{truncateMiddle(resultFileName, 10, 10, '...')}*/}
          {/*<img src={imgDownload}/>*/}
          {format === 'txt' && <img src={imgText}/>}
          {format === 'xlsx' && <img src={imgExcel}/>}
      </a>
    </div>
  )
}

export default FileDownload
